import React from 'react';
import ModalCloseIcon from '../../modals/ModalCloseIcon'
import ModalHelper from '../../../helpers/ModalHelper'
import LoaderActionButton from '../../LoaderActionButton'
import RequiredFieldLabel from '../../forms/RequiredFieldLabel'
import ApiService from '../../../services/ApiService'

// create or update
class BankAccountDetailsFormModal extends React.Component {
  constructor(props) {
    super(props)

    this.divID = props.modalID

    const initialState = this.loadComponentState(this.props.bankAccountDetails)

    this.state = initialState
  }

  componentDidMount() {
    this.modalInstance = ModalHelper.initialiseModal(this.divID, this)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.bankAccountDetails.token && !this.props.bankAccountDetails.token) {
      // removing, so reset
      const newState = this.loadComponentState(this.props.bankAccountDetails)

      this.setState(newState)
    }
  }

  loadComponentState = (bankAccountDetails) => {
    const allFieldNameMappings = bankAccountDetails.field_name_mappings

    return {
      bankAccountDetails,
      isRunningApiRequest: false,
      apiRequestError: false,
      isPersisted: !['', null, undefined].includes(bankAccountDetails['token']),
      allFieldNameMappings,
      currentFieldNameMappings: allFieldNameMappings[bankAccountDetails.account_type],
      currentRequiredFields: bankAccountDetails['required_fields'],
      inputVals: {
        account_type: bankAccountDetails['account_type'],
        email: bankAccountDetails['email'] || '',
        full_name: bankAccountDetails['full_name'] || '',
        field_one: bankAccountDetails['field_one'] || '',
        field_two: bankAccountDetails['field_two'] || ''
      }
    }
  }

  closeModal = () => {
    this.modalInstance.close()
  }

  handleApiResponse = (response) => {
    if(response.success) {
      const updateStateArgs = {
        apiRequestSuccess: true,
        isRunningApiRequest: false
      }

      this.setState(
        updateStateArgs,
        () => {
          this.props.createOrUpdateCallback(response.data['bank_account_details'], response.data['flash'])
          this.closeModal()
        }
      )
    }
    else {
      this.setState({
        isRunningApiRequest: false,
        apiRequestError: true
      })
    }
  }

  resetInputFormLengthValidationText = (e) => {
    const fieldName = e.target.dataset.fieldName
    const text = document.getElementById(`${fieldName}-input-form-length-validation-text`)
    
    if(text) {
      text.classList.remove('purple-text')
    }
  }

  handleInputValueChange = (e) => {
    let [target, value] = [e.target.name, e.target.value]

    const updatedInputVals = Object.assign(this.state.inputVals, { [target]: value })

    let updatedState = { inputVals: updatedInputVals }

    if(target === 'account_type') {
      // update the field mappings and corresponding current required fields
      const currentFieldNameMappings = this.state.allFieldNameMappings[value]

      let currentRequiredFields = ['account_type', 'email', 'full_name']

      if(currentFieldNameMappings['field_one'].required) {
        currentRequiredFields.push('field_one')
      }
      if(currentFieldNameMappings['field_two'].required) {
        currentRequiredFields.push('field_two')
      }

      Object.assign(updatedState, {
        currentFieldNameMappings,
        currentRequiredFields
      })
    }

    this.setState(updatedState)
  }

  submitForm = (event) => {
    const details = this.state.inputVals

    let missingRequiredField = false
    // don't try to submit, and allow the HTML form validations to run
    
    this.state.currentRequiredFields.forEach((requiredField) => {
      if(['', null].includes(details[requiredField])) {
        missingRequiredField = true
      }
    })

    if(missingRequiredField) {
      return
    }

    let invalidFieldLength = false

    Object.keys(this.state.currentFieldNameMappings).forEach((fieldName) => {
      const lengthValidation = this.state.currentFieldNameMappings[fieldName].length_validation

      const field = document.getElementById(`bank-account-details-form-modal-form-${fieldName}-input`)
      const value = field.value

      const focusInvalidLengthField = (() => {
        if(lengthValidation) {
          if(lengthValidation.equal && value.length !== lengthValidation.equal) {
            return true
          }
  
          if(lengthValidation.gte && value.length < lengthValidation.gte) {
            return true
          }
  
          if(lengthValidation.lte && value.length > lengthValidation.lte) {
            return true
          }
        }
        else {
          false
        }
      })()

      if(focusInvalidLengthField) {
        field.focus()
        document.getElementById(`${fieldName}-input-form-length-validation-text`).classList.add('purple-text')
        invalidFieldLength = true // will return after
        return // break iteration
      }
    })

    if(invalidFieldLength) {
      return
    }

    const requestParams = { bank_account_details: details }

    event.stopPropagation()
    event.preventDefault()

    const runApiRequestCallback = (() => {
      // update
      if(this.isPersisted) {
        return(
          () => {
            ApiService.updateBankAccountDetails(this.state.bankAccountDetails['token'], requestParams)
              .then((response) => {
                this.handleApiResponse(response)
              })
          }
        )
        
      }
      // create
      else {
        return(
          () => {
            ApiService.createBankAccountDetails(requestParams)
              .then((response) => {
                this.handleApiResponse(response)
              })
          }
        )
      }
    })()

    this.setState(
      {
        isRunningApiRequest: true,
        apiRequestError: false
      },
      runApiRequestCallback
    )
  }

  render() {
    return(
      <div id={this.divID} className='modal modal--bank-account-details-form'>
        <div className='modal-content'>
          <ModalCloseIcon
            closeModal={this.closeModal}
            modalID={this.divID}
          />

          <h5>{`${this.isPersisted ? 'Update' : 'Add' } Bank Account Details`}</h5>

          <div className='modal-form'>
            <form onSubmit={(e) => { e.preventDefault() }} className="col s12">
              <div className="input-field col s12 padding-bottom">
                { this.state.bankAccountDetails.account_types.map((accountType) => {
                  return(
                    <p key={`${accountType}_account_type`}>
                      <label className='radio-button-label'>
                        <input name={`account_type`} value={accountType} type='radio' checked={this.state.inputVals.account_type == accountType} onChange={this.handleInputValueChange} />
                        <span>{accountType}</span>
                      </label>
                    </p>
                  )
                })}
              </div>

              <div className="input-field col s12">
                <RequiredFieldLabel
                  name={'Account holder full name'}
                  htmlFor={`${this.divID}-form-full-name-input`}
                  forceActive={this.state.inputVals['full_name'] !== ''}
                />
                <input required name='full_name' type='text' autoComplete='off' value={this.state.inputVals['full_name']} onChange={this.handleInputValueChange} id={`${this.divID}-form-full-name-input`} className='modal-form__input'></input>
              </div>

              <div className="input-field col s12">
                <RequiredFieldLabel
                  name={'Email'}
                  htmlFor={`${this.divID}-form-email-input`}
                  forceActive={this.state.inputVals['email'] !== ''}
                />
                <input required name='email' type='text' autoComplete='off' value={this.state.inputVals['email']} onChange={this.handleInputValueChange} id={`${this.divID}-form-email-input`} className='modal-form__input'></input>
              </div>

              {/* field_one, field_two */}
              { Object.keys(this.state.currentFieldNameMappings).map((fieldName) => {
                const currentMapping = this.state.currentFieldNameMappings[fieldName]
                const forceLabelActive = this.state.inputVals[fieldName] !== ''
                const labelHtmlFor = `${this.divID}-form-${fieldName}-input`

                return(
                  <div className="input-field col s12" key={`${fieldName}_input_group`}>
                    { currentMapping['required'] &&
                      <RequiredFieldLabel
                        name={currentMapping['display_name']}
                        htmlFor={labelHtmlFor}
                        forceActive={forceLabelActive}
                      />
                    }

                    { !currentMapping['required'] &&
                      <label className={this.props.forceActive ? 'active' : ''} htmlFor={labelHtmlFor}>
                        {currentMapping['display_name']}
                      </label>
                    }

                    <input required={currentMapping['required']} name={fieldName} type='text' autoComplete='off' value={this.state.inputVals[fieldName]} data-field-name={fieldName} onKeyUp={this.resetInputFormLengthValidationText} onChange={this.handleInputValueChange} id={`${this.divID}-form-${fieldName}-input`} className='modal-form__input'></input>

                    { this.state.currentFieldNameMappings[fieldName]['length_validation'] &&
                      <div className='modal--bank-account-details-form__input-form-length-validation-text' id={`${fieldName}-input-form-length-validation-text`}>
                        {this.state.currentFieldNameMappings[fieldName]['length_validation'].text}
                      </div>
                    }
                  </div>
                )
              })}
                
              { this.state.apiRequestError &&
                <div className='modal--bank-account-details-form__api-request-error'>
                  <p>Sorry! something went wrong</p>
                  <div className='centered-flex-container-column'>
                    <i className="fa fa-solid fa-xmark fa-lg" onClick={() => { this.setState({ apiRequestError: false }) }} ></i>
                  </div>
                </div>
              }

              <div className='modal-form__submit'>
                <LoaderActionButton
                  text={`${this.isPersisted ? 'Update' : 'Save' } Details`}
                  buttonType={'submit'}
                  faIconClass={this.isPersisted ? 'fa-check' : 'fa-floppy-disk'}
                  secondaryClassName={'padding-left padding-right'}
                  isLoading={this.state.isRunningApiRequest}
                  onClick={this.submitForm}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default BankAccountDetailsFormModal
