import React from 'react'
import SiteLinks from './SiteLinks'

class Footer extends React.Component {

  render() {
    return(
      <footer className={`footer footer--${this.props.currentPage}`}>
        <div className='container'>
          <div className={`footer__site-links footer__site-links--${this.props.currentPage}`}>
            <div className='site-links--bottom__element'>
              ©&nbsp;
              <span>{new Date().getFullYear()}</span>
            </div>
            <SiteLinks
              isShowing={true} // CSS breakpoints will determine the visibility
              position={'bottom'}
              currentPage={this.props.currentPage}
            />
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
