import React, { useState } from 'react'
import LoadingSpinner from './LoadingSpinner'

function ExamplePrintPreviewPhoto(props) {
  const assetsCloudfrontUrl = document.getElementById('assets_cloudfront_url').value
  const [isLoaded, setIsLoaded] = useState(false)
  const previewImageFilename = props.filename
  const fullImageFilename = previewImageFilename.replaceAll('preview', 'full')

  return(
    <div className='example-print-preview-photo'>
      <a href={isLoaded ? `${assetsCloudfrontUrl}/${fullImageFilename}` : null}>
        { !isLoaded &&
          <div className='example-print-preview-photo__loading-spinner'>
            <LoadingSpinner
              isShowing={true}
              size={'big'}
              colour={'blue'}
            />
          </div>
        }

        <img
          className='example-print-preview-photo__photo'
          onLoad={(_e) => { setIsLoaded(true) }}
          src={`${assetsCloudfrontUrl}/${previewImageFilename}`}
        />
      </a>
    </div>
  )
}

export default ExamplePrintPreviewPhoto
