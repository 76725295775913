import React from 'react'
import ModalCloseIcon from '../../modals/ModalCloseIcon'
import LoaderActionButton from '../../LoaderActionButton'

import ModalHelper from '../../../helpers/ModalHelper'

class NavLinks extends React.Component {
  constructor(props) {
    super(props)

    this.divID = 'admin-nav-links-modal'
  }

  componentDidMount() { 
    this.modalInstance = ModalHelper.initialiseModal(null, this)
  }

  closeModal = () => {
    this.modalInstance.close()
  }

  render() {
    const currentPage  = this.props.currentPage

    return(
      <div id={this.divID} className='modal modal--nav-links'>
        <div className='modal-content'>
          <ModalCloseIcon
            modalID={this.divID}
            closeModal={this.closeModal}
          />

          <h5>Admin Links</h5>

          <p className='purple-text'>
            {this.props.adminEmail}
          </p>

          <div className='modal--nav-links__links'>
            { currentPage !== 'home' &&
              <LoaderActionButton
                text={'Admin Home'}
                href={'/dreams_admin'}
                faIconClass={'fa-arrow-right'}
              />
            }

            { currentPage !== 'series-index' &&
              <LoaderActionButton
                text={'Series'}
                href={'/dreams_admin/series'}
                faIconClass={'fa-arrow-right'}
              />
            }

            { currentPage !== 'orders' &&
              <LoaderActionButton
                text={'Orders'}
                href={'/dreams_admin/orders'}
                faIconClass={'fa-arrow-right'}
              />
            }

            {/* TODO: Print jobs index */}
            { currentPage !== 'print_jobs' &&
              <LoaderActionButton
                text={'Current Print Job'}
                href={'/dreams_admin/print_jobs/current'}
                faIconClass={'fa-arrow-right'}
              />
            }

            <LoaderActionButton
              text={'Customers (TODO)'}
              faIconClass={'fa-arrow-right'}
            />

            <LoaderActionButton
              text={'Affiliates (TODO)'}
              faIconClass={'fa-arrow-right'}
            />
          </div>


        </div>
      </div>
    )
  }
}

export default NavLinks
