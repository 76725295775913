import React from 'react'
import CartPhoto from './CartPhoto'
import CostsReview from './orders/CostsReview'
import PhotosHelper from '../helpers/PhotosHelper'

class CartPhotos extends React.Component {
  render() {
    const photos = this.props.photos
    
    if(photos.length) {
      const printSizings = Object.values(this.props.printSizings)

      return(
        <div className='cart-photos'>
          { printSizings.length &&
            <CostsReview
              printsCost={PhotosHelper.totalCost(printSizings)}
              totalCost={PhotosHelper.totalCost(printSizings)}
            />
          }
  
          {
            photos.map((photo) => {
              return(
                <CartPhoto
                  key={`cart-photo-${photo.token}`}
                  photo={photo}
                  removeFromCart={this.props.removeFromCart}
                />
              )
            })
          }
        </div>
      )
    }
    else {
      return null
    }
  }
}

export default CartPhotos;
