import React from 'react';
import ModalCloseIcon from './ModalCloseIcon';
import ModalHelper from '../../helpers/ModalHelper'

class CryptoAddressModal extends React.Component {
  constructor(props) {
    super(props)

    this.assetsCloudfrontUrl = document.getElementById('assets_cloudfront_url').value
    this.divID = `${props.crypto}-crypto-address-modal`
  }

  componentDidMount() {
    this.modalInstance = ModalHelper.initialiseModal(this.divID, this)
  }

  closeModal = () => {
    this.modalInstance.close()
  }

  render() {
    const metadata = this.props.metadata

    return(
      <div id={this.divID} className='modal crypto-address-modal'>
        <div className='modal-content'>
          <ModalCloseIcon
            closeModal={this.closeModal}
            modalID={this.divID}
          />

          <h5>{`${metadata['name']} Deposit Address`}</h5>

          <img src={`${this.assetsCloudfrontUrl}/${this.props.crypto}-address-qr.jpg`} />

          <p>{metadata['address']}</p>
        </div>
      </div>
    )
  }
}

export default CryptoAddressModal
