import React from 'react'

class RequiredFieldLabel extends React.Component {
  render() {
    return(
      <label className={this.props.forceActive ? 'active' : ''} htmlFor={this.props.htmlFor}>
        <span>{this.props.name}</span>
        <span>*</span>
      </label>
    )
  }
}

export default RequiredFieldLabel
