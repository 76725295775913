import React from 'react';

class Controls extends React.Component {
  render() {
    if(this.props.isShowing) {
      return(
        <div className='container'>
          
        </div>
      )
    }
    else {
      return null;
    }
  }
}

export default Controls;
