import React from 'react'
import LoaderActionButton from '../../LoaderActionButton'

function ConfirmStep(props) {
  const orderStatusText = () => {
    const printsPluralised = props.pluraliseCollectionName('Print')

    if(props.isPaid) {
      if(props.deliveryMethod === 'pickup') {
        return `Your ${printsPluralised} have been marked for production and can be picked up in Puerto Escondido as soon as they're ready.`
      }
      else {
        return `Your ${printsPluralised} have been marked for production and will be sent out to you as soon as they're ready.`
      }
    }
    else {
      if(props.paymentMethod === 'cash') {
        if(props.deliveryMethod === 'delivery') {
          return `You can make your cash payment to me in person and your ${printsPluralised} will be marked for production and shipped when they're ready.`
        }
        else {
          return `You can make your cash payment and pickup your ${printsPluralised} in person.`
        }
      }
      else if(props.paymentMethod === 'card') {
        // this option shouldn't strictly be possible, but include it just incase the stripe callback error handling failed
        if(props.deliveryMethod === 'delivery') {
          return `Your ${printsPluralised} will be automatically marked for production and sent out to you once your card payment is successful. If you'd prefer you can also choose a different payment option.`
        }
        else {
          return `Your ${printsPluralised} will be automatically marked for production and can be picked up once your card payment is successful. If you'd prefer you can also choose a different payment option.`
        }
      }
      else {
        // crypto
        if(props.deliveryMethod === 'delivery') {
          return `Once your ${props.paymentMethod} transaction is received, your ${printsPluralised} will be automatically marked for production and shipped out to you as soon as they're ready.`
        }
        else {
          return `Once your ${props.paymentMethod} transaction is received, your ${printsPluralised} will be automatically marked for production and can be picked up in Puerto Escondido as soon as they're ready.`
        }
      }
    }
  
  }

  if(props.isPaid || props.isConfirmed) {
    return(
      <div className='complete-step'>
        <div className='order-checkout__section'>
          { props.isPaid &&
            <p>
              Great! Your Order is paid and confirmed.
            </p>
          }
          { !props.isPaid &&
            <p>
              Great! Your Order is confirmed and awaiting payment.
            </p>
          }
        </div>

        <div className='order-checkout__section'>
          <div className='complete-step__link-or-action'>
            <LoaderActionButton
              text={'Go to my Order'}
              href={`/orders/${props.orderToken}`}
              faIconClass={'fa-arrow-right'}
              secondaryClassName={'complete-step__link-or-action-button'}
            />
            <p>
              { orderStatusText() }
            </p>
          </div>
        </div>
      </div>
    )
  }
  else {
    return(
      <div className='complete-step'>

        <div className='order-checkout__section'>
          <p>
            Everything looking good? Confirm your Order to get it into the system
          </p>
        </div>

        <div className='order-checkout__section'>
          <div className='complete-step__link-or-action'>
            <a className={`complete-step__link-or-action-button button button--action ${props.isCompletingOrder ? 'button--disabled' : ''}`} onClick={props.confirmOrder}>
              <div className='contents-wrapper'>
                <span>Confirm my Order</span>
                <i className='fa fa-regular fa-thumbs-up'></i>
              </div>
            </a>

            { props.isCompletingOrder &&
              <div className="request-loading">
                <div className="indeterminate"></div>
              </div>
            }
            { props.hasconfirmOrderError &&
              <div className='complete-step__complete-order-error'>
                Sorry! Something went wrong just then while trying to confirm your Order
              </div>
            }
            <p>
              You can still come back and change the selected delivery and payment methods if you need to, right up until the Order is paid.
            </p>
          </div>

        </div>
      </div>
    )
  }
}

export default ConfirmStep
