import React from 'react'
import PhotosHelper from '../../helpers/PhotosHelper'
import CartPhoto from '../CartPhoto'
import CostsReview from './CostsReview'

class ReviewOrderPhotos extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      issuesWithSelection: false,
      accepted: false,
      imagesLoaded: []
    }
  }

  onImageLoaded = (photoToken) => {
    let imagesLoaded = this.state.imagesLoaded

    if(!imagesLoaded.includes(photoToken)) {
      imagesLoaded.push(photoToken)
    }

    this.imagesLoaded = imagesLoaded
    this.setState({ imagesLoaded })
  }

  render() {
    const photos = this.props.photos
    const allImagesLoaded = this.state.imagesLoaded.length >= photos.length
    const printSizings = photos.map((photo) => photo.print_sizing.size)

    return(
      <div className='review-order-photos'>
        {
          photos.map((photo) => {
            return(
              <div className='margin-bottom' key={`cart-photo-${photo.token}`}>
                <CartPhoto
                  optionsDisabled={this.props.isPaid || this.props.optionsDisabled}
                  photo={photo}
                  selectPrintSize={this.props.selectPrintSize}
                  removeFromCart={this.props.removeFromCart}
                  onImageLoaded={this.onImageLoaded}
                />
              </div>
            )
          })
        }

        { allImagesLoaded && this.props.printsCost && this.props.totalCost &&
          <CostsReview
            isPaid={this.props.isPaid}
            discountCodeMetadata={this.props.discountCodeMetadata}
            deliveryMethod={this.props.deliveryMethod}
            pluraliseCollectionName={this.props.pluraliseCollectionName}
            printsCost={this.props.printsCost || PhotosHelper.totalCost(printSizings)}
            totalCost={this.props.totalCost || PhotosHelper.totalCost(printSizings)}
            shippingCost={this.props.shippingCost}
            cryptoPriceData={this.props.cryptoPriceData}
            actionDisabled={this.props.actionDisabled}
            action={this.props.isPaid ? null : this.props.completeStep}
            actionText={this.props.isPaid ? null : 'Continue'}
          />
        }
      </div>
    )
  }
}

export default ReviewOrderPhotos
