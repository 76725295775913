import React, { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import StripePaymentForm from '../../forms/StripePaymentForm'
import ApplyDiscountCodeForm from '../../forms/ApplyDiscountCodeForm'
import CostsReview from '../CostsReview'
import PaymentStepCryptoOptions from '../PaymentStepCryptoOptions'
import ApiService from '../../../services/ApiService'

import PageScrollHelper from '../../../helpers/PageScrollHelper'
import CryptoHelper from '../../../helpers/CryptoHelper'

function PaymentStep(props) {
  const cryptos = CryptoHelper.cryptos()

  const cryptoIsSelected = cryptos.includes(props.paymentMethod)

  const [cryptoPriceData, setCryptoPriceData] = useState(null)
  const [fetchingCryptoPriceData, setFetchingCryptoPriceData] = useState(false)

  useEffect(() => {
    if (cryptoIsSelected) {
      fetchCryptoPriceData()
    }
  }, [cryptoIsSelected]) // triggers whenever crypto payment method is selected / changed

  // if no crypto token passed, then use one that is already set on order (as paymentMethod) - in this case just refreshing price
  const fetchCryptoPriceData = (crypto = props.paymentMethod) => {
    if (!cryptoIsSelected) {
      return
    }

    // if order is confirmed/paid, then crypto quote is already locked in and stored on the payment details
    // use this instead of fetching a fresh quote
    if((props.isConfirmed || props.isPaid) && props.cryptoPriceData && Object.keys(props.cryptoPriceData).length > 0) {
      setCryptoPriceData(props.cryptoPriceData)
      return
    }

    setFetchingCryptoPriceData(true)

    ApiService.getCryptoQuote(props.orderToken, crypto)
      .then((response) => {
        setFetchingCryptoPriceData(false)

        if (response.success && response.data) {
          setCryptoPriceData(response.data)
          // propagate state to parent (needed when refreshed)
          props.setCryptoPriceData(response.data)
        }
      })
  }

  const [cardFormShowing, setCardFormShowing] = useState(!props.isPaid && props.paymentMethod === 'card')
  const [cryptoFormShowing, setCryptoFormShowing] = useState(!props.isPaid && cryptoIsSelected)

  const stripePublishableKey = document.getElementById('stripe_publishable_key').value
  const stripePromise = loadStripe(stripePublishableKey)

  const discountCodeMetadata = props.discountCodeMetadata

  const selectCard = () => {
    if(props.isPaid ||  props.paymentMethod === 'card') {
      return
    }

    setCryptoPriceData(null)
    props.selectPaymentMethod('card')

    setCardFormShowing(true)
    setCryptoFormShowing(false)
  }

  const selectCrypto = (crypto) => {
    if(props.isConfirmed || props.isPaid || (cryptoIsSelected && crypto === props.paymentMethod)) {
      return
    }
    setCryptoPriceData({ crypto_token_price_usd: '...' }) // placeholder while it loads

    props.selectPaymentMethod(crypto)

    setCardFormShowing(false)
    setCryptoFormShowing(true)
    fetchCryptoPriceData(crypto)

    window.setTimeout(() => {
      PageScrollHelper.scrollToBottom()
    }, 50)
  }

  const selectCash = () => {
    if(props.isPaid || props.paymentMethod === 'cash') {
      return
    }
    setCryptoPriceData(null)

    props.selectPaymentMethod('cash')

    setCardFormShowing(false)
    setCryptoFormShowing(false)
  }

  const methodSelectionText = () => {
    const paymentMethod = props.paymentMethod

    if (paymentMethod === null) {
      return `How would you like to pay for your ${props.pluraliseCollectionName('Print')}?`
    }

    if (paymentMethod === 'card') {
      if(props.isPaid) {
        return props.stripePaymentDetails ? `Paid with ${props.stripePaymentDetails}` : 'Paid with Card'
      }
      else if(cardFormShowing) {
        return 'Enter Card details'
      }
    }
    else if (cryptoIsSelected) {
      if(props.isPaid) {
        return `Order paid. ${CryptoHelper.metadata(paymentMethod).symbol} deposit received to address ${CryptoHelper.metadata(paymentMethod).address}`
      }
      else {
        return `Pay for your ${props.pluraliseCollectionName('Print')} with Cryptocurrency`
      }
    }
    else if(paymentMethod === 'cash') {
      if(props.isPaid) {
        return 'Order paid with Cash.'
      }
      else {
        return `Pay Cash for your ${props.pluraliseCollectionName('Print')} in Puerto Escondido`
      }
    }
  }

  const applyDiscountCodeSuccess = (responseData) => {
    // responseData is serialised order (combined with flash data for convenience)
    props.applyDiscountCode(responseData)
  }

  const stripeClientSecret = props.paymentIntentClientKey

  const stripePaymentFormOptions = {
    clientSecret: stripeClientSecret,
    appearance: {
      theme: 'stripe',
      fontFamily: 'PublicSans',
      fontSizeSm: '14px',
      fontSizeLg: '16px',
      colorPrimaryText: '#3B3F44',
      spacingGridRow: '1rem',
      spacingGridColumn: '1rem'
    }
  }

  return (
    <div className="payment-step">
      <div className="order-checkout__section">
        <div>
          <p>{methodSelectionText()}</p>
          { cryptoIsSelected && !props.isPaid &&
            <p>(BTC / ETH / ADA)</p>
          }
          
          { props.isPaid && props.paidAt &&
            <p>{`Payment received ${props.paidAt}`}</p>
          }

        </div>
      </div>

      <div className="order-checkout__section">
        <div className="payment-step__options-panel">
          { stripeClientSecret &&
            <a className={`button button--selectable button--full-width ${props.paymentMethod === 'card' ? 'selected' : ''} ${props.paymentMethod !== 'card' && props.isPaid ? 'button--disabled' : ''}`} onClick={selectCard}>
              <div className='contents-wrapper w-22'>
                <span>Card</span>
                <i className="fa fa-regular fa-credit-card"></i>
              </div>
            </a>
          }

          <a className={`button button--selectable button--full-width ${props.paymentMethod === 'cash' ? 'selected' : ''} ${props.paymentMethod !== 'cash' && props.isPaid ? 'button--disabled' : ''}`} onClick={selectCash}>
            <div className='contents-wrapper w-22'>
              <span>Cash</span>
              <i className="fa fa-solid fa-money-bill-1-wave"></i>
            </div>
          </a>

          { cryptos.length > 0 &&
            <a className={`button button--selectable button--full-width ${cryptoIsSelected ? 'selected' : ''} ${props.paymentMethod !== 'crypto' && props.isPaid ? 'button--disabled' : ''}`} onClick={(_e) => { selectCrypto('bitcoin') }}>
              <div className='contents-wrapper w-25'>
                <span>Crypto</span>
                <i className="fa fa-brands fa-btc"></i>
              </div>
            </a>
          }

          {(cryptoIsSelected || props.paymentMethod === 'cash') && !props.isPaid && props.navegableSteps.includes('confirm') &&
            <a className='button button--action button--full-width' onClick={props.completeStep}>
              <div className='contents-wrapper'>
                <span>Continue</span>
                <i className='fa fa-solid fa-arrow-right'></i>
              </div>
            </a>
          }
        </div>
      </div>
      
      <div className='order-checkout__section'>
        <div className='payment-step__discount-code' style={{ display: props.isPaid ? 'none' : 'block' }}>
          { discountCodeMetadata &&
            <React.Fragment>
              <p>{`Discount Code Applied:`}</p>
              <h5>{discountCodeMetadata['code']}</h5>
            </React.Fragment>
          }

          { !discountCodeMetadata && !props.isPaid &&
            <React.Fragment>
              <p>Discount Code? Add it here</p>

              <ApplyDiscountCodeForm
                orderToken={props.orderToken}
                applyDiscountCodeSuccess={applyDiscountCodeSuccess}
              />
            </React.Fragment>
          }
        </div>
      </div>

      <div className='order-checkout__section'>
        <div className='payment-step__costs-review'>
          <CostsReview
            isPaid={props.isPaid}
            discountCodeMetadata={discountCodeMetadata}
            deliveryMethod={props.deliveryMethod}
            pluraliseCollectionName={props.pluraliseCollectionName}
            printsCost={props.printsCost || PhotosHelper.totalCost(printSizings)}
            totalCost={props.totalCost || PhotosHelper.totalCost(printSizings)}
            shippingCost={props.shippingCost}
            cryptoPriceData={cryptoPriceData}
          />
        </div>
      </div>

      { !props.isPaid &&
        <div className="order-checkout__section">
          { cryptoIsSelected && cryptoFormShowing &&
            <div className="payment-step__crypto">
              <PaymentStepCryptoOptions
                cryptos={CryptoHelper.cryptos()}
                selectCrypto={selectCrypto}
                cryptoPriceData={cryptoPriceData}
                fetchCryptoPriceData={fetchCryptoPriceData}
                fetchingCryptoPriceData={fetchingCryptoPriceData}
                selectedCrypto={props.paymentMethod}
                isPaid={props.isPaid}
                isConfirmed={props.isConfirmed}
              />
            </div>
          }

          { props.paymentMethod === 'card' && cardFormShowing && stripeClientSecret &&
            <div className="payment-step__card">
              <Elements options={stripePaymentFormOptions} stripe={stripePromise}>
                <StripePaymentForm
                  orderToken={props.orderToken}
                />
              </Elements>
            </div>
          }
        </div>
      }

    </div>
  )
}

export default PaymentStep
