import React from 'react'
import Navbar from './Navbar'
import ExamplePrintPhotos from './ExamplePrintPhotos'
import SocialLinksModal from './modals/SocialLinksModal'

import Footer from './Footer'
import LoaderActionButton from './LoaderActionButton'

function ExamplePrints() {

  const photosData = JSON.parse(document.getElementById('photos_data').value)
  const renderAllPhotosLink = photosData.length === 1

  return(
    <React.Fragment>
      <Navbar title='keenedreams'/>


      <div className='example-prints'>
        <p className='example-prints__header-text'>
        </p>


        { renderAllPhotosLink &&
          <div className='example-prints__load-all-photos-link'>
            <LoaderActionButton
              text={'Load more print photos'}
              href={'/example_prints'}
              faIconClass={'fa-rotate-right'}
            />
          </div>
        }

        {
          photosData.map((photoData) => {
            return(
              <ExamplePrintPhotos
                key={`example-print-photo-${photoData.token}`}
                photoData={photoData}
              />
            )
          })
        }
      </div>

      <SocialLinksModal />

      <Footer currentPage={'example-prints'} />      
    </React.Fragment>
  )
}

export default ExamplePrints
