import React, { useState } from 'react'
import PaymentStepCryptoOption from './PaymentStepCryptoOption'
import CryptoHelper from '../../helpers/CryptoHelper'

function PaymentStepCryptoOptions(props) {
  const canUpdate = !(props.isConfirmed || props.isPaid)
  const [selectedCrypto, setSelectedCrypto] = useState(props.selectedCrypto)

  const selectCrypto = (crypto, e) => {
    if(!canUpdate) {
      e.preventDefault()
      return
    }
    // TODO: callback?
    setSelectedCrypto(crypto)
    props.selectCrypto(crypto)
  }

  return(
    <div className='payment-step-crypto-options'>
      { canUpdate &&
        <div className='inline-options-selector'>
          <div className='inline-options-selector__label'>
            Select CryptoCurrency
          </div>

          <div className='inline-options-selector__options'>
            {
              props.cryptos.map((crypto) => {
                const metadata = CryptoHelper.metadata(crypto)

                return(
                  <div key={`select-${crypto}-option`} onClick={(e) => { selectCrypto(crypto, e) }} className={`inline-options-selector__option ${selectedCrypto === crypto ? 'inline-options-selector__option--selected' : ''}`}>
                    <span>{ metadata['symbol'] }</span>
                  </div>
                )
              })
            }
          </div>
        </div>
      }

      {
        props.cryptos.map((crypto) => {
          if(props.selectedCrypto === crypto) {
            return(
              <PaymentStepCryptoOption
                key={`payment-step-crypto-${crypto}-option`}
                isConfirmed={props.isConfirmed}
                isPaid={props.isPaid}
                crypto={crypto}
                cryptoPriceData={props.cryptoPriceData}
                fetchCryptoPriceData={props.fetchCryptoPriceData}
                fetchingCryptoPriceData={props.fetchingCryptoPriceData}
              />
            )
          }
          else {
            return null
          }
        })
      }
    </div>
  )
}

export default PaymentStepCryptoOptions
