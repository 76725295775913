import React from 'react'
import ModalCloseIcon from './ModalCloseIcon'

import ModalHelper from '../../helpers/ModalHelper'

class SocialLinks extends React.Component {
  constructor(props) {
    super(props)

    this.divID = 'social-links-modal'
  }

  componentDidMount() { 
    this.modalInstance = ModalHelper.initialiseModal(null, this)
  }

  closeModal = () => {
    this.modalInstance.close()
  }

  render() {
    return(
      <div id={this.divID} className='modal modal--nav-links'>
        <div className='modal-content'>
          <ModalCloseIcon
            modalID={this.divID}
            closeModal={this.closeModal}
          />

          <h5>Contact / Social Media</h5>

          <p>
            If you have any questions or just want to get in touch, you can send me an email or message me on social media :)
          </p>

          <div className='modal--nav-links__links'>
            <a href='mailto:caleb@keenedreams.io' className='button button--action'>
              <span>caleb@keenedreams.io</span>
              <i className="fa-regular fa-envelope"></i>
            </a>
          
            <a target="_blank" href="https://youtube.com/@keenedreams?si=MUCPnababwuDXmZv" className='button button--action'>
              <span>Youtube</span>
              <i className="fa-regular fa-brands fa-youtube"></i>
            </a>

            <a target="_blank" href="https://www.instagram.com/keenedreams" className='button button--action'>
              <span>Instagram</span>
              <i className="fa-regular fa-brands fa-instagram"></i>
            </a>
            
            <a target="_blank" href="https://www.tiktok.com/@keenedreams" className='button button--action'>
              <span>Tiktok</span>
              <i className="fa-regular fa-brands fa-tiktok"></i>
            </a>
          </div>

        </div>
      </div>
    )
  }
}

export default SocialLinks
