import React from 'react';
import ModalCloseIcon from './ModalCloseIcon';
import ModalHelper from '../../helpers/ModalHelper'

class CancelOrderModal extends React.Component {
  constructor(props) {
    super(props)

    this.divID = `cancel-order-${props.orderToken}-modal`

    this.paymentMethodIsCrypto = ['bitcoin', 'ethereum', 'cardano'].includes(props.paymentMethod)
  }

  componentDidMount() {
    this.modalInstance = ModalHelper.initialiseModal(this.divID, this)
  }

  closeModal = () => {
    this.modalInstance.close()
  }

  paidOrderCancelText = () => {
    const paymentMethod = this.props.paymentMethod

    if (paymentMethod === 'cash') {
      return "If you decide to cancel I'll get in touch about a refund of your cash payment"
    }
    else if(paymentMethod === 'card') {
      if(this.props.stripePaymentDetails) {
        return `If you decide to cancel a full refund will be issued to your ${this.props.stripePaymentDetails}`
      }
      else {
        return 'If you decide to cancel a full refund will be issued.'
      }
    }
    else if(paymentMethod === 'other') {
      return 'If you decide to cancel a full refund will be issued.'
    }
    else if(this.paymentMethodIsCrypto) {
      // crypto
      return `If you decide to cancel you will receive a refund to the ${paymentMethod} address from which you made the original deposit.`
    }
  }

  confirmCancel = () => {
    if(window.confirm('Cancel Order?')) {
      window.location = `/orders/${this.props.orderToken}/cancel`
    }
  }

  render() {
    return(
      <div id={this.divID} className='modal cancel-order-modal'>
        <div className='modal-content'>
          <ModalCloseIcon
            closeModal={this.closeModal}
            modalID={this.divID}
          />

          <h5>Cancel Order</h5>

          <p>Your Prints are almost under production! Are you sure you want to cancel your order?</p>

          { this.props.isPaid &&
            <p>{this.paidOrderCancelText()}</p>
          }

          { this.props.isPaid && this.paymentMethodIsCrypto &&
            <p className='cancel-order-modal__crypto-transaction-fees-warning'>
              {`Please note that this refund will exclude the cost of any ${this.props.paymentMethod} network transaction fees.`}
            </p>
          }

          <div className='cancel-order-modal__links'>
            <a onClick={this.closeModal} className='button button--action'>
              <div className='contents-wrapper'>
                <i className='fa fa-solid fa-arrow-left'></i>
                <span></span>
              </div>
            </a>
            <a onClick={this.confirmCancel} className='button button--action'>
              <div className='contents-wrapper'>
                <span></span>
                <i className='fa fa-solid fa-trash-can'></i>
              </div>
            </a>
          </div>

        </div>
      </div>
    )
  }
}

export default CancelOrderModal
