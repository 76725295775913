import React from 'react'
import CardContents from './CardContents'

function Card(props) {
  const seriesData = props.seriesData
  
  return(
    <a className='series-card' href={`/series/${seriesData.slug}`}>
      <CardContents seriesData={seriesData} />
    </a>
  )
}

export default Card
