import React from 'react'
import CardContents from '../../series/CardContents'

function Card(props) {
  const seriesData = props.seriesData
  
  return(
    <div className='series-card series-card--admin'>
      <CardContents
        seriesData={seriesData}
        renderLinks={true}
        deleteButtonClickHandler={props.deleteButtonClickHandler}
      />
    </div>
  )
}

export default Card
