import React from 'react';

class ModalCloseIcon extends React.Component {
  render() {
    return(
      <div className='modal__close-button'>
        <i id={`close-modal-${this.props.modalID}`} className="fa fa-solid fa-xmark fa-lg" onClick={this.props.closeModal} ></i>
      </div>
    )
  }
}

export default ModalCloseIcon;
