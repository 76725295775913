import React from 'react'

function TotalPayout(props) {

  if(props.isShowing) {
    const description_text = {
      awaiting_payout: 'Total payout that you are waiting to receive',
      upcoming_payout: 'Total upcoming payout amount',
      received_payout: 'Total received from affiliate order payouts'
    }[props.ordersType]

    return(
      <React.Fragment>
        <div className='centered-flex-container-row margin-top padding-top'>
          <div className='centered-flex-container-column'>
            <p className='orders-table-description-text'>{description_text}</p>
          </div>
        </div>

        <table className='affiliate-orders-table'>
          <tbody>
            <tr>
              <td>TOTAL:</td>
              <td>{props.total}</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    )
  }
  else {
    return null
  }
}

export default TotalPayout