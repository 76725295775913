import React from 'react'
import ApiService from '../services/ApiService'
import ModalHelper from '../helpers/ModalHelper';
import AdminNavLinksModal from './admin/modals/NavLinksModal';

function AdminFooter(props) {

  const resourceName = props.resourceName || document.getElementById('resource_name').value
  const resourceEmail = document.getElementById('resource_email').value

  // TODO: move to helper and share with affiliate signout
  const signOutResource = () => {
    const headers = ApiService.loadRequestHeaders()

    fetch(`/${resourceName}s/sign_out`, { method: 'DELETE', headers })
      .then((response) => {
        if(response.ok) {
          window.location = `/${resourceName}s/sign_in`
        }
        else {
          console.error("dang! something went wrong while trying to sign you out")
        }
      })
  }

  return(
    <footer className={`footer footer--${resourceName}`}>
      <div className='container'>
        <div className={`footer__site-links footer__site-links--${resourceName}`}>

          <AdminNavLinksModal
            adminEmail={resourceEmail}
            currentPage={props.currentPage}
          />

          <div className='site-links--bottom__element'>
            <a onClick={() => { ModalHelper.openModalClickHandler('admin-nav-links-modal') }}>
              Links
            </a>
          </div>

          <div className='site-links--bottom__element'>
            <a onClick={signOutResource}>
              Sign out
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default AdminFooter
