import React from 'react';
import LocalStorageContext from './contexts/LocalStorageContext'
import LocalStorageHelper from '../helpers/LocalStorageHelper'

import ModalHelper from '../helpers/ModalHelper'

class SiteLinks extends React.Component {
  openPrintsListModal = (_ev) => {
    ModalHelper.openModalClickHandler('prints-list-modal');
  }

  static contextType = LocalStorageContext;

  render() {
    const currentCartPhotos = (() => {
      if(this.context) {
        return this.context.currentCartPhotos
      }
      else {
        return []
      }
    })()

    const hasCartItems = currentCartPhotos.length > 0
    const renderWritingLink = this.props.currentPage !== 'series-index' && document.getElementById('has_series').value === 'true'

    const currentOrderToken = LocalStorageHelper.currentOrderToken()

    if(this.props.isShowing) {
      if(this.props.position === 'right') {
        return(
          <div className='info-box-right site-links--right'>
            <div className='info-box-right__wrapper'>
              <p>Site Links</p>
              <hr />

              <a onClick={() => { ModalHelper.openModalClickHandler('social-links-modal') }}>
                Social
              </a>

              <hr />
              <a href="/about">About</a>

              { renderWritingLink &&
                <hr />
              }

              { renderWritingLink &&
                <a href="/series">
                  Series
                </a>
              }

              { hasCartItems &&
                <hr />
              }

              { hasCartItems && currentOrderToken &&
                <a id='right-prints-list-link' href={`/orders/${currentOrderToken}/checkout`} style={{ cursor: 'pointer' }}>
                  {`Cart (${currentCartPhotos.length})`}
                </a>
              }

              { hasCartItems && !currentOrderToken &&
                <a id='right-prints-list-link' onClick={this.openPrintsListModal} style={{ cursor: 'pointer' }}>
                  {`Cart (${currentCartPhotos.length})`}
                </a>
              }

            </div>
          </div>
        )
      }
      else { // bottom (within footer)
        const currentPage = this.props.currentPage

        return(
          <React.Fragment>
            { ['about', 'example-prints', 'order-checkout', 'order-show', 'photo-show', 'series-index', 'series-show'].includes(currentPage) &&
              <div className='site-links--bottom__element'>
                <a href='/photos'>Photos</a>
              </div>
            }

            { renderWritingLink && ['home', 'about', 'photo-show', 'order-checkout', 'series-show'].includes(currentPage) &&
              <div className='site-links--bottom__element'>
                <a href="/series">
                  Series
                </a>
              </div>
            }

            { ['home', 'example-prints', 'order-checkout', 'order-show', 'photo-show', 'series-index', 'series-show'].includes(currentPage) &&
              <div className='site-links--bottom__element'>
                <a href='/about'>About</a>
              </div>
            }

            { currentPage !== 'photo-show' &&
              <div className='site-links--bottom__element'>
                <a onClick={() => { ModalHelper.openModalClickHandler('social-links-modal') }}>
                  Social
                </a>
              </div>
            }

            { hasCartItems && currentOrderToken &&
               <div className='site-links--bottom__element'>
                <a id='bottom-prints-list-link' href={`/orders/${currentOrderToken}/checkout`} style={{ cursor: 'pointer' }}>
                  &nbsp; {`(${currentCartPhotos.length})`}
                </a>
              </div>
            }

            { hasCartItems && !currentOrderToken &&
              <div className='site-links--bottom__element'>
                <a id='bottom-prints-list-link' onClick={this.openPrintsListModal} style={{ cursor: 'pointer' }}>
                  &nbsp; {`(${currentCartPhotos.length})`}
                </a>
              </div>
            }
          </React.Fragment>
        )
      }
    }
    else {
      return null;
    }
  }
}

export default SiteLinks;
