import React from 'react';

class Pagination extends React.Component {
  _isFirstPage = () => {
    return this.props.currentPage === '1'
  }

  _isLastPage = () => {
    return this.props.currentPage === this._lastPageNumber();
  }

  _lastPageNumber = () => {
    let pages = this.props.pages;

    return pages[pages.length - 1];
  }

  _individualPageLinkClass = (pageNumber) => {
    return pageNumber === this.props.currentPage ? 'active' : 'waves-effect';
  }

  _handlePageLinkClick = (event) => {
    let page = event.target.getAttribute('data-page');
    
    // previous/next -> <i> tag comes through as target instead
    if(page === null) {
      page = event.target.closest('a').getAttribute('data-page')
    }

    if(page === 'previous' && this._isFirstPage()) {
      return null;
    }
    else if(page === 'next' && this._isLastPage()) {
      return null;
    }
    else if(page === this.props.currentPage) {
      return null
    }

    this.props.changePage(page);
  }

  render() {
    if(this.props.isShowing) {
      const previoustPageLink = [
        <li key={0} className={this._isFirstPage() ? 'disabled' : ''}>
          <a onClick={this._handlePageLinkClick} data-page='previous' className={this._isFirstPage() ? 'disabled' : ''}>
            <i className="fa-solid fa-chevron-left"></i>
          </a>
        </li>
      ];
      
      const individualPageLinks = this.props.pages.map((page, key) => {
        return(
          <li key={key+1} className={this._individualPageLinkClass(page)}>
            <a onClick={this._handlePageLinkClick} data-page={page} >
              {page}
            </a>
          </li>
        ) 
      })
  
      const nextPageLink = [
        <li key={parseInt(this._lastPageNumber()) + 1} className={`${this._isLastPage() ? 'disabled' : ''}`}>
          <a onClick={this._handlePageLinkClick} data-page='next' className={this._isLastPage() ? 'disabled' : ''} >
            <i className="fa-solid fa-chevron-right"></i>
          </a>
        </li>
      ];
      
  
      const allPageLinks = previoustPageLink
        .concat(individualPageLinks)
        .concat(nextPageLink);
  
      return(
        <div className='container pagination-container'>
          <ul className="pagination">
            {allPageLinks}
          </ul>
        </div>
      )
    }
    else {
      return null;
    }
  }
}

export default Pagination;
