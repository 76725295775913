import React, { useState, useEffect } from 'react'
import RequiredFieldLabel from './RequiredFieldLabel'
import ApiService from '../../services/ApiService'

function CustomerForm(props) {
  const [updateError, setUpdateError] = useState(false)

  const dismissError = () => {
    setUpdateError(false)
  }


  const loadInput = (field) => {
    return document.getElementById(`customer-form-${field}-input`)
  }

  const loadInputValue = (field) => {
    return loadInput(field).value
  }


  const isValidEmail = (email) => {
    const validEmailRegxp = /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return email.match(validEmailRegxp)
  }

  const submitParams = () => {
    return {
      customer: {
        name: loadInputValue('name'),
        email: loadInputValue('email')
      },
      customer_note: loadInputValue('comments')
    }
  }

  const validParams = (params) => {
    const validName = params.customer['name'] && params.customer['name'] !== ''
    const validEmail = isValidEmail(params.customer['email'])

    // only run these visual validations if it's being changed
    if(props.customer) {
      const setInputValueValidityVisualState = (field, isValid) => {
        const input = document.getElementById(`customer-form-${field}-input`)
  
        if(isValid) {
          input.classList.remove('invalid')
        }
        else {
          input.classList.add('invalid')
        }
      }
      
      setInputValueValidityVisualState('name', validName)
      setInputValueValidityVisualState('email', validEmail)
    }

    return validName && validEmail
  }

  // debounce input changes
  const debounce = (func, delay) => {
    let timer
    return function () {
      const context = this
      const args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        func.apply(context, args)
      }, delay)
    }
  }

  const createOrUpdateCustomer = () => {
    const requestData = submitParams()

    if(!validParams(requestData)) {
      return
    }

    setUpdateError(false)

    ApiService.updateOrder(props.orderToken, requestData)
      .then((response) => {
        const success = response.success

        setUpdateError(!success)

        if(success) {
          const responseData = response.data

          props.successCallback(responseData)
        }
      })
  }

  const debouncedCreateOrUpdateCustomer = debounce(createOrUpdateCustomer, 1000)

  // Add an effect to run the debounced function whenever inputs change
  useEffect(() => {
    const inputFields = document.querySelectorAll('.customer-form__input')
    const handleInputChange = () => {
      debouncedCreateOrUpdateCustomer()
    }

    inputFields.forEach((input) => {
      input.addEventListener('input', handleInputChange)
    })

    return () => {
      inputFields.forEach((input) => {
        input.removeEventListener('input', handleInputChange)
      })
    }
  }, [debouncedCreateOrUpdateCustomer])

  // populate input initial values
  useEffect(() => {
    const populateField = (inputId, inputValue) => {
      const input = document.getElementById(inputId)
      const label = document.querySelector(`label[for='${inputId}']`)

      input.value = inputValue
      label.classList.add('active')
    }

    if(props.customer && Object.keys(props.customer).length > 0) {
      ['name', 'email'].forEach((field) => {
        if(props.customer[field] && props.customer[field] !== '') {
          populateField(`customer-form-${field}-input`, props.customer[field])
        }
      })
    }

    if(props.note && props.note !== '') {
      populateField('customer-form-comments-input', props.note)
    }
  })

  return(
    <div className='customer-form row'>
      <form onSubmit={(e) => { e.preventDefault() }} className="col s12">
        <div className="input-field col s12">
          <RequiredFieldLabel
            name={'Name'}
            htmlFor={'customer-form-name-input'}
          />
          <input required name='name' type='text' id='customer-form-name-input' autoComplete='name' className='customer-form__input'></input>
        </div>

        <div className="input-field col s12">
          <RequiredFieldLabel
            name={'Email'}
            htmlFor={'customer-form-email-input'}
          />
          <input required name='email' type='email' id='customer-form-email-input' autoComplete='email' className='customer-form__input'></input>
        </div>

        <div className="input-field col s12">
          <label htmlFor='customer-form-comments-input'>Comments/Notes</label>
          <textarea name='comments' id='customer-form-comments-input' className="customer-form__input materialize-textarea"></textarea>
        </div>

        { updateError &&
          <div className='customer-form__update-error col s12'>
            <i title='Dismiss' onClick={dismissError} className="fa fa-solid fa-xmark fa-lg"></i>
            <p className='api-request-error'>
              Dang! something didn't work quite right just then. Please try again, or you can &nbsp;
              <a href={`mailto:caleb@keenedreams.io?subject=Order%20${props.orderToken}`}>send me an email</a>
            </p>
          </div>
        }
      </form>
    </div>
  )
}

export default CustomerForm
