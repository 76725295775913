import React from 'react'
import PhotoImage from './PhotoImage'
import PhotoModalWrapper from './modals/PhotoModalWrapper'
import ActionButton from './ActionButton'
import PhotoStatusIcon from './admin/PhotoStatusIcon'

import PhotosHelper from '../helpers/PhotosHelper'
import ModalHelper from '../helpers/ModalHelper'

class Photo extends React.Component {
  constructor(props) {
    super(props)
    
    // observed means there's a IntersectionObserver watching it, and waiting to set the imageUrl
    this.state = {
      observing: true,
      photoLoaded: false,
      showPhotoDisplayableName: false
    }
  }

  componentDidMount() {
    if(this.props.lazyLoad) {
      this._createIntersectionObserver()
    }
  }

  _openPhotoModalClickHandler = (ev) => {
    this.setState({ showPhotoDisplayableName: false })

    ModalHelper.openModalClickHandler('photo', ev)
  }

  _unobserve = (observer, target) => {
    observer.unobserve(target)
    this.setState({ observing: false })
  }

  _intersectionObserverCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if(entry.isIntersecting) {
        this._unobserve(observer, entry.target)
      }
    })
  }

  _createIntersectionObserver = () => {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    }

    let observer = new IntersectionObserver(this._intersectionObserverCallback, options)
    let wrapper = document.querySelector(`.photo__wrapper[data-token='${this.props.photo['token']}']`)

    observer.observe(wrapper)
  }

  _url = () => {
    const baseUrl = PhotosHelper.photoCloudfrontUrl(this.props.photo['basename'], this.props.aspectGroup)

    if(this.props.lazyLoad) {
      return this.state.observing ? '#' : baseUrl
    }
    else {
      return baseUrl
    }
  }

  // called by child component when img has finished loading 
  setPhotoLoaded = () => {
    this.setState({ photoLoaded: true })
    
    if(this.props.setPhotoLoaded) {
      this.props.setPhotoLoaded() // tell the parent component that it has loaded
    }
  }

  render() {
    if(this.props.isShowing) {
      const photo = this.props.photo

      return(
        <div className='photo photo__wrapper' data-token={photo.token} onMouseOver={this._onMouseOver} onMouseLeave={this._onMouseLeave} >

          { this.props.usageType === 'admin-dreams-edit' &&
            <div className='admin__photo-status-icons-wrapper'>
              <PhotoStatusIcon
                isShowing={this.props.isHidden}
                faIconClass={'fa-regular fa-eye-slash'}
              />

              <PhotoStatusIcon
                title={'Marked for Replacement'}
                isShowing={!this.props.isHidden && this.props.isMarkedForReplacement}
                faIconClass={'fa-solid fa-hand-holding-hand'}
              />

              <PhotoStatusIcon
                title={'Marked for Deletion'}
                isShowing={!this.props.isHidden && this.props.isMarkedForDeletion}
                faIconClass={'fa-solid fa-fire'}
              />

              <PhotoStatusIcon
                title={'Photo is part of a series'}
                isShowing={photo.series_id !== null}
                faIconClass={'fa-solid fa-list-ol'}
              />

              <PhotoStatusIcon
                title={'Is B-roll'}
                isShowing={photo.is_b_roll}
                faIconClass={'fa-solid fa-film'}
              />
            </div>
          }

          <PhotoModalWrapper
            photo={photo}
            type={this.props.usageType}
            src={this._url()}
          />

          <PhotoImage
            src={this._url()}
            photo={photo}
            href={this.props.usageType === 'show' ? `/photos/${this.props.photo['token']}` : null}
            clickHandler={this.props.usageType === 'admin-dreams-edit' ? this.props.photoClickHandler : null}
            selected={this.props.selected}
            setLoaded={this.setPhotoLoaded}
          />

          <div className={`photo__admin-action-buttons-wrapper photo__admin-action-buttons-wrapper--${photo.aspect_group}`}>
            <ActionButton
              title={'Swap'}
              hoverable={false}
              faIconClass={'fa-right-left'}
              buttonType={'admin-action'}
              isShowing={this.props.swappable}
              clickHandler={this.props.swapPhotoButtonClickHandler}
            />

            <ActionButton
              title={'Open Edit modal'}
              hoverable={true}
              faIconClass={'fa-pen-to-square'}
              buttonType={'admin-action'}
              isShowing={this.props.usageType === 'admin-dreams-edit' && this.state.photoLoaded}
              clickHandler={this._openPhotoModalClickHandler}
            />
 
            <ActionButton
              title={'Hide'}
              hoverable={true}
              faIconClass={'fa-regular fa-eye-slash'}
              buttonType={'admin-action'}
              isShowing={this.props.hidePhotoButtonClickHandler && !this.props.isHidden && !this.props.swappable}
              clickHandler={this.props.hidePhotoButtonClickHandler}
            />

            <ActionButton
              title={'Show'}
              hoverable={true}
              faIconClass={'fa-regular fa-eye'}
              buttonType={'admin-action'}
              isShowing={this.props.showPhotoButtonClickHandler && this.props.isHidden && !this.props.swappable}
              clickHandler={this.props.showPhotoButtonClickHandler}
            />

            <ActionButton
              title={'Mark for replacement'}
              hoverable={true}
              faIconClass={'fa-hand-holding-hand'}
              buttonType={'admin-action'}
              isShowing={this.props.markForReplacementClickHandler && !this.props.isMarkedForReplacement}
              clickHandler={this.props.markForReplacementClickHandler}
            />

            <ActionButton
              title={'Unmark for replacement'}
              hoverable={true}
              faIconClass={'fa-handshake-simple-slash'}
              buttonType={'admin-action'}
              isShowing={this.props.unmarkForReplacementClickHandler && this.props.isMarkedForReplacement}
              clickHandler={this.props.unmarkForReplacementClickHandler}
            />

            <ActionButton
              title={'Mark for deletion'}
              hoverable={true}
              faIconClass={'fa-trash-arrow-up'}
              buttonType={'admin-action'}
              isShowing={this.props.markForDeletionClickHandler && !this.props.isMarkedForDeletion}
              clickHandler={this.props.markForDeletionClickHandler}
            />

            <ActionButton
              title={'Unmark for replacement'}
              hoverable={true}
              faIconClass={'fa-rotate-left'}
              buttonType={'admin-action'}
              isShowing={this.props.unmarkForDeletionClickHandler && this.props.isMarkedForDeletion}
              clickHandler={this.props.unmarkForDeletionClickHandler}
            />

            { this.props.usageType === 'admin-dreams-edit' && this.state.photoLoaded &&
              <a title={'Go to Photo on main site'} href={`/photos/${photo.token}`} target='_blank' className='button button--admin-action button--hoverable'>
                <i className='fa fa-solid fa-arrow-right'></i>
              </a>
            }
          </div>
        </div>
      )
    }
    else {
      return null
    }
  }
}

export default Photo
