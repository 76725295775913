import React from 'react';
import ModalWrapperContext from '../contexts/ModalWrapperContext';

class ModalTextField extends React.Component {

  _activeClass = () => {
    return this.props.defaultValue === '' ? '' : 'active';
  }

  static contextType = ModalWrapperContext;

  render() {
    const id = `photo-${this.props.token || this.context.token}-${this.props.name}`;
    const labelText = this.props.labelText || (this.props.name[0].toUpperCase() + this.props.name.slice(1));

    if(this.props.inputType === 'input') {
      return(
        <div className="row">
          <div className="input-field col s12">
            <input disabled={this.props.disabled} required={this.props.required} name={this.props.name} id={id} defaultValue={this.props.defaultValue} type='text' className='validate'></input>
            <label className={this._activeClass()} htmlFor={id}>{labelText}</label>
          </div>
        </div>
      )
    }
    else if(this.props.inputType === 'textarea') {
      return(
        <div className="row">
          <div className="input-field col s12">
            <textarea name={this.props.name} id={id} defaultValue={this.props.defaultValue} className="materialize-textarea"></textarea>
            <label className={this._activeClass()} htmlFor={id}>{labelText}</label>
          </div>
        </div>
      );
    }
  }
}

//ModalTextField.contextType = ModalWrapperContext;

export default ModalTextField;
