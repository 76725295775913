import React from 'react';

class DonationOptions extends React.Component {
  render() {
    return(
      <div className='donations'>
      
      </div>
    )
  }
}

export default DonationOptions;