import React from 'react';
import ModalCloseIcon from './ModalCloseIcon';
import ModalHelper from '../../helpers/ModalHelper'
import RequiredFieldLabel from '../forms/RequiredFieldLabel'
import LoaderActionButton from '../LoaderActionButton'
import ApiService from '../../services/ApiService'

class PrintJobPayModal extends React.Component {
  constructor(props) {
    super(props)

    this.divID = `print-job-${props.printJob.token}-pay-modal`

    this.printJob = props.printJob
    this.printJobQuote = props.printJob['print_job_quote']

    this.state = {
      isRunningApiRequest: false,
      paymentNote: this.printJobQuote['payment_note'] || ''
      
    }
  }

  componentDidMount() {
    this.modalInstance = ModalHelper.initialiseModal(this.divID, this)
  }

  closeModal = () => {
    this.modalInstance.close()
  }

  handleApiResponse = (response) => {
    if(response.success) {
      const printJob = response.data['print_job']

      const updateStateArgs = {
        apiRequestSuccess: true,
        isRunningApiRequest: false
      }

      this.setState(
        updateStateArgs,
        () => {
          this.props.setPrintJob(printJob, response.data['flash']) // update print job in parent component
          this.closeModal() // parent will hide the option for it to be reopened
        }
      )
    }
    else {
      this.setState({
        isRunningApiRequest: false,
        apiRequestError: true
      })
    }
  }

  handlePaymentNoteInputChange = (e) => {
    this.setState({ paymentNote: e.target.value })
  }

  submitForm = (event) => {
    const paymentNote = this.state.paymentNote
    // don't try to submit, and allow the HTML form validations to run
    if(paymentNote === '') {
      return
    }

    event.stopPropagation()
    event.preventDefault()

    const markPrintJobPaidCallback = () => {
      ApiService.markPrintJobPaid(this.printJob.token, paymentNote)
        .then((response) => {
          this.handleApiResponse(response)
        })
    }
    

    this.setState(
      {
        isRunningApiRequest: true,
        apiRequestError: false
      },
      markPrintJobPaidCallback
    )
  }

  render() {
    return(
      <div id={this.divID} className='modal modal--print-job-pay'>
        <div className='modal-content'>
          <ModalCloseIcon
            closeModal={this.closeModal}
            modalID={this.divID}
          />

          <h5>Mark Print Job as paid</h5>

          <div className='modal-form'>
            <form onSubmit={(e) => { e.preventDefault() }} className="col s12">
              <div className="input-field col s12">
                <RequiredFieldLabel
                  name={'Payment Note'}
                  htmlFor={`${this.divID}-form-payment-note-input`}
                  forceActive={this.state.paymentNote !== ''}
                />
                <textarea name='notes' value={this.state.paymentNote} onChange={this.handlePaymentNoteInputChange} id={`${this.divID}-form-payment-note-input`} className="modal-form__input materialize-textarea"></textarea>
              </div>

              <div className='modal-form__submit'>
                { !this.state.apiRequestError &&
                  <LoaderActionButton
                    text={'Mark as PAID'}
                    buttonType={'submit'}
                    faIconClass={'fa-hand-holding-dollar'}
                    secondaryClassName={'padding-left padding-right'}
                    isLoading={this.state.isRunningApiRequest}
                    onClick={this.submitForm}
                  />
                }

                { this.state.apiRequestError &&
                  <p>
                    Sorry! something went wrong &nbsp;
                    <a onClick={this.submitForm}>
                      try again
                    </a>
                  </p>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default PrintJobPayModal
