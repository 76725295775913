import React, { useState } from 'react'

function HeaderVideo(props) {
  const [isShowing, setIsShowing] = useState(true)
  const [iframeIsLoaded, setIframeIsLoaded] = useState(false)
  
  if(isShowing) {
    return(
      <div className='header-video'>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <div>
          <iframe
            onLoad={(_e) => { setIframeIsLoaded(true) }}
            src={`${props.videoEmbedUrl}?badge=0&title=0&sidedock=0&portrait=0&controls=0&byline=0&quality_selector=0&muted=1&player_id=0&app_id=58479&autoplay=1&loop=1&autopause=0&byline=0&keyboard=0&playsinline=1`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            title={props.title}>
          </iframe>

          { iframeIsLoaded &&
            <div className='header-video__hide-button'>
              <a className='button button--action' onClick={(_e) => { setIsShowing(false) }}>
                <span></span>
                <i className="fa-solid fa-eye-slash"></i>
              </a>
            </div>
          }
        </div>
      </div>
    )
  }
  else {
    return null
  }
}

export default HeaderVideo
