import React from 'react';

class ActionButton extends React.Component {
  render() {
    if(this.props.isShowing) { // TODO: default to showing (invert to isHidden)
      return(
        <button
          className={`button button--${this.props.buttonType || 'action'} ${this.props.text ? '' : 'button--icon'} ${this.props.hoverable ? 'button--hoverable' : ''} ${this.props.secondaryClassName ? this.props.secondaryClassName : ''}`}
          data-token={this.props.token}
          onClick={this.props.clickHandler}
          disabled={this.props.disabled}
          title={this.props.title}
        >
          { this.props.text &&
            <span>{this.props.text}</span>
          }
          { this.props.faIconClass &&
            <i className={`fa ${this.props.faIconClass.includes('fa-regular') ? '' : 'fa-solid'} ${this.props.faIconClass}`}></i>
          }
        </button>
      );
    }
    else {
      return null;
    }
  }
}

export default ActionButton;
