import React from 'react';

class ResponseStatusIcon extends React.Component {
  render() {
    if(this.props.isShowing) {
      let success = this.props.success;

      return(
        <i className={`fa-solid response-status-icon response-status-icon--${success ? 'success fa-check' : 'failure fa-xmark'}`}></i>
      );
    }
    else {
      return null;
    }
  }
}

export default ResponseStatusIcon;
