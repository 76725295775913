const _clearFlash = (componentClearFlash) => {
  // component.setState({ flash: null })
  componentClearFlash.call() // either setState, or state hook
}

var flashTimeouts = []

export default {
  // auto-hide on scroll
  initialise: (componentCurrentFlash, componentClearFlash) => {
    document.addEventListener('scroll', (_event) => {
      const currentFlash = componentCurrentFlash.call()

      if(currentFlash) {
        _clearFlash(componentClearFlash)
      }
    })
  },

  loadInitialFlash: () => {
    const flashDataHiddenField = document.getElementById('flash_data')
    const initialFlashUnparsed = flashDataHiddenField ? flashDataHiddenField.value : null
    
    return initialFlashUnparsed ? JSON.parse(initialFlashUnparsed) : null
  },

  // needs to support both functional and class-based components, so pass setter/clearer functions
  setFlash: (componentSetFlash, componentClearFlash, success, message) => {
    const flash = {
      message,
      type: success ? 'notice' : 'error'
    }

    if(flashTimeouts.length) {
      flashTimeouts.forEach((timeoutID) => {
        clearTimeout(timeoutID)
      })

      flashTimeouts = []
    }

    // componentSetFlash always takes a callback, which is the timeout that will clear it after an interval
    // show flash, then auto-hide after 3 seconds
    componentSetFlash(flash, () => {
      const timeout = window.setTimeout(() => {
        _clearFlash(componentClearFlash)
      }, 3000)

      flashTimeouts.push(timeout)
    })
  }
}
