import React, { useState, useEffect, useContext } from 'react'
import RequiredFieldLabel from './RequiredFieldLabel'
import ApiService from '../../services/ApiService'
import FlashContext from '../contexts/FlashContext'

function ApplyDiscountCodeForm(props) {
  const [isApplyingDiscountCode, setIsApplyingDiscountCode] = useState(false)

  const topLevelFlashContext = useContext(FlashContext)

  useEffect(() => {
    const discountCodeInput = document.getElementById('discount-code-code')
    const discountCodeInputLabel = document.querySelector("label[for='discount-code-code']")
  
    discountCodeInput.addEventListener("focus", (_event) => {
      discountCodeInputLabel.classList.add('active')
    });
    
    discountCodeInput.addEventListener("blur", (event) => {
      const inputValue = event.target.value

      if(inputValue === '') {
        discountCodeInputLabel.classList.remove('active')
      }
    })
  })

  const applyDiscountCode = (_event) => {
    if(isApplyingDiscountCode) {
      return
    }

    setIsApplyingDiscountCode(true)

    const code = document.getElementById('discount-code-code').value

    if(code === '') {
      setIsApplyingDiscountCode(false)
      return
    }

    ApiService.applyDiscountCode(props.orderToken, code)
      .then((response) => {
        if(response.success) {
          const responseData = response.data // order combined with flash
          const flash = responseData['flash']
          const updateSuccess = flash.type === 'notice'

          topLevelFlashContext.setFlash(updateSuccess, flash['message'])

          if(updateSuccess) {
            props.applyDiscountCodeSuccess(responseData)
          }
        }
        else {
          // only stop it on failure - in success case the enclosing component success callback will stop rendering the form
          setIsApplyingDiscountCode(false)
          topLevelFlashContext.setFlash(false, "Sorry! that didn't work.")
        }
      })
  }

  return(
    <div className='apply-discount-code-form'>
      <form onSubmit={(e) => { e.preventDefault() }}>
        <div className='input-field'>
          <input required name='code' autoComplete='off' id='discount-code-code'></input>
          <RequiredFieldLabel
            name='Code'
            htmlFor='discount-code-code'
          />
        </div>

        <button type='submit' className='button button--action' onClick={applyDiscountCode}>
          <span>Apply Code</span>
          { isApplyingDiscountCode &&
            <i className='fa fa-solid fa-spinner fa-spin'></i>
          }
          { !isApplyingDiscountCode &&
            <i className='fa fa-solid fa-plus'></i>
          }
        </button>
      </form>
    </div>
  )
}

export default ApplyDiscountCodeForm
