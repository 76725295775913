import React from 'react'

function Flash(props) {
  if(props.flash && props.flash.message && props.flash.type) {
    const message = props.flash.message
    const success = props.flash.type == 'notice'
  
    return(
      <div className='flash'>
        <div className={`flash__contents ${success ? 'notice' : 'error'}`}>
          <div className='flash__message'>{ message }</div>
          <div className='flash__icon'>
            { success &&
              <i className='fa fa-lg fa-regular fa-circle-check'></i>
            }
            { !success &&
              <i className="fa fa-lg fa-solid fa-triangle-exclamation"></i>
            }
          </div>
        </div>
      </div>
    )
  }
  else {
    return null
  }
}

export default Flash
