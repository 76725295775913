import React from 'react'

import Navbar from '../Navbar'
import Flash from '../Flash'
import StepNav from '../StepNav'

import ReviewStep from './steps/ReviewStep'
import DeliveryStep from './steps/DeliveryStep'
import PaymentStep from './steps/PaymentStep'
import ConfirmStep from './steps/ConfirmStep'

import StringHelper from '../../helpers/StringHelper'
import AddressHelper from '../../helpers/AddressHelper'
import CryptoHelper from '../../helpers/CryptoHelper'
import PageScrollHelper from '../../helpers/PageScrollHelper'

import ApiService from '../../services/ApiService'

import SocialLinksModal from '../modals/SocialLinksModal'
import Footer from '../Footer'

import FlashContext from '../contexts/FlashContext'
import FlashHelper from '../../helpers/FlashHelper'

import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import SocialLinks from '../modals/SocialLinksModal'

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    const orderData = this.props.orderData

    this.allSteps = ['details', 'delivery', 'payment', 'confirm']

    const unparsedPhotoDataElements = orderData['photos_json']

    this.orderToken = orderData['token']
    this.cryptos = CryptoHelper.cryptos()
    this.paymentIntentClientKey = orderData['payment_intent_client_key']

    const paymentMethod = orderData['payment_method']
    const cryptoIsSelected = this.cryptos.includes(paymentMethod)

    const photos = unparsedPhotoDataElements.map((photoDataElement) => JSON.parse(photoDataElement))

    const completedSteps = orderData['completed_steps'] || []

    const address = orderData['address'] || {}

    let stateToSet = {
      photos,
      isCreatingOrder: false,
      completedSteps,
      address,
      paymentMethod,
      cryptoIsSelected,
      deliveryMethod: orderData['delivery_method'],
      isPaid: orderData['is_paid'],
      isConfirmed: orderData['is_confirmed'],
      printsCost: orderData['prints_cost'],
      totalCost: orderData['total_cost'],
      shippingCost: orderData['shipping_cost'],
      cryptoPaymentDetails: orderData['crypto_payment_details'],
      customer: orderData['customer'],
      note: orderData['note']
    }

    if(orderData['discount_code_metadata']) {
      stateToSet['discountCodeMetadata'] = orderData['discount_code_metadata']
    }

     const currentStep = (() => {
      if(orderData['is_confirmed'] && !orderData['is_paid']) {
        return 'payment'
      }
      
      if(completedSteps.length === 0) {
        return 'details'
      }
      
      if(orderData['review_order']) {
        // the only possible change that can be made here is to address
        if(orderData['is_paid']) {
          // should only be navigating here as a paid order if delivery method is 'delivery'
          return orderData['delivery_method'] === 'delivery' ? 'delivery' : 'details'
        }
        else {
          return 'details'
        }
      }

      const navegableSteps = this.loadNavegableStepsFrom(stateToSet)

      // either the first incomplete step, or the last navegable one, completed or not
      return navegableSteps.filter((step) => !completedSteps.includes(step))[0] || navegableSteps[navegableSteps.length - 1]
    })()

    stateToSet['currentStep'] = currentStep

    if(orderData['is_paid'] && orderData['paid_at']) {
      stateToSet['paidAt'] = orderData['paid_at']
    }

    // special case for when card payment has been made and we skip straight to confirm step and want to show basic payment information
    if(orderData['is_paid'] && orderData['stripe_payment_details']) {
      stateToSet['stripePaymentDetails'] = orderData['stripe_payment_details']
    }

    this.state = stateToSet

    // if coming from order cart on homepage
    if(!orderData['is_completed']) {
      LocalStorageHelper.storeOrderToken(orderData['token']) // clear when checkout is complete, along with cart items
    }
  }

  componentDidMount() {
    FlashHelper.initialise(this.currentFlashState, this.clearFlashState) // initialise with empty currentFlash
  }

  currentFlashState = () => {
    return this.state ? this.state.flash : {}
  }

  // provided to flash helper, for setting local component state
  setFlashState = (flash, callback) => {
    this.setState({ flash }, callback)
  }

  clearFlashState = () => {
    this.setState({ flash: null })
  }

  setFlash = (success, message) => {
    FlashHelper.setFlash(this.setFlashState, this.clearFlashState, success, message)
  }

  photoFromToken = (photoToken) => {
    return this.state.photos.find((photo) => photo.token === photoToken)
  }

  photoIndexFromToken = (photoToken) => {
    const photo = this.photoFromToken(photoToken)

    return this.state.photos.indexOf(photo)
  }

  selectPrintSize = (photoToken, size) => {
    let photos = this.state.photos

    let currentPhoto = photos.find((photo) => photo.token === photoToken)
    
    const index = photos.indexOf(currentPhoto)

    let printSizing = currentPhoto.print_sizing
    
    printSizing['size'] = size

    currentPhoto.print_sizing = printSizing

    photos.splice(index, 1, currentPhoto)

    this.setState({ photos }, () => {
      this.persistPrintSizeSelection(photoToken, size)
    })
  }

  // actual setting is done in child component, this is just to propagate the state on the react tree
  applyDiscountCode = (order) => {
    const discountCodeMetadata = order.discount_code_metadata
    const totalCost = order.total_cost // totalCost has updated after discount code was applied

    this.setState({ discountCodeMetadata, totalCost })
  }

  // same idea as applyDiscountCode - setting is done in child component (PaymentStep), this function is a callback to propagate state to this component (the parent)
  setCryptoPriceData = (cryptoPriceData) => {
    this.setState({ cryptoPaymentDetails: cryptoPriceData })
  }

  selectDeliveryMethod = (deliveryMethod) => {
    let stateToSet = this.state

    stateToSet.deliveryMethod = deliveryMethod

    let completedSteps = this.state.completedSteps
    const deliveryStepAlreadyCompleted = completedSteps.includes('delivery')

    // confirm step
    if(deliveryMethod === 'pickup' && !deliveryStepAlreadyCompleted) {
      completedSteps.push('delivery')
    }
    else if(deliveryMethod === 'delivery') {
      const addressIsValid = AddressHelper.addressIsValid(this.state.address)

      if(deliveryStepAlreadyCompleted && !addressIsValid) {
        completedSteps = completedSteps.filter((step) => step !== 'delivery')
      }
      else if(!deliveryStepAlreadyCompleted && addressIsValid) {
        completedSteps.push('delivery')
      }
    }

    stateToSet['completedSteps'] = completedSteps

    this.setState(stateToSet, () => {
      this.updateOrder({ delivery_method: deliveryMethod, completed_steps: completedSteps })
    })
  }

  selectPaymentMethod = (paymentMethod) => {
    let completedSteps = this.state.completedSteps
    const paymentStepAlreadyCompleted = completedSteps.includes('payment')
    const cryptoIsSelected = this.cryptos.includes(paymentMethod)

    if((cryptoIsSelected || paymentMethod === 'cash') && !paymentStepAlreadyCompleted) {
      completedSteps.push('payment')
    }
    else if(paymentMethod === 'card' && paymentStepAlreadyCompleted) {
      // uncomplete step (crypto or cash was previously selected)
      // step can only ever be completed with card by submitting form, once card payment is made payment method is locked in
      completedSteps = completedSteps.filter((step) => step !== 'payment')
    }

    this.setState({ paymentMethod, completedSteps, cryptoIsSelected }, () => {
      this.updateOrder({ payment_method: paymentMethod, completed_steps: completedSteps })
    })
  }

  persistPrintSizeSelection = (photoToken, size) => {
    const requestParams = {
      photo_token: photoToken,
      print_sizing: { size }
    }

    ApiService.updateOrder(this.orderToken, requestParams)
      .then((response) => {
        this.updateLocalStateFromUpdateOrderResponse(response)
      })
  }

  removeFromCart = (photoToken) => {
    const photos = this.state.photos
    const removingLastPhoto = photos.length === 1
    const confirmMessage = removingLastPhoto ? 'Remove Photo and cancel Order?' : 'Remove this photo from the Cart?'

    if(window.confirm(confirmMessage)) {
      const photoToRemove = photos.find((photo) => photo.token === photoToken)
  
      const index = photos.indexOf(photoToRemove)

      photos.splice(index, 1)

      if(removingLastPhoto) {
        window.location = `/orders/${this.orderToken}/cancel`
      }
      else {
        this.setState({ photos }, () => {
          this.persistRemoveFromCart(photoToken)
        }) 
      }
    }
  }

  persistRemoveFromCart = (photoToken) => {
    ApiService.removePrintFromOrder(this.orderToken, photoToken)
      .then((response) => {
        LocalStorageHelper.removeFromCart(photoToken)
        this.updateLocalStateFromUpdateOrderResponse(response)
      })
  }

  onStepClick = (step) => {
    if(this.navegableSteps().includes(step)) {
      this.setState({ currentStep: step }, () => {
        PageScrollHelper.scrollToTop()
      })
    }
  }

  // like confirm step, but called from the payment step
  confirmOrder = () => {
    this.setState({ isCompletingOrder: true }, () => {
      ApiService.confirmOrder(this.orderToken)
        .then((response) => {
          const success = response.success

          let stateToSet = { isCompletingOrder: false }

          if(success) {
            const responseData = response.data

            LocalStorageHelper.clearCurrentCart()

            stateToSet['completedSteps'] = this.allSteps
            stateToSet['currentStep'] = 'confirm'
            // TODO: potentially load other order info here
            stateToSet['isPaid'] = responseData['is_paid']
            stateToSet['isConfirmed'] = responseData['is_confirmed']
          }
          else {
            stateToSet['hasconfirmOrderError'] = true
          }

          this.setState(stateToSet, () => {
            window.setTimeout(() => {
              this.setState({ hasconfirmOrderError: false })
            }, 4000)
          })
        })
    })
  }

  completeStep = () => {
    let currentStep = this.state.currentStep
    let completedSteps = this.state.completedSteps

    const stepAlreadyCompleted = completedSteps.includes(currentStep)

    // some steps require manual completion (reivew), some are completed automatically (delivery, when setting valid address or choosing pickup)
    if(!stepAlreadyCompleted) {
      completedSteps.push(currentStep)
    }

    currentStep = this.nextStepFor(currentStep)

    this.setState({ currentStep, completedSteps }, () => {
      PageScrollHelper.scrollToTop()

      // sync with backend too if it's not already completed
      if(!stepAlreadyCompleted) {
        this.updateOrder({ completed_steps: completedSteps })
      }
    })
  }

  updateLocalStateFromUpdateOrderResponse = (apiResponse) => {
    if(apiResponse.success && Object.keys(apiResponse.data).length > 0) {
      const responseData = apiResponse.data
      const responseDataKeys = Object.keys(responseData)

      let stateToSet = {}

      const keyMappings = {
        prints_cost: 'printsCost',
        shipping_cost: 'shippingCost',
        total_cost: 'totalCost',
        paid: 'isPaid',
        delivery_method: 'deliveryMethod',
        payment_method: 'paymentMethod',
        discount_code_metadata: 'discountCodeMetadata'
      }

      Object.keys(keyMappings).forEach((apiResponseKey) => {
        if(responseDataKeys.includes(apiResponseKey)) {
          stateToSet[keyMappings[apiResponseKey]] = responseData[apiResponseKey]
        }
      })

      this.setState(stateToSet)
    }
    else {
      this.setFlash(false, 'Sorry! something went wrong while trying to update')
    }
  }

  // update completed steps, delivery/payment method in backend
  updateOrder = (updateOrderParams) => {
    ApiService.updateOrder(this.orderToken, {
      order: updateOrderParams
    })
    .then((response) => {
      this.updateLocalStateFromUpdateOrderResponse(response)
    })
  }

  updateCustomerSuccess = (responseData) => {
    let stateToSet = this.state
    let completedSteps = stateToSet.completedSteps

    const detailsStepCompleted = completedSteps.includes('details')

    if(!detailsStepCompleted) {
      completedSteps.push('details')
    }

    stateToSet['note'] = responseData['note']
    stateToSet['customer'] = responseData['customer']

    this.setState(stateToSet, () => {
      if(!detailsStepCompleted) {
        this.updateOrder({ completed_steps: completedSteps })
      }
    })
  }

  updateAddressSuccess = (responseData) => {
    let stateToSet = this.state
    let completedSteps = stateToSet.completedSteps

    const deliveryStepCompleted = completedSteps.includes('delivery')

    if(!deliveryStepCompleted) {
      completedSteps.push('delivery')
    }

    stateToSet['address'] = responseData['address']
    stateToSet['deliveryMethod'] = responseData['delivery_method']
    stateToSet['shippingCost'] = responseData['shipping_cost']
    stateToSet['totalCost'] = responseData['total_cost']
    stateToSet['completedSteps'] = completedSteps

    this.setState(stateToSet, () => {
      if(!deliveryStepCompleted) {
        this.updateOrder({ completed_steps: completedSteps })
      }
    })
  }

  // roll uncompleting step into this
  removeAddress = () => {
    let stateToSet = { address: {}, deliveryMethod: null, isConfirmed: false }
    // uncompletes both delivery and confirm step (in back-end order state transitions back to NEW)
    stateToSet['completedSteps'] = this.state.completedSteps.filter((step) => !['delivery', 'confirm'].includes(step))

    this.setState(stateToSet, () => {
      this.updateOrder({ address: null })
    })
  }

  nextStepFor = (step) => {
    const nextStepMappings = {
      details: 'delivery',
      delivery: 'payment',
      payment: 'confirm'
    }

    return nextStepMappings[step]
  }

  currentStepHeader = () => {
    let headerMappings = (() => {
      if(this.state.isPaid) {
        return {
          details: `Selected ${this.pluraliseCollectionName('Print')}`,
          confirm: 'Order complete'
        }
      }
      else {
        return {
          details: 'Details',
          confirm: 'Confirm'
        }
      }
    })()

    headerMappings['payment'] = 'Payment'
    headerMappings['delivery'] = `Getting my ${this.pluraliseCollectionName('Print')}`

    return headerMappings[this.state.currentStep]
  }

  // term === photo / print
  pluraliseCollectionName = (collectionName) => {
    return StringHelper.pluraliseCollectionName(this.state.photos, collectionName)
  }

  loadNavegableStepsFrom = (data) => {
    const completedSteps = data.completedSteps
    
    if(completedSteps.length === 0) {
      return []
    }

    const hasValidAddress = AddressHelper.addressIsValid(data.address)
    if(data.isPaid || data.isConfirmed) {
      if(data.deliveryMethod === 'pickup' || (data.deliveryMethod === 'delivery' && hasValidAddress)) {
        return this.allSteps
      }
      else {
        return ['details', 'delivery', 'payment']
      }
    }

    let navegable = ['details']

    if(completedSteps.includes('details') || data.deliveryMethod) {
      navegable.push('delivery')
    }
    if(completedSteps.includes('delivery') || completedSteps.includes('payment') || hasValidAddress) {
      navegable.push('payment')
    }
    if(completedSteps.includes('details') && data.deliveryMethod && data.paymentMethod) {
      // reconfirm valid delivery step
      if(data.deliveryMethod === 'pickup' || (data.deliveryMethod === 'delivery' && hasValidAddress)) {
        navegable.push('confirm')
      }
    }

    return navegable
  }

  // need to allow this to be called separate from state because it's needed once when initial state is being constructed
  navegableSteps = () => {
    return this.loadNavegableStepsFrom(this.state)
  }

  render() {
    const navegableSteps = this.navegableSteps()
    return(
      <FlashContext.Provider value={{ setFlash: this.setFlash }} >
        <Navbar title='keenedreams'/>
        <Flash flash={this.state.flash} />

        <div className='order-checkout'>
          <StepNav
            allSteps={this.allSteps}
            currentStep={this.state.currentStep}
            completedSteps={this.state.completedSteps}
            navegableSteps={navegableSteps}
            onStepClick={this.onStepClick}
          />

          <div className='order-checkout__current-step-header'>
            <h4>
              { this.currentStepHeader() }
            </h4>
          </div>

          { this.state.currentStep === 'details' &&
            <ReviewStep
              photos={this.state.photos}
              orderToken={this.orderToken}
              customer={this.state.customer}
              note={this.state.note}
              discountCodeMetadata={this.state.discountCodeMetadata}
              pluraliseCollectionName={this.pluraliseCollectionName}
              deliveryMethod={this.state.deliveryMethod}
              isPaid={this.state.isPaid}
              optionsDisabled={this.state.isPaid || (this.state.isConfirmed && this.state.cryptoIsSelected)}
              actionDisabled={!this.state.customer}
              printsCost={this.state.printsCost}
              totalCost={this.state.totalCost}
              shippingCost={this.state.shippingCost}
              selectPrintSize={this.selectPrintSize}
              removeFromCart={this.removeFromCart}
              completeStep={this.completeStep}
              updateCustomerSuccess={this.updateCustomerSuccess}
            />
          }

          { this.state.currentStep === 'delivery' &&
            <DeliveryStep
              customer={this.state.customer}
              isConfirmed={this.state.isConfirmed}
              isPaid={this.state.isPaid}
              pluraliseCollectionName={this.pluraliseCollectionName}
              orderToken={this.orderToken}
              deliveryMethod={this.state.deliveryMethod}
              address={this.state.address}
              updateAddressSuccess={this.updateAddressSuccess}
              removeAddress={this.removeAddress}
              selectDeliveryMethod={this.selectDeliveryMethod}
              completeStep={this.completeStep}
            />
          }

          { this.state.currentStep === 'payment' &&
            <PaymentStep
              pluraliseCollectionName={this.pluraliseCollectionName}
              applyDiscountCode={this.applyDiscountCode}
              setCryptoPriceData={this.setCryptoPriceData}
              discountCodeMetadata={this.state.discountCodeMetadata}
              orderToken={this.orderToken}
              paymentMethod={this.state.paymentMethod}
              deliveryMethod={this.state.deliveryMethod}
              paymentIntentClientKey={this.paymentIntentClientKey}
              selectPaymentMethod={this.selectPaymentMethod}
              isConfirmed={this.state.isConfirmed}
              isPaid={this.state.isPaid}
              paidAt={this.state.paidAt}
              printsCost={this.state.printsCost}
              totalCost={this.state.totalCost}
              cryptoPriceData={this.state.cryptoPaymentDetails}
              shippingCost={this.state.shippingCost}
              stripePaymentDetails={this.state.stripePaymentDetails}
              navegableSteps={navegableSteps}
              completeStep={this.completeStep}
            />
          }

          { this.state.currentStep === 'confirm' &&
            <ConfirmStep
              pluraliseCollectionName={this.pluraliseCollectionName}
              orderToken={this.orderToken}
              paymentMethod={this.state.paymentMethod}
              deliveryMethod={this.state.deliveryMethod}
              isPaid={this.state.isPaid}
              isConfirmed={this.state.isConfirmed}
              confirmOrder={this.confirmOrder}
              isCompletingOrder={this.state.isCompletingOrder}
              hasconfirmOrderError={this.state.hasconfirmOrderError}
            />
          }
        </div>

        <SocialLinksModal />

        <Footer currentPage={'order-checkout'} />
      </FlashContext.Provider>
    )
  }
}

export default Checkout;
