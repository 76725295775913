import React from 'react';

class SinglePageNav extends React.Component {
  render() {
    return (
      <a
        className={`single-page-nav__link ${this.props.selected ? 'single-page-nav__link--selected' : ''}`}
        onClick={this.props.onClickHandler}
        data-section={this.props.selectionKey}
      >
        {this.props.text}
      </a>
    )
  }
}

export default SinglePageNav;
