import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'

import PrintsListModal from '../modals/PrintsListModal'
import PhotoImage from '../PhotoImage'
import ShowPhotoOptions from '../ShowPhotoOptions'
import PhotoDetails from '../PhotoDetails'
import PhotosHelper from '../../helpers/PhotosHelper'

import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import LocalStorageContext from '../contexts/LocalStorageContext'

class Show extends React.Component {

  static contextType = LocalStorageContext;

  constructor(props) {
    super(props)

    this.photo = props.photo
    this.photoToken = props.photo.token

    this.state = {
      photoLoaded: false,
      photoData: {},
      currentCartPhotos: [],
      currentCartPhotoTokens: [],
      sectionsVisible: this.defaultSectionsVisible()
    }
  }

  componentDidMount() {
    PhotosHelper.loadPhotos(this)
  }

  defaultSectionsVisible = () => {
    // see ModalHelper#_defaultSectionsVisible

    // legacy

    // return {
    //   createOrderLink: true,
    //   printsListFormOptions: false,
    //   printsListForm: false // TODO: add logic for previouslyOrdered in here
    // }

    return {}
  }

  setCurrentCartPhotosFromTokens = (currentCartPhotoTokens) => {
    PhotosHelper.setCurrentCartPhotosFromTokens(this, currentCartPhotoTokens)
  }

  setPhotoLoaded = () => {
    this.setState({ photoLoaded: true })
  }

  render() {
    const photo = this.photo
    const url = photo.url || PhotosHelper.photoCloudfrontUrl(photo.basename, photo.aspect_group)

    return(
      <LocalStorageContext.Provider
        value={{
          currentOrderToken: LocalStorageHelper.currentOrderToken(),
          currentCartPhotos: this.state.currentCartPhotos,
          currentCartPhotoTokens: this.state.currentCartPhotoTokens,
          setCurrentCartPhotosFromTokens: this.setCurrentCartPhotosFromTokens,
        }}
      >
        <React.Fragment>
          <Navbar title='keenedreams'/>

          <PrintsListModal
            currentCartPhotos={this.state.currentCartPhotos}
            currentCartPhotoTokens={this.state.currentCartPhotoTokens}
            setCurrentCartPhotosFromTokens={this.setCurrentCartPhotosFromTokens}
          />
          
          <div className='photo-show'>
            <div className='photo-show__section'>
              <div className={'cart-photos__photo cart-photos__photo--with-desktop-border'}>
                <div id={`cart-photo-${photo.token}-wrapper`}>
                  <PhotoImage
                    src={url}
                    photo={photo}
                    hoverable={false}
                    setLoaded={this.setPhotoLoaded}
                  />

                  <ShowPhotoOptions
                    photo={photo}
                    currentCartPhotoTokens={this.state.currentCartPhotoTokens}
                    sectionsVisible={this.state.sectionsVisible}
                    LocalStorageIsAvailable={this.LocalStorageIsAvailable}
                  />

                  { !photo.is_b_roll &&
                    <PhotoDetails photo={photo} />
                  }
                </div>
              </div>
            </div>
          </div>
    
          <Footer currentPage={'photo-show'} />
        </React.Fragment>
      </LocalStorageContext.Provider>
   ) 
  }
}

export default Show
