import React from 'react'
import AddressHelper from '../../helpers/AddressHelper'

class AddressPreview extends React.Component {
  constructor(props) {
    super(props)
    this.fields = AddressHelper.fields()
    this.countriesData = JSON.parse(document.getElementById('countries_data').value)
    this.state = { countriesData: this.countriesData }
  }

  fullCountryName = () => {
    return this.countriesData.find((countryData) => countryData.code === this.props.address.country).name
  }

  render() {
    const address = this.props.address

    return(
      <table className='address-preview'>
        { !this.props.hideHeader &&
          <thead>
            <tr>
              <td className='address-preview__header'>
                <h5>Selected Address</h5>
              </td>
              <td>
                { this.props.renderUpdateLink && this.props.onUpdateLinkClick &&
                  <a onClick={this.props.onUpdateLinkClick} className='orange-hover-transition'>
                    Update
                  </a>
                }
                { this.props.renderRemoveLink && this.props.onRemoveLinkClick &&
                  <a onClick={this.props.onRemoveLinkClick} className='orange-hover-transition'>
                    Remove
                  </a>
                }
              </td>
            </tr>
          </thead>
        }
        <tbody>
          {
            this.fields.map((addressFieldKey) => {
              const addressFieldValue = address[addressFieldKey]
      
              if(addressFieldValue && addressFieldValue.length) {
                return(
                  <tr className='address-preview__field' key={`address_preview_${addressFieldKey}`}>
                    <td>
                      { `${AddressHelper.fieldKeyToName(addressFieldKey)}` }
                    </td>
                    <td className='address-field-value'>
                      { addressFieldKey === 'country' && !this.props.rawCountryCode ? this.fullCountryName() : addressFieldValue }
                    </td>
                  </tr>
                )
              }
              else {
                return null
              }
            })
          }
        </tbody>
      </table>
    )
  }
}

export default AddressPreview
