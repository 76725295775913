import React from 'react'

function OrdersTable(props) {
  return(
    <table className='affiliate-orders-table'>
      <thead>
        <tr>
          <td>Status</td>
          <td>Affiliate Payout</td>
          { props.arePayoutReceivedOrders &&
            <td>Received</td>
          }
          { props.areCancelledOrders &&
            <td>Cancelled</td>
          }
          <td></td>
        </tr>
      </thead>

      <tbody>
        {
          props.orders.map((order) => {
            return(
              <tr key={`affiliate-order-${order.token}`}>
                <td>{order.status}</td>
                <td>{order.affiliate_payout_amount}</td>
                { props.arePayoutReceivedOrders &&
                  <td>{order.affiliate_payout_metadata['marked_as_received_at']}</td>
                }
                { props.areCancelledOrders &&
                  <td>{order.cancelled_at}</td>
                }
                <td>
                  <a href={`/affiliate/order/${order.token}`} className='button button--action'>
                    <span>View</span>
                    <i className='fa fa-solid fa-eye'></i>
                  </a>
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}

export default OrdersTable
