import React, { useState } from 'react'
import Navbar from '../Navbar'
import Flash from '../Flash'
import FlashHelper from '../../helpers/FlashHelper'
import SectionSeparator from '../SectionSeparator'
import OrdersTable from './OrdersTable'
import TotalPayout from './TotalPayout'
import Footer from './Footer'

import ActionButton from '../ActionButton'
import LoaderActionButton from '../LoaderActionButton'
import ApiService from '../../services/ApiService'
import BankAccountDetailsFormModal from './modals/BankAccountDetailsFormModal'
import ModalHelper from '../../helpers/ModalHelper'

function Home() {
  const ordersData = JSON.parse(document.getElementById('affiliate_orders_data').value)
  const hasOrders = Object.keys(ordersData).length > 0

  const affiliate = JSON.parse(document.getElementById('affiliate').value)
  const affiliateCode = affiliate.affiliate_code
  const businessName = affiliate.business_name

  const [bankAccountDetails, setBankAccountDetails] = useState(affiliate.bank_account_details)
  const [currentFlash, setCurrentFlash] = useState(FlashHelper.loadInitialFlash())

  const setFlash = (flash) => {
    setCurrentFlash(flash)
    window.setTimeout(() => {
      setCurrentFlash(null)
    }, 3000)
  }

  const bankAccountDetailsPersisted = ![null, undefined].includes(bankAccountDetails['token'])
  const bankAccountDetailsFieldNameMappings = bankAccountDetails.field_name_mappings[bankAccountDetails['account_type']]
  
  const loadOrders = (orders_type) => {
    return (ordersData[orders_type] || {}).orders || []
  }

  // only used for order_types that definitely exist
  const loadTotalPayout = (orders_type) => {
    return ordersData[orders_type].payout_sum
  }

  const awaitingPayoutOrders = loadOrders('awaiting_payout')
  const upcomingPayoutOrders = loadOrders('upcoming_payout')
  const receivedPayoutOrders = loadOrders('received_payout')
  const cancelledOrders      = loadOrders('cancelled')

  const handleBankAccountDetailsFormSubmission = (bankAccountDetails, flashMessage) => {
    setFlash({ type: 'notice', message: flashMessage })

    setBankAccountDetails(bankAccountDetails)
  }

  const deleteButtonClickHandler = (token) => {
    ApiService.destroyBankAccountDetails(token)
      .then((response) => {
        const responseData = response.data

        console.log('delete!, resonseData: ', responseData)

        if(responseData.success) {
          setBankAccountDetails(responseData.bank_account_details)

          setFlash({ type: 'notice', message: responseData.flash })
        }
        else {
          setFlash({ type: 'error', message: responseData.flash })
        }
      })
  }

  return(
    <React.Fragment>
      <Navbar title='AFFILIATE HOME'/>
      <Flash flash={currentFlash} />

      <h4 className='border-bottom-header'>{businessName}</h4>

      <div className='affiliate-home'>
        <div className='affiliate-home__section'>
          <div className='container'>
            <p>{`Hi ${affiliate.name}!`}</p>
            
            <p className='justify-text'>
              this is your affiliate space. If somebody scans the keenedreams QR code in your business and makes a purchase, this order will show up here on your affiliate homepage.
            </p>
            <p className='justify-text'>
              {`For every order that is created with this QR code in your space (and completed), you'll be owed ${affiliateCode.payout_percentage}% of the total order cost (excluding shipping)`}
            </p>
            <p className='justify-text'>
              Affiliate payouts are processed as soon as the order is shipped to the customer. When this happens you'll receive an email letting you know that you've got an upcoming payout.
            </p>
            <p className='justify-text'>
              Orders will show up here on your homepage before they're ready to be paid out (i.e. when they're not completed by the customer, or have not yet been sent for printing) - so you'll be able to keep track of all orders that were created by using your affiliate link or scanning your affiliate QR code in your space.
            </p>

            <p>
              Your affiliate link (the URL that your keenedreams affiliate QR code points to) is:
              &nbsp;
              <a href={`/?affiliate_code=${affiliateCode.code}`}>{affiliate.qr_code_url_string}</a>
            </p>

            <p>{`Your link has been visited (or your affiliate code scanned) ${affiliateCode.scans_count} times in the last 30 days.`}</p>

            <p>
              If you have any question you can always
              &nbsp;
              <a href={'mailto:caleb@keenedreams.io'}>send me an email</a>
              &nbsp;
              (caleb@keenedreams.io)
            </p>
          </div>
        </div>

        <div className='affiliate-home__section'>
          <SectionSeparator
            version='small'
            text='Bank Account Details'
          />

          <BankAccountDetailsFormModal
            bankAccountDetails={bankAccountDetails}
            modalID={'bank-account-details-form-modal'}
            createOrUpdateCallback={handleBankAccountDetailsFormSubmission}
          />

          { !bankAccountDetailsPersisted &&
            <div className='text-center'>
              <p>Add your bank account details to be able to receive affiliate payouts when a print is sold using your code.</p>

              <ActionButton
                isShowing={true}
                text={'Add Details'}
                faIconClass={'fa-plus'}
                secondaryClassName={'button--fullsize-round margin-top'}
                clickHandler={() => { ModalHelper.openModalClickHandler('bank-account-details-form-modal') }}
              />
            </div>
          }
          
          { bankAccountDetailsPersisted &&
            <div className='affiliate-home__section flex-center'>
              <div className='affiliate-home__bank-acount-details'>

                <table className='bank-account-details-table'>
                  <tbody>
                    <tr className='links'>
                      <td>
                        <ActionButton
                          isShowing={true}
                          text={'Edit'}
                          faIconClass={'fa-pen-to-square'}
                          clickHandler={() => { ModalHelper.openModalClickHandler('bank-account-details-form-modal') }}
                        />
                        <LoaderActionButton
                          text={'Remove'}
                          onClick={() => { deleteButtonClickHandler(bankAccountDetails.token) }}
                          faIconClass={'fa-trash'}
                          confirmationText={'Remove your bank account details?'}
                        />
                      </td>
                      <td>
                      </td>
                    </tr>

                    <tr>
                      <td>Account Type:</td>
                      <td>{bankAccountDetails.account_type}</td>
                    </tr>
                    <tr>
                      <td>Full Name</td>
                      <td>{bankAccountDetails.full_name}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{bankAccountDetails.email}</td>
                    </tr>
                    <tr>
                      <td>{bankAccountDetailsFieldNameMappings['field_one']['display_name']}</td>
                      <td>{bankAccountDetails.field_one}</td>
                    </tr>
                    <tr>
                      <td>{bankAccountDetailsFieldNameMappings['field_two']['display_name']}</td>
                      <td>{bankAccountDetails.field_two}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>

        {/* <div className='affiliate-code__section'>
          <div className='affiliate-code__created'>
            <div className='mini-header'>
              <p>Your affiliate code</p>
            </div>

            <h4>{affiliateCode.code}</h4>
             
            <a href={`/affiliate_codes/${affiliateCode.id}/edit`} className='button button--action button--full-width'>
              <span className='update-affiliate-code-link'>
              </span>
              <i className='fa fa-regular fa-pen-to-square'></i>
            </a>
          </div>
        </div> */}

        { !hasOrders && 
          <div className='affiliate-home__section'>
             <SectionSeparator
              version='small'
              text='Affiliate Orders'
            />

            <div className='container'>
               <div className='centered-flex-container-row'>
                <div className='centered-flex-container-column'>
                  <p>No Orders have been created using your affiliate code yet! once they start coming in, you can keep track of them here.</p>
                </div>
              </div>
            </div>
          </div>
        }

        <div className='affiliate-home__section' style={{ display: hasOrders ? 'block' : 'none'}}>
          { hasOrders &&
            <SectionSeparator
              version='small'
              text='Awaiting Payout Orders'
            />
          }

          { awaitingPayoutOrders.length > 0 &&
            <div className='container'>
               <div className='centered-flex-container-row'>
                <div className='centered-flex-container-column'>
                  <p className='orders-table-description-text'>Orders that customers have received, meaning you're owed an affiliate payout.</p>
                </div>
              </div>

              <OrdersTable orders={awaitingPayoutOrders} />

              <TotalPayout
                isShowing={awaitingPayoutOrders.length > 1}
                ordersType={'awaiting_payout'}
                total={loadTotalPayout('awaiting_payout')}
              />
            </div>
          }


          { awaitingPayoutOrders.length === 0 &&
            <div className='container'>
              <div className='centered-flex-container-row'>
                <div className='centered-flex-container-column'>
                  <p>No outstanding affiliate payment orders at the moment.</p>
                  <p>You'll get notified by email when an affiliate order is completed and added to this list.</p>
                </div>
              </div>
            </div>
          }
        </div>

        { upcomingPayoutOrders.length > 0 &&
          <div className='affiliate-home__section'>
            <SectionSeparator
              version='small'
              text='Upcoming Payout Orders'
            />

            <div className='container'>
              <div className='centered-flex-container-row'>
                <div className='centered-flex-container-column'>
                  <p className='orders-table-description-text'>Orders that are using your affiliate code, but have not yet been completed.</p>
                </div>
              </div>

              <OrdersTable orders={upcomingPayoutOrders} />

              <TotalPayout
                isShowing={upcomingPayoutOrders.length > 1}
                ordersType={'upcoming_payout'}
                total={loadTotalPayout('upcoming_payout')} />
            </div>
          </div>
        }

        { receivedPayoutOrders.length > 0 &&
          <div className='affiliate-home__section'>
            <SectionSeparator
              version='small'
              text='Received Payout Orders'
            />

            <div className='container'>
              <div className='centered-flex-container-row'>
                <div className='centered-flex-container-column'>
                  <p className='orders-table-description-text'>Orders for which you've previously received affiliate payouts.</p>
                </div>
              </div>

              <OrdersTable
                orders={receivedPayoutOrders}
                arePayoutReceivedOrders={true}
              />

              <TotalPayout
                isShowing={receivedPayoutOrders.length > 1}
                ordersType={'received_payout'}
                total={loadTotalPayout('received_payout')}
              />
            </div>
          </div>
        }

        { cancelledOrders.length > 0 &&
          <div className='affiliate-home__section'>
            <SectionSeparator
              version='small'
              text='Cancelled Orders'
            />

            <div className='container'>
              <div className='centered-flex-container-row'>
                <div className='centered-flex-container-column'>
                  <p className='orders-table-description-text'>Orders using your affiliate code that were cancelled by the Customer before being completed.</p>
                </div>
              </div>

              <OrdersTable
                orders={cancelledOrders}
                areCancelledOrders={true}
              />
            </div>
          </div>
        }

      </div>
      <Footer />
    </React.Fragment>
  )
}

export default Home
