import React from 'react';
import ModalCloseIcon from './ModalCloseIcon';
import ModalHelper from '../../helpers/ModalHelper'
import LoaderActionButton from '../LoaderActionButton'
import RequiredFieldLabel from '../forms/RequiredFieldLabel'
import ApiService from '../../services/ApiService'

// create or update
class PrintJobQuoteModal extends React.Component {
  constructor(props) {
    super(props)

    this.divID = `print-job-${props.printJob.token}-production-quote-modal`

    this.printJob = props.printJob
    this.printJobQuote = props.printJob['print_job_quote'] || {}

    this.isPersisted = !['', null, undefined].includes(this.printJobQuote['token'])

    this.state = {
      isRunningApiRequest: false,
      apiRequestError: false,
      inputVals: {
        printing_cost: this.printJobQuote['printing_cost'] || '',
        shipping_cost: this.printJobQuote['shipping_cost'] || '',
        misc_cost: this.printJobQuote['misc_cost'] || '',
        notes: this.printJobQuote['notes'] || ''
      }
    }
  }

  componentDidMount() {
    this.modalInstance = ModalHelper.initialiseModal(this.divID, this)
  }

  closeModal = () => {
    this.modalInstance.close()
  }

  handleApiResponse = (response) => {
    if(response.success) {
      const printJob = response.data['print_job']

      const updateStateArgs = {
        apiRequestSuccess: true,
        isRunningApiRequest: false
      }

      this.setState(
        updateStateArgs,
        () => {
          this.props.setPrintJob(printJob, response.data['flash']) // update print job in parent component
          this.closeModal() // parent will hide the option for it to be reopened
        }
      )
    }
    else {
      this.setState({
        isRunningApiRequest: false,
        apiRequestError: true
      })
    }
  }

  handleInputValueChange = (e) => {
    const updatedInputVals = Object.assign(this.state.inputVals, { [e.target.name]: e.target.value })

    this.setState({ inputVals: updatedInputVals })
  }

  submitForm = (event) => {
    const requestData = {
      print_job_token: this.printJob.token,
      print_job_quote: this.state.inputVals
    }

    // don't try to submit, and allow the HTML form validations to run
    if(['', null].includes(requestData['print_job_quote'].printing_cost)) {
      return
    }

    event.stopPropagation()
    event.preventDefault()

    const runApiRequestCallback = (() => {
      // update
      if(this.isPersisted) {
        return(
          () => {
            ApiService.updatePrintJobQuote(this.printJob['print_job_quote'].token, requestData)
              .then((response) => {
                this.handleApiResponse(response)
              })
          }
        )
        
      }
      // create
      else {
        return(
          () => {
            ApiService.createPrintJobQuote(requestData)
              .then((response) => {
                this.handleApiResponse(response)
              })
          }
        )
      }
    })()

    this.setState(
      {
        isRunningApiRequest: true,
        apiRequestError: false
      },
      runApiRequestCallback
    )
  }

  render() {
    return(
      <div id={this.divID} className='modal modal--print-job-quote'>
        <div className='modal-content'>
          <ModalCloseIcon
            closeModal={this.closeModal}
            modalID={this.divID}
          />

          <h5>{`${this.isPersisted ? 'Update' : 'Create' } Print Job Quote`}</h5>
          <div className='sub-header-info-text'>
            All costs are in Euro (€)
          </div>

          <div className='modal-form'>
            <form onSubmit={(e) => { e.preventDefault() }} className="col s12">
              <div className="input-field col s12">
                <RequiredFieldLabel
                  name={'Printing Cost'}
                  htmlFor={`${this.divID}-form-printing-cost-input`}
                  forceActive={this.state.inputVals['printing_cost'] !== ''}
                />
                <input required name='printing_cost' type='number' value={this.state.inputVals['printing_cost']} onChange={this.handleInputValueChange} id={`${this.divID}-form-printing-cost-input`} className='modal-form__input'></input>
              </div>

              <div className="input-field col s12">
                <label className={this.state.inputVals['shipping_cost'] == '' ? '' : 'active'} htmlFor={`${this.divID}-form-shipping-cost-input`}>
                  Shipping Cost
                </label>
                <input name='shipping_cost' type='number' value={this.state.inputVals['shipping_cost']} onChange={this.handleInputValueChange} id={`${this.divID}-form-shipping-cost-input`} className='modal-form__input'></input>
              </div>


              <div className="input-field col s12">
                <label className={this.state.inputVals['misc_cost'] == '' ? '' : 'active'} htmlFor={`${this.divID}-form-misc-cost-input`}>
                  Other Cost (i.e. business cards, etc)
                </label>
                <input name='misc_cost' type='number' value={this.state.inputVals['misc_cost']} onChange={this.handleInputValueChange} id={`${this.divID}-form-misc-cost-input`} className='modal-form__input'></input>
              </div>

              <div className="input-field col s12">
                <label className={this.state.inputVals['notes'] == '' ? '' : 'active'}  data-force-focus-on-click htmlFor={`${this.divID}-form-notes-input`} >
                  Notes / Comments
                </label>
                <textarea name='notes' value={this.state.inputVals['notes']} onChange={this.handleInputValueChange} id={`${this.divID}-form-notes-input`} className="modal-form__input materialize-textarea"></textarea>
              </div>

              <div className='modal-form__submit'>
                { !this.state.apiRequestError &&
                  <LoaderActionButton
                    text={`${this.isPersisted ? 'Update' : 'Add' } Quote`}
                    buttonType={'submit'}
                    faIconClass={'fa-plus'}
                    secondaryClassName={'padding-left padding-right'}
                    isLoading={this.state.isRunningApiRequest}
                    onClick={this.submitForm}
                  />
                }

                { this.state.apiRequestError &&
                  <p>
                    Sorry! something went wrong &nbsp;
                    <a onClick={this.submitForm}>
                      try again
                    </a>
                  </p>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default PrintJobQuoteModal
