import React, { useState, useEffect } from 'react'

function LoaderActionButton(props) {
  const [isLoading, setIsLoading] = useState(props.isLoading)

  // allow isLoading state to be maintained either externally (with isLoading via props) or internally
  useEffect(() => {
    setIsLoading(props.isLoading)
  }, [props.isLoading])

  const onClick = (event) => {

    if(props.onClick) {
      if(isLoading) {
        return
      }

      if(props.confirmationText) {
        if(!window.confirm(props.confirmationText)) {
          return
        }
      }
  
      // only manage loading state internally if parent component isn't doing it
      if(!Object.keys(props).includes('isLoading')) {
        setIsLoading(true)
      }
  
      props.onClick(event)
    }
    else {
      if(isLoading) {
        return
      }

      if(props.confirmationText) {
        if(!window.confirm(props.confirmationText)) {
          event.preventDefault()
          return
        }
      }

      if(!Object.keys(props).includes('isLoading')) {
        setIsLoading(true)
      }
    }
  }

  const renderLinkOrButtonContents = () => {
    return(
      <div className='contents-wrapper'>
        <span>
          {props.text}
        </span>
        { isLoading && props.faIconClass &&
          <i className='fa-solid fa-spinner fa-spin'></i>
        }
        { !isLoading && props.faIconClass &&
          <i className={`fa ${props.faIconClass.includes('fa-regular') ? '' : 'fa-solid'} ${props.faIconClass}`}></i>
        }
      </div>
    )
  }

  const classListString = `button ${(props.secondaryClassName || []).includes('button--admin-action') ? '' : 'button--action'} ${props.isDisabled ? 'disabled' : ''} ${props.secondaryClassName ? props.secondaryClassName : ''} ${isLoading ? 'button--loading' : ''}`

  if(props.href) {
    return(
      <a title={props.title} onClick={onClick} href={props.href} className={classListString}>
        { renderLinkOrButtonContents() }
      </a>
    )
  }
  else {
    return(
      <button title={props.title} type={props.buttonType || 'button'} onClick={onClick} className={classListString}>
        { renderLinkOrButtonContents() }
      </button>
    )
  }
}

export default LoaderActionButton
