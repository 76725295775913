import React from 'react';

class AboutSectionText extends React.Component {
  render() {
    const sectionText = (() => {
      switch(this.props.selectedSection) {
        case('Life'):
          return "My life in Mexico is pretty alright, I suppose I really can't complain."
        case('Photos'):
          return "Photography is a lot of fun but I really should make more time for it."
        // Tech
        default:
          return "I just wanna be a hackerman and write cool scripts to automate my existence."
      }
    }).call();

    return(
      <p className='about__text__section'>{sectionText}</p>
    )
  }
}

export default AboutSectionText;