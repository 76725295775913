import React from 'react'

function SectionSeparator(props) {
  return(
    <div className='section-separator'>
      { props.version == 'small' &&
        <h5>
          {props.text}
        </h5>  
      }

      { !props.version &&
        <h3>
          {props.text}
        </h3>
      }
    </div>
  )
}

export default SectionSeparator