export default {
  scrollToTop: (args = {}) => {
    const timeout = Object.keys(args).includes('timeout') ? args['timeout'] : 50
    const behavior = args['behavior'] || 'smooth'

    window.setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior
      })
    }, timeout)
  },

  scrollToBottom: () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    })
  },

  scrollToElement: (elementId) => {
    const element = document.getElementById(elementId)

    if(element) {
      element.scrollIntoView()
    }
  }
}
