import React, { useEffect } from 'react'

function CountryPicker(props) {
  useEffect(() => {
    // initialise Materialize select 
    Materialize.FormSelect.init(document.getElementById(props.formId), {})
  })

  return(
    <select id={props.formId} name='country' defaultValue={props.selected || 'PLACEHOLDER'} required>
      <option disabled value='PLACEHOLDER'>Select Country</option>

      { props.countriesData.map((countryData, i) => {
        return(
          <option value={countryData['code']} key={i}>
            {countryData['name']}
          </option>
        )
      })

      }
    </select>
  )
}

export default CountryPicker
