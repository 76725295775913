import React from 'react'
import PrintsizingHelper from '../helpers/PrintsizingHelper'
import StringHelper from '../helpers/StringHelper'

function PhotoDetails(props) {
  const photo = props.photo

  return(
    <div className='photo-details'>
      <p className='purple-small-text'>
        {photo.series_id ? 'This photo is part of a series and only has 5 editions available at each size' : 'Limited edition giclée prints'}
      </p>
      
      <div className='photo-details__number-remaining'>
        {
          ['small', 'medium', 'large', 'extra_large'].map((size) => {
            const editionsRemaining = photo.print_editions_remaining_by_size[size]
            const hasEditionsRemaining = editionsRemaining > 0

            return(
              <p className='photo-details__version' key={`photo-${photo.token}-${size}-version`}>
                <strong>
                  {`${StringHelper.capitalise(size.replace(/\_/, ' '))}:`}
                </strong>
                &nbsp;

                <span className={`version-text ${hasEditionsRemaining && photo.print_sizings_available_by_dpi.includes(size) ? '' : 'version-text--unavailable'}`}>
                  {`${PrintsizingHelper.dimensions(size, photo.aspect_group)} ~ €${PrintsizingHelper.cost(size, photo.aspect_group)}`}
                </span>
                <br />

                <span className='version-subtext'>
                  {`Edition of ${photo.default_print_edition_numbers_by_size[size]}`}
                  { !hasEditionsRemaining &&
                    <React.Fragment>
                      &nbsp;
                      -
                      &nbsp;
                      sold out
                    </React.Fragment>
                  }
                </span>
              </p>
            )
          })
        }
      </div>

      <p className='photo-details__certificate-of-authenticity-text'>
        Each print comes with a unique certificate of authenticity
      </p>

      <p>
        For more information about Ordering, Printing, and Delivery, please visit the
        &nbsp;
        <a href='/about'>About page</a>
      </p>
    </div>
  )
}

export default PhotoDetails
