import React from 'react';
import BaseMetrics from './BaseMetrics';
import PhotoCountDifference from './PhotoCountDifference';

class AspectGroupMetrics extends React.Component {
  render() {
    if(this.props.isShowing) {
      const totalCount  = this.props.aspectGroupPhotoData.length;
      const hiddenCount = this.props.aspectGroupPhotoData.filter((photo) => photo['hidden']).length
      const showingCount = totalCount - hiddenCount;

      const balancedAspectGroupCount = showingCount % 3 === 0 && showingCount % 2 === 0

      if(showingCount === 0) {
        return(
          <div className='info-box-right'>
            <div className='info-box-right__wrapper'>
              <p>{this.props.aspectGroup}</p>
              <hr />
              <p>No photos showing</p>
            </div>
          </div>
        )
      }
      else if(balancedAspectGroupCount) {
        return(
          <div className='info-box-right'>
            <div className='info-box-right__wrapper'>
              <BaseMetrics
                aspectGroup={this.props.aspectGroup}
                totalCount={totalCount}
                showingCount={showingCount}
                hiddenCount={hiddenCount}
              />
              <div className='admin__aspect-group-metrics__difference-icon'>
                Balanced <i className="fa fa-solid fa-check"></i>
              </div>
            </div>
          </div>
        );
      }
      else {
        return(
          <div className="info-box-right">
            <div className='info-box-right__wrapper'>
              <BaseMetrics
                aspectGroup={this.props.aspectGroup}
                totalCount={totalCount}
                showingCount={showingCount}
                hiddenCount={hiddenCount}
              />
              <PhotoCountDifference
                increment={false}
                showingCount={showingCount}
              />
              <PhotoCountDifference
                increment={true}
                showingCount={showingCount}
              />
            </div>
          </div>
        );
      }
    }
    else {
      return null;
    }
  }
}

export default AspectGroupMetrics;
