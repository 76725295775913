import React, { Fragment, useState } from 'react'

import ApiService from '../../services/ApiService'
import FlashHelper from '../../helpers/FlashHelper'
import StringHelper from '../../helpers/StringHelper'

import Navbar from '../Navbar'
import Flash from '../Flash'
import OrderStatusTable from '../orders/OrderStatusTable'
import LoaderActionButton from '../LoaderActionButton'
import SectionSeparator from '../SectionSeparator'
import CostsReview from '../orders/CostsReview'
import AddressPreview from '../forms/AddressPreview'
import ReviewOrderPhotos from '../orders/ReviewOrderPhotos'
import AdminFooter from '../AdminFooter'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import ActionButton from '../ActionButton'
import ModalHelper from '../../helpers/ModalHelper'
import OrderTrackingUrlModal from '../modals/OrderTrackingUrlModal'

function Order() {
  const currentPrintJob = JSON.parse(document.getElementById('last_print_job').value)
  const [order, setOrder] = useState(JSON.parse(document.getElementById('order').value))

  const [orderState, setOrderState] = useState(order.state) // for temporarily preserving original order state between transitions
  const [currentFlash, setCurrentFlash] = useState(FlashHelper.loadInitialFlash())

  const unparsedPhotoDataElements = order.photos_json
  const photos = unparsedPhotoDataElements.map((photoDataElement) => JSON.parse(photoDataElement))

  const [performingAction, setPerformingAction] = useState(null)

  const resourceName = document.getElementById('resource_name').value
  
  const isAdmin = resourceName === 'admin'
  const isAdminOrAffiliate = isAdmin || resourceName === 'affiliate'
  const isAdminOrPrinter   = isAdmin || resourceName === 'printer'

  console.log('order: ', order)

  const pluraliseCollectionName = (collectionName) => {
    return StringHelper.pluraliseCollectionName(photos, collectionName)
  }

  if(currentFlash) {
    window.setTimeout(() => {
      setCurrentFlash(null)
    }, 3000)
  }

  const setFlash = (flash) => {
    setCurrentFlash(flash)
    window.setTimeout(() => {
      setCurrentFlash(null)
    }, 3000)
  }

  const handleStateTransitionApiResponse = (response) => {
    setPerformingAction(null)

    if(response.success && Object.keys(response.data).length > 0) {
      const updatedOrder = response.data
      
      setOrder(updatedOrder)
      setFlash({ type: 'notice', message: `Order transitioned from ${orderState} to ${updatedOrder.state}` })
      setOrderState(updatedOrder.state)
    }
    else {
      setFlash({ type: 'error', message: 'Error performing state transition' })
    }
  }

  const markAsPaid = () => {
    setPerformingAction('markAsPaid')

    ApiService.payOrder(order.token)
      .then((response) => {
        handleStateTransitionApiResponse(response)
      })
  }

  // can come from PAID or AWAITING_PRINT
  const markAsCompleted = () => {
    setPerformingAction('markAsCompleted')

    ApiService.completeOrder(order.token)
      .then((response) => {
        handleStateTransitionApiResponse(response)
      })
  }

  // can come straight from PAID (could already be printed) or from AWAITING_PRINT (newly printed)
  const markAsShipped = () => {
    setPerformingAction('markAsShipped')

    ApiService.shipOrder(order.token)
      .then((response) => {
        handleStateTransitionApiResponse(response)
      })
  }

  const cancelOrder = () => {
    setPerformingAction('cancelOrder')

    ApiService.cancelOrder(order.token, { admin_json: true })
      .then((response) => {
        handleStateTransitionApiResponse(response)

        const currentCartOrderToken = LocalStorageHelper.currentOrderToken

        if(currentCartOrderToken && currentCartOrderToken == order.token) {
          LocalStorageHelper.clearCurrentCart()
        }
      })
  }

  const receiveAffiliatePayout = () => {
    setPerformingAction('receiveAffiliatePayout')

    ApiService.receiveAffiliatePayout(order.token)
      .then((response) => {
        setPerformingAction(false)

        if(response.success && response.data) {
          setOrder(response.data)
          // TOOD: serialise flash from backend
          setFlash({ type: 'notice', message: 'Great! Affiliate Payout received 😊' })
        }
        else {
          setFlash({ type: 'error', message: 'Sorry! something went wrong just then. Try refreshing the page and trying again.' })
        }
      })
  }

  const addToPrintJob = () => {
    setPerformingAction('addToPrintJob')

    ApiService.addToPrintJob(order.token, currentPrintJob.token)
    .then((response) => {
      setPerformingAction(false)

      if(response.success) {
        setOrder(response.data['order'])
        // TOOD: serialise flash from backend
        setFlash({ type: 'notice', message: response.data['message'] })
      }
      else {
        setFlash({ type: 'error', message: response.data['message'] })
      }
    })
  }

  return(
    <React.Fragment>
      <Navbar title={'Customer Order'}/>
      
      <Flash flash={currentFlash} />

      <div className='admin-order'>
        <SectionSeparator text={`Order ${order.token}`} />


        <div className='order-show__section'>
          <OrderStatusTable order={order} />
        </div>

        { isAdmin &&
          <div className='order-show__section'>
            <LoaderActionButton
              text={'View as Customer'}
              href={`/orders/${order.token}`}
              secondaryClassName={'order-show__link-or-action-button'}
              faIconClass={'fa-eye'}
            />
          </div>
        }

        { isAdmin && order.delivery_method === 'delivery' &&
          <Fragment>
            <OrderTrackingUrlModal
              order={order}
              setOrder={setOrder}
              setFlash={setFlash}
            />
            <div className='order-show__section'> 
              <ActionButton
                isShowing={true}
                text={'Update tracking URL'}
                faIconClass={'fa-truck-fast'}
                secondaryClassName={'order-show__link-or-action-button'}
                clickHandler={() => { ModalHelper.openModalClickHandler(`order-${order.token}-tracking-url-modal`) }}
              />
            </div>
          </Fragment>
        }

        { isAdmin && order.can_add_to_print_job && currentPrintJob['is_started'] !== undefined && !currentPrintJob['is_started'] &&
          <div className='order-show__section'>
            <LoaderActionButton
              text={'Add to Print Job'}
              secondaryClassName={'order-show__link-or-action-button'}
              faIconClass={'fa-print'}
              isLoading={performingAction === 'addToPrintJob'}
              onClick={addToPrintJob}
              confirmationText={'Add to Current Print Job?'}
            />
          </div>
        }

        { isAdminOrAffiliate && order.is_affiliate_payable && order.is_awaiting_affiliate_payout &&
          <div className='order-show__section'>
            <LoaderActionButton
              text={'Affiliate Payout Received'}
              secondaryClassName={'order-show__link-or-action-button'}
              faIconClass={'fa-hand-holding-heart'}
              isLoading={performingAction === 'receiveAffiliatePayout'}
              onClick={receiveAffiliatePayout}
              confirmationText={'Mark Affiliate Payout as received?'}
            />
          </div>
        }

        { isAdmin && order.is_payable &&
          <div className='order-show__section'>
            <LoaderActionButton
              text={'Mark as Paid'}
              secondaryClassName={'order-show__link-or-action-button'}
              faIconClass={'fa-hand-holding-dollar'}
              isLoading={performingAction === 'markAsPaid'}
              onClick={markAsPaid}
              confirmationText={'Mark Order as paid?'}
            />
          </div>
        }

        { isAdmin && order.is_completable && // is_completable / is_shippable are aliases
          <div className='order-show__section'>
            <LoaderActionButton
              text={'Mark as Completed'}
              secondaryClassName={'order-show__link-or-action-button'}
              faIconClass={'fa-check'}
              isLoading={performingAction === 'markAsCompleted'}
              onClick={markAsCompleted}
              confirmationText={'Mark Order as completed?'}
            />
          </div>
        }

        { isAdminOrPrinter && order.is_completable && order.delivery_method == 'delivery' &&
          <div className='order-show__section'>
            <LoaderActionButton
              text={'Mark as Shipped'}
              secondaryClassName={'order-show__link-or-action-button'}
              faIconClass={'fa-truck-fast'}
              isLoading={performingAction === 'markAsShipped'}
              onClick={markAsShipped}
              confirmationText={'Mark Order as shipped?'}
            />
          </div>
        }

        { isAdmin && order.is_cancellable &&
          <div className='order-show__section'>
            <LoaderActionButton
              text={'Cancel Order'}
              secondaryClassName={'order-show__link-or-action-button'}
              faIconClass={'fa-trash-can'}
              isLoading={performingAction === 'cancelOrder'}
              onClick={cancelOrder}
              confirmationText={'Cancel Order?'}
            />
          </div>
        }

        { isAdminOrAffiliate &&
          <React.Fragment>
            <SectionSeparator text={'Order Costs'}/>

            <div className='order-show__section'>
              <div className='review-order-photos'>
                <CostsReview
                  isPaid={order.is_paid}
                  discountCodeMetadata={order.discount_code_metadata}
                  deliveryMethod={order.delivery_method}
                  pluraliseCollectionName={pluraliseCollectionName}
                  printsCost={order.prints_cost}
                  totalCost={order.total_cost}
                  shippingCost={order.shipping_cost}
                />
              </div>
            </div>
          </React.Fragment>
        }

        { !order.is_cancelled && order.delivery_method === 'delivery' && order.address && Object.keys(order.address).length > 0 &&
          <React.Fragment>
            <SectionSeparator text={'Delivery Address'}/>
            
            <div className='order-show__section'>
              <AddressPreview
                hideHeader={true}
                address={order.address}
              />
            </div>
          </React.Fragment>
        }

        <React.Fragment>
          <SectionSeparator text={`Order ${pluraliseCollectionName('Photo')}`}/>

          <div className='order-show__section'>
            <ReviewOrderPhotos
              photos={photos}
              optionsDisabled={true}
            />
          </div>

        </React.Fragment>
      </div>
      
      <AdminFooter currentPage={'order'} reosurceName={resourceName} />
    </React.Fragment>
  )  
}

export default Order
