import React from 'react'
import ApiService from '../../services/ApiService'
import PaginationHelper from '../../helpers/PaginationHelper'

import Navbar from '../Navbar'
import PhotosContainer from './PhotosContainer'
import VisibilityToggle from './VisibilityToggle'
import Pagination from '../Pagination'
import AdminFooter from '../AdminFooter'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      photoData: {},
      currentPage: "1",
      showPagination: true,
      showHiddenPhotos: false,
      excludeSeriesPhotos: false,
      excludeBRollPhotos: false
    }
  }

  componentDidMount() {
    ApiService.loadPhotos({ merge_hidden: false }).then((photoData) => {
      this.setState({ photoData })
    })
  }

   // full range of possible pages
   _allPages = () => {
    return Object.keys(this.state.photoData)
  }

  changePage = (page) => {
    const newPage = PaginationHelper.newPage(
      page,
      this.state.currentPage,
      this._allPages()
    )

    if(newPage) {
      this.setState({ currentPage: newPage })
    }
  }

  reloadPhotoData = (photoData) => {
    this.setState({ photoData })
  }

  processLoadPhotosResponse = (photoData) => {
    const allPages = Object.keys(photoData)
    const lastPage = allPages[allPages.length - 1]

    const currentPage = (() => {
      if(parseInt(this.state.currentPage) > parseInt(lastPage)) {
        return lastPage
      }
      else {
        return this.state.currentPage
      }
    })()

    this.setState({ photoData, currentPage })
  }

  // TODO: combine these 3 functions

  toggleHiddenPhotos = () => {
    const showHiddenPhotos = !this.state.showHiddenPhotos

    this.setState({ showHiddenPhotos }, () => {
      ApiService.loadPhotos({ merge_hidden: showHiddenPhotos, exclude_series: this.state.excludeSeriesPhotos, exclude_b_roll: this.state.excludeBRollPhotos }).then((photoData) => {
        this.processLoadPhotosResponse(photoData)
      })
    })
  }

  toggleSeriesPhotos = () => {
    const excludeSeriesPhotos = !this.state.excludeSeriesPhotos

    this.setState({ excludeSeriesPhotos }, () => {
      ApiService.loadPhotos({ merge_hidden: this.state.showHiddenPhotos, exclude_series: excludeSeriesPhotos, exclude_b_roll: this.state.excludeBRollPhotos }).then((photoData) => {
        this.processLoadPhotosResponse(photoData)
      })
    })
  }

  toggleBRollPhotos = () => {
    const excludeBRollPhotos = !this.state.excludeBRollPhotos

    this.setState({ excludeBRollPhotos }, () => {
      ApiService.loadPhotos({ merge_hidden: this.state.showHiddenPhotos, exclude_series: this.state.excludeSeriesPhotos, exclude_b_roll: excludeBRollPhotos }).then((photoData) => {
        this.processLoadPhotosResponse(photoData)
      })
    })
  }

  render() {
    return(
      <React.Fragment>
        <Navbar title='keenedreams'/>

        <VisibilityToggle
          text={'Show Hidden'}
          variant={'hidden'}
          isChecked={this.state.showHiddenPhotos}
          toggleHandler={this.toggleHiddenPhotos}
        />

        <VisibilityToggle
          text={'Show Series'}
          variant={'series'}
          isChecked={!this.state.excludeSeriesPhotos}
          toggleHandler={this.toggleSeriesPhotos}
        />

        { !this.state.excludeSeriesPhotos &&
          <VisibilityToggle
            text={'Show B-roll'}
            variant={'b-roll'}
            isChecked={!this.state.excludeBRollPhotos}
            toggleHandler={this.toggleBRollPhotos}
          />
        }

        <PhotosContainer
          photoData={this.state.photoData[this.state.currentPage]}
          reloadPhotoData={this.reloadPhotoData}
          showHiddenPhotos={this.state.showHiddenPhotos}
          excludeSeriesPhotos={this.state.excludeSeriesPhotos}
          excludeBRollPhotos={this.state.excludeBRollPhotos}
        />

        <Pagination
          isShowing={this.state.showPagination}
          currentPage={this.state.currentPage}
          pages={this._allPages()}
          changePage={this.changePage}
        />

        <AdminFooter currentPage={'home'} />
      </React.Fragment>
    )
  }
}

export default Home