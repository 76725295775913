import React from 'react';

import ModalCloseIcon from './ModalCloseIcon';
import CartPhotos from '../CartPhotos'
import ApiService from '../../services/ApiService'

import PrintsListContext from '../contexts/PrintsListContext'
import LoaderActionButton from '../LoaderActionButton'

import ModalHelper from '../../helpers/ModalHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'

class PrintsListModal extends React.Component {

  constructor(props) {
    super(props);

    this.divID = 'prints-list-modal'
    this.state = ModalHelper.initialStateFor('prints-list')
  }

  componentDidMount() {
    this.modalInstance = ModalHelper.initialiseModal('prints-list', this)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.currentCartPhotos.length !== this.props.currentCartPhotos.length) {
      // for convenience store in an intermediate structure here, with the key being the photo token
      let previousPrintSizings = this.state.printSizings || {}
      let newPrintSizings = {}

      this.props.currentCartPhotos.forEach((photo) => {
        newPrintSizings[photo.token] ||= previousPrintSizings[photo.token] || 'small'
      })

      this.setState({ printSizings: newPrintSizings })
    }
  }

  onModalOpen = () => {
    let sectionsVisible = this.state.sectionsVisible
    
    sectionsVisible['currentCartPhotos'] = true
    
    // set this on modal open since loading it from backend and storing in localstorage can be slightly delayed
    const currentAffiliateCode = LocalStorageHelper.currentAffiliateCode()

    this.setState({ sectionsVisible, currentAffiliateCode })

  }

  closeModal = () => {
    this.modalInstance.close()
  }

  toggleCollapsibleSection = (sectionKey) => {
    let sectionsVisible = this.state.sectionsVisible
    sectionsVisible[sectionKey] = !sectionsVisible[sectionKey]

    this.setState({ sectionsVisible })
  }

  selectPrintSize = (photoToken, size) => {
    let printSizings = this.state.printSizings

    printSizings[photoToken] = size

    this.setState({ printSizings }) // this will also populate hidden form
  }

  goToCheckout = () => {
    this.setState({ isSubmittingCheckoutForm: true })

    document.getElementById('go-to-checkout-form').submit()
  }

  updateCurrentPhotoTokensContext = () => {
    const currentPhotoTokensFromSession = LocalStorageHelper.currentCartPhotoTokens()
        
    this.props.setCurrentCartPhotosFromTokens(currentPhotoTokensFromSession)
  }

  removeFromCart = (photoToken) => {
    if(window.confirm('Remove this photo from the Cart?') && LocalStorageHelper.removeFromCart(photoToken)) {
      this.updateCurrentPhotoTokensContext()
    }
    else {
      console.log('something went wrong removing from cart')
    }
  }

  photoTokensHiddenInputValue = () => {
    return JSON.stringify(this.props.currentCartPhotoTokens)
  }

  printSizingsHidenInputValue = () => {
    const sizings = this.state.printSizings
    
    return JSON.stringify(
      Object.keys(sizings).map((photoToken) => {
        return { photo_token: photoToken, size: sizings[photoToken] }
      })
    )
  }

  render() {
    const currentPhotos = this.props.currentCartPhotos
    const currentPhotoTokens = this.props.currentCartPhotoTokens

    return(
      <PrintsListContext.Provider
        value={{
          printSizings: this.state.printSizings,
          selectPrintSize: this.selectPrintSize
        }}
      >
        <div id={this.divID} className='modal modal--prints-list'>
          <form method='post' action='/orders' id='go-to-checkout-form'>
            <input name='authenticity_token' type='hidden' value={ApiService.authenticityToken()} />

            { this.state.currentAffiliateCode &&
              <input type='hidden' name='affiliate_code[code]' id='affiliate_code_code' value={this.state.currentAffiliateCode} />
            }
            <input type='hidden' name='photo_tokens' id='photo_tokens' value={this.photoTokensHiddenInputValue()} />
            { this.state.printSizings &&
              <input type='hidden' name='print_sizings' id='print_sizings' value={this.printSizingsHidenInputValue()} />
            }
          </form>

          <div className='modal-content'>
            <ModalCloseIcon
              closeModal={this.closeModal}
              modalID={`prints-list-modal`}
            />

            <React.Fragment>
              <h4>Cart</h4>

              <div className='modal--prints-list__selected-photos-status'>
                { currentPhotoTokens.length === 0 &&
                  <p>Cart is empty</p>
                }
              </div>
            </React.Fragment>

            { currentPhotoTokens.length > 0 &&
              <div className='modal__stacked-buttons'>
                <LoaderActionButton
                  text={'Go to Checkout'}
                  faIconClass={'fa-arrow-right'}
                  onClick={this.goToCheckout}
                  isLoading={this.state.isSubmittingCheckoutForm}
                />
              </div>
            }

            { this.state.printSizings &&
              <div className='modal--prints-list__photos-wrapper'>
                <CartPhotos
                  photos={currentPhotos}
                  removeFromCart={this.removeFromCart}
                  printSizings={this.state.printSizings}
                />
              </div>
            }
          </div>
        </div>
      </PrintsListContext.Provider>
    )
  }
}

export default PrintsListModal;
