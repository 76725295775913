const mappings = {
  bitcoin: {
    name: 'Bitcoin',
    symbol: 'BTC',
    network: 'mainnet',
    address: '1L4cMX8QDGiSQ4WDAy6KrCo6YRFbjkgmpZ'
  },
  ethereum: {
    name: 'Ethereum',
    symbol: 'ETH',
    network: 'ERC20',
    address: '0x944ffe25f99c5fd211e5aef9e574dd5854111da0'
  },
  cardano: {
    name: 'Cardano',
    symbol: 'ADA',
    network: 'mainnet',
    address: 'DdzFFzCqrhsgZ6xzN4oFLfqpfXG4c6NpB84zccyAG7KutVzMXct1omJQUxCJhypZSS66mK9icfAGtKfzcT9vbHcQtKT6xYyJBvsjnzRn'
  }
}

export default {
  metadata: (crypto) => {
    return mappings[crypto]
  },

  cryptos: () => {
    // return Object.keys(mappings)
    return [] // temporarily disable crypto payment because of issues scraping price
  }
}
