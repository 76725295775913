const _queryLocalStorage = (itemKey, missingValueDefault) => {
  const item = window.localStorage.getItem(itemKey)

  if(item) {
    return JSON.parse(item)
  }
  else {
    return missingValueDefault
  }
}

const _currentCart = () => {
  return _queryLocalStorage('currentCart', {})
}

const _previousOrderPhotoTokens = () => {
  return _queryLocalStorage('previousOrderPhotoTokens', [])
}

const _currentCartPhotoTokens = () => {
  const cart = _currentCart()

  return cart['photo_tokens'] || []
}

const _isInCurrentCart = (photoToken) => {
  return _currentCartPhotoTokens().includes(photoToken)
}

module.exports = {
  LocalStorageIsAvailable: () => {
    let storage;
    try {
        storage = window.localStorage;
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return e instanceof DOMException && (
          // everything except Firefox
          e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
          // acknowledge QuotaExceededError only if there's something already stored
          (storage && storage.length !== 0
        )
    }
  },

  addToCart: (photoToken) => {
    let cart = _currentCart()
    let photoTokens = cart['photo_tokens'] || []

    photoTokens.push(photoToken)

    cart['photo_tokens'] = photoTokens

    window.localStorage.setItem('currentCart', JSON.stringify(cart))

    return _isInCurrentCart(photoToken) // success if added
  },

  removeFromCart: (photoToken) => {
    let cart = _currentCart()
    let photoTokens = cart['photo_tokens'].filter((token) => token != photoToken)

    cart['photo_tokens'] = photoTokens

    window.localStorage.setItem('currentCart', JSON.stringify(cart))

    return !_isInCurrentCart(photoToken) // success if removed
  },

  // TODO: implement expiry, and stop clearing when loading about page with no param present
  storeAffiliateCode: (affiliateCode) => {
    // For passing to backend when order is created, to associate with affiliate
    window.localStorage.setItem('affiliateCode', affiliateCode)
  },

  clearAffiliateCode: () => {
    window.localStorage.removeItem('affiliateCode')
  },

  currentAffiliateCode: () => {
    return window.localStorage.getItem('affiliateCode')
  },

  // // takes array of photo tokens
  // addToPreviousOrderPhotoTokens: (photoTokens) => {
  //   let previousOrderPhotoTokens = _previousOrderPhotoTokens()

  //   previousOrderPhotoTokens = previousOrderPhotoTokens.concat(photoTokens).filter((el) => el !== null)

  //   window.localStorage.setItem('previousOrderPhotoTokens', JSON.stringify(previousOrderPhotoTokens))
  // },

  previousOrderPhotoTokens: _previousOrderPhotoTokens,

  isInCurrentCart: _isInCurrentCart,

  clearCurrentCart: () => {
    window.localStorage.removeItem('currentCart')
    window.localStorage.removeItem('orderToken')
  },

  storeOrderToken: (orderToken) => {
    window.localStorage.setItem('orderToken', orderToken)
  },

  currentOrderToken: () => {
    return window.localStorage.getItem('orderToken')
  },

  currentCart: _currentCart,
  currentCartPhotoTokens: _currentCartPhotoTokens
}
