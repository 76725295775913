import React from 'react'
import ModalCloseIcon from './ModalCloseIcon'
import ModalHelper from '../../helpers/ModalHelper'
import LoaderActionButton from '../LoaderActionButton'
import ApiService from '../../services/ApiService'

// create or update
class OrderTrackingUrlModal extends React.Component {
  constructor(props) {
    super(props)

    this.order = props.order

    this.divID = `order-${this.order.token}-tracking-url-modal`
    
    this.state = {
      isRunningApiRequest: false,
      apiRequestError: false,
      trackingUrl: this.order.tracking_url
    }
  }

  componentDidMount() {
    this.modalInstance = ModalHelper.initialiseModal(this.divID, this)
  }

  closeModal = () => {
    this.modalInstance.close()
  }

  handleApiResponse = (response) => {
    if(response.success) {
      const responseData = response.data

      const updateStateArgs = {
        apiRequestSuccess: true,
        isRunningApiRequest: false
      }

      this.setState(
        updateStateArgs,
        () => {
          this.props.setOrder(responseData) // update order in parent component
          
          if(responseData.flash) {
            this.props.setFlash(responseData.flash)
          }

          this.closeModal()
        }
      )
    }
    else {
      this.setState({
        isRunningApiRequest: false,
        apiRequestError: true
      })
    }
  }

  handleTrackingUrlInputChange = (e) => {
    this.setState({ trackingUrl: e.target.value })
  }

  submitForm = (event) => {
    event.stopPropagation()
    event.preventDefault()

    const updateOrderCallback = () => {
      ApiService.updateOrder(this.order.token, {
        order: { tracking_url: this.state.trackingUrl }
      })
      .then((response) => {
        this.handleApiResponse(response)
      })
    }

    this.setState(
      {
        isRunningApiRequest: true,
        apiRequestError: false
      },
      updateOrderCallback
    )
  }

  render() {
    return(
      <div id={this.divID} className='modal modal--order-tracking-url'>
        <div className='modal-content'>
          <ModalCloseIcon
            closeModal={this.closeModal}
            modalID={this.divID}
          />

          <h5 className='padding-bottom'>
            Order Tracking URL
          </h5>
  
          <div className='modal-form'>
            <form onSubmit={(e) => { e.preventDefault() }} className="col s12">
    
              <div className="input-field col s12">
                <label className={['', null].includes(this.state.trackingUrl) ? '' : 'active'} htmlFor={`${this.divID}-input`}>
                  Tracking URL
                </label>
                <input name='shipping_cost' type='text' value={this.state.trackingUrl || ''} onChange={this.handleTrackingUrlInputChange} id={`${this.divID}-input`} className='modal-form__input'></input>
              </div>

              <div className='modal-form__submit'>
                { !this.state.apiRequestError &&
                  <LoaderActionButton
                    text={'Update'}
                    buttonType={'submit'}
                    faIconClass={'fa-check'}
                    secondaryClassName={'padding-left padding-right'}
                    isLoading={this.state.isRunningApiRequest}
                    onClick={this.submitForm}
                  />
                }

                { this.state.apiRequestError &&
                  <p>
                    Sorry! something went wrong &nbsp;
                    <a onClick={this.submitForm}>
                      try again
                    </a>
                  </p>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default OrderTrackingUrlModal
