import React from 'react'
import LoaderActionButton from './LoaderActionButton'
import ExamplePrintPreviewPhoto from './ExamplePrintPreviewPhoto'

function ExamplePrintPhoto(props) {
  const photoData = props.photoData
  const images = photoData.example_print_images

  return(
    <div className='example-print-photos' id={`${photoData.token}-example-print-photos`}>
      <div className='example-print-photos__header'>
        <p className='photo-name'>
          {photoData.name}
        </p>

        <div className='photo-link'>
          <LoaderActionButton
            text={'Go to Photo'}
            href={`/photos/${photoData.token}`}
            faIconClass={'fa-arrow-right'}
          />
        </div>
      </div>


      {
        images.preview.map((previewImageFilename) => {
          return(
            <ExamplePrintPreviewPhoto
              key={previewImageFilename}
              filename={previewImageFilename}
            />
          )

        })
      }
    </div>
  ) 
}

export default ExamplePrintPhoto
