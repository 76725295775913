import React from 'react'
import StringHelper from '../../helpers/StringHelper'
import ModalHelper from '../../helpers/ModalHelper'
import CryptoAddressModal from '../modals/CryptoAddressModal'
import CryptoHelper from '../../helpers/CryptoHelper'

function OrderStatusTable(props) {
  const order = props.order
  
  const paymentMethodIsCrypto = ['bitcoin', 'ethereum', 'cardano'].includes(order.payment_method)
  const paidWithCrypto = order.is_paid && paymentMethodIsCrypto

  const cryptoPaymentMetadata = paidWithCrypto ? CryptoHelper.metadata(order.payment_method) : {}

  const paymentMethodString = (() => {
    if(paidWithCrypto) {
      return `${order.payment_method} (${cryptoPaymentMetadata.symbol})`
    }
    else {
      return order.payment_method
    }
  })()

  return(
    <React.Fragment>
      { paidWithCrypto &&
        <CryptoAddressModal
          crypto={order.payment_method}
          metadata={cryptoPaymentMetadata}
        />
      }
      <table className='order-status-table'>
        <tbody>
          { order.customer &&
            <React.Fragment>
              <tr className='customer-row customer-row--top'>
                <td>Customer</td>
                <td>
                  <a href={`/dreams_admin/customers/${order.customer['id']}`}>
                    {order.customer['name']}
                  </a>
                </td>
              </tr>
              <tr className='customer-row customer-row--bottom'>
                <td>Customer Email</td>
                <td>{order.customer['email']}</td>
              </tr>
            </React.Fragment>
          }
          <tr>
            <td>Order Status</td>
            <td className='purple-text'>{order.status}</td>
          </tr>
          { !order.is_cancelled &&
            <React.Fragment>
              <tr>
                <td>Order Created</td>
                <td>{order.created_at}</td>
              </tr>
              { order.delivery_method &&
                <tr>
                  <td>Delivery Method</td>
                  <td>{StringHelper.capitalise(order.delivery_method)}</td>
                </tr>
              }
              { order.payment_method &&
                <tr>
                  <td>Payment Method</td>
                  <td>{StringHelper.capitalise(paymentMethodString)}</td>
                </tr>
              }
            </React.Fragment>
          }
          { order.is_paid && order.payment_method === 'card' &&
            <tr>
              <td>Payment Details</td>
              <td>{order.stripe_payment_details}</td>
            </tr>
          }
          { paidWithCrypto &&
            <tr>
              <td>Payment Details</td>
              <td>
                <a onClick={(_e) => { ModalHelper.openModalClickHandler(`${order.payment_method}-crypto-address-modal`)} } className='orange-hover-transition'>
                  {`${cryptoPaymentMetadata.symbol} Address`}
                </a>
              </td>
            </tr>
          }
          { order.is_paid && order.paid_at &&
            <tr>
              <td>Paid at</td>
              <td>{order.paid_at}</td>
            </tr>
          }
          { order.is_cancelled && order.cancelled_at &&
            <tr>
              <td>Cancelled at</td>
              <td>{order.cancelled_at}</td>
            </tr>
          }
          { order.is_shipped && order.shipped_at &&
            <tr>
              <td>Shipped at</td>
              <td>{order.shipped_at}</td>
            </tr>
          }
          { order.tracking_number && // tracking_number presence always means tracking_url is also present
            <tr>
              <td>Tracking link</td>
              <td>
                <a href={order.tracking_url} target='_blank'>
                  {order.tracking_number}
                </a>
              </td>
            </tr>
          }
          { order.is_completed && order.completed_at &&
            <tr>
              <td>Order Completed</td>
              <td>{order.completed_at}</td>
            </tr>
          }
          { order.print_job_token &&
            <tr>
              <td>Print Job</td>
              <td><a href={`/dreams_admin/print_jobs/${order.print_job_token}`}>{order.print_job_token}</a></td>
            </tr>
          }
          { order.affiliate_business_name &&
          <tr className='affiliate-row affiliate-row--top'>
            <td>Affiliate</td>
            <td>{order.affiliate_business_name}</td>
          </tr>
          }
          { order.affiliate_business_name && order.affiliate_payout_amount &&
            <tr className='affiliate-row'>
              <td>Payout amount</td>
              <td>{`${order.affiliate_payout_amount}`}</td>
            </tr>
          }
          { order.affiliate_business_name && order.affiliate_payout_status &&
            <tr className='affiliate-row'>
              <td>Payout status</td>
              <td>{order.affiliate_payout_status}</td>
            </tr>
          }

          { order.affiliate_business_name && order.affiliate_payout_metadata && order.affiliate_payout_metadata['marked_as_received_at'] &&
            <tr className='affiliate-row'>
              <td>Payout received</td>
              <td>{order.affiliate_payout_metadata['marked_as_received_at']}</td>
            </tr>
          }

          { order.affiliate_business_name && order.affiliate_payout_metadata && order.affiliate_payout_metadata['marked_as_received_by'] &&
            <tr className='affiliate-row'>
              <td>Marked as received by</td>
              <td>{order.affiliate_payout_metadata['marked_as_received_by']}</td>
            </tr>
          }
        </tbody>
      </table>

    </React.Fragment>
  )
}

export default OrderStatusTable
