import ApiService from '../services/ApiService'
import LocalStorageHelper from './LocalStorageHelper'
import PrintsizingHelper from './PrintsizingHelper'

// assumes localStorage has already been updated, is only updating component state here
const _setCurrentCartPhotosFromTokens = (component, currentCartPhotoTokens) => {
  let photosToFilter = component.state.orderableHiddenPhotos || []

  // destructure photoData (flatten across pagination + aspect groupings)
  Object.values(component.state.photoData).forEach((paginationGroup) => {
    Object.values(paginationGroup).forEach((aspectGroupArray) => {
      photosToFilter = photosToFilter.concat(aspectGroupArray)
    })
  })

  const currentCartPhotos = photosToFilter.filter((photo) => currentCartPhotoTokens.includes(photo.token))

  component.setState({ currentCartPhotoTokens, currentCartPhotos}, () => {
    const maxPage = Object.keys(component.state.photoData).length
    const currentPage = parseInt(component.state.currentPage || '1')

    if(currentPage > maxPage) {
      component.setState({ currentPage: `${maxPage}` })
    }
    else if(currentPage < 1) {
      component.setState({ currentPage: '1' })
    }
  })

}

const _currentCartPhotoTokensFromLocalStorage = (component) => {
  if(component.LocalStorageIsAvailable) {
    return LocalStorageHelper.currentCartPhotoTokens()
  }
  else {
    return []
  }
}

// load the cart photo tokens from localstorage, and add to component state
const _setCurrentCartPhotosFromLocalStorage = (component) => {
  const currentCartPhotoTokens = _currentCartPhotoTokensFromLocalStorage(component)

  _setCurrentCartPhotosFromTokens(component, currentCartPhotoTokens)
}

export default {
  photoCloudfrontUrl: (baseName, aspectGroup) => {
    const cloudfrontBaseUrl = document.getElementById('photos_cloudfront_url').value

    return `${cloudfrontBaseUrl}/${baseName}-${aspectGroup}-thumbnail.jpg`
  },

  watermarkedPhotoCloudfrontUrl: (basename) => {
    const cloudfrontBaseUrl = document.getElementById('watermarked_photos_cloudfront_url').value

    return `${cloudfrontBaseUrl}/watermarked_${basename}.jpg`
  },

  setCurrentCartPhotosFromLocalStorage: _setCurrentCartPhotosFromLocalStorage,

  loadPhotos: (component) => {
    component.LocalStorageIsAvailable = LocalStorageHelper.LocalStorageIsAvailable()

    ApiService.loadPhotos({ merge_hidden: false }).then((photoData) => {
      component.setState({ photoData }, () => {
        // load hidden photos for cart in separate request
        ApiService.loadPhotos({ load_orderable_hidden: true }).then((orderableHiddenPhotos) => {
          // once hidden photos are returned by API, add them to component state, and then set the cart tokens in nested callback
          component.setState({ orderableHiddenPhotos }, () => {
            _setCurrentCartPhotosFromLocalStorage(component)
          })

        })
      })
    })
  },

  // used for calculating how much padding the placeholder image will need
  // inverse because it's height/width (aspect ratio is usually width/height)

  loadingPhotoSpinnerWrapperPaddingPercentage: (aspectGroup) => {
    const mappings = {
      portrait: 1.25,
      square: 1.0,
      landscape: 0.8
    }
   
    return (mappings[aspectGroup] * 100) * 0.5;
  },

  currentCartPhotoTokensFromLocalStorage: _currentCartPhotoTokensFromLocalStorage,

  setCurrentCartPhotosFromTokens: _setCurrentCartPhotosFromTokens,

  totalCost: (printSizes) => {
    let cost = 0.0

    // can be used where async state needs time to populate
    if(printSizes) {
      // not actual printSize object, just size property (small, medium, large, custom)
      printSizes.forEach((size) => {
        cost += PrintsizingHelper.cost(size)
      })
    }

    return cost
  }
}
