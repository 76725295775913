import React, { useState } from 'react'

import StringHelper from '../../helpers/StringHelper'
import FlashHelper from '../../helpers/FlashHelper'

import Navbar from '../Navbar'
import Flash from '../Flash'
import SectionSeparator from '../SectionSeparator'
import OrderStatusTable from '../orders/OrderStatusTable'
import LoaderActionButton from '../LoaderActionButton'
import ReviewOrderPhotos from '../orders/ReviewOrderPhotos'
import Footer from './Footer'
import ApiService from '../../services/ApiService'

function Order() {
  const [order, setOrder] = useState(JSON.parse(document.getElementById('order').value))
  const [currentFlash, setCurrentFlash] = useState(FlashHelper.loadInitialFlash())

  const unparsedPhotoDataElements = order.photos_json
  const photos = unparsedPhotoDataElements.map((photoDataElement) => JSON.parse(photoDataElement))

  const [isReceivingAffiliatePayout, setIsReceivingAffiliatePayout] = useState(false)

  const pluraliseCollectionName = (collectionName) => {
    return StringHelper.pluraliseCollectionName(photos, collectionName)
  }

  const setFlash = (flash) => {
    setCurrentFlash(flash)
    window.setTimeout(() => {
      setCurrentFlash(null)
    }, 3000)
  }

  const receiveAffiliatePayout = () => {
    if(isReceivingAffiliatePayout) {
      return
    }

    if(window.confirm('Mark Affiliate Payout as received?')) {
      setIsReceivingAffiliatePayout(true)

      ApiService.receiveAffiliatePayout(order.token)
        .then((response) => {
          setIsReceivingAffiliatePayout(false)

          if(response.success && response.data) {
            setOrder(response.data)
            // TOOD: serialise flash from backend
            setFlash({ type: 'notice', message: 'Great! Affiliate Payout received 😊' })
          }
          else {
            setFlash({ type: 'error', message: 'Sorry! something went wrong just then. Try refreshing the page and trying again.' })
          }
        })
    }
  }

  return(
    <React.Fragment>
      <Navbar title='Affiliate Order'/>
      <Flash flash={currentFlash} />

      <div className='affiliate-order'>
        <SectionSeparator text={`Order ${order.token}`}/>

        <div className='order-show__section'>
          <OrderStatusTable order={order} />
        </div>

        { order.is_affiliate_payable && order.is_awaiting_affiliate_payout &&
          <div className='order-show__section'>
            <LoaderActionButton
              text={'Affiliate Payout Received'}
              secondaryClassName={'order-show__link-or-action-button'}
              faIconClass={'fa-hand-holding-heart'}
              isLoading={isReceivingAffiliatePayout}
              onClick={receiveAffiliatePayout}
            />
          </div>
        }

        <React.Fragment>
          <SectionSeparator text={`Order ${pluraliseCollectionName('Photo')}`}/>

          <div className='order-show__section'>
            <ReviewOrderPhotos
              photos={photos}
              pluraliseCollectionName={pluraliseCollectionName}
              deliveryMethod={order.delivery_method}
              optionsDisabled={true}
              discountCodeMetadata={order.discount_code_metadata}
              printsCost={order.prints_cost}
              shippingCost={order.shipping_cost}
              totalCost={order.total_cost}
            />
          </div>

        </React.Fragment>
      </div>
      <Footer currentPage={'order'} />
    </React.Fragment>
  )
}

export default Order
