const _defaultSectionsVisible = (modalVariant) => {
  if(modalVariant === 'photo') {
    return {}
  }
  else if(modalVariant === 'prints-list') {
    return {
      currentCartPhotos: false // will be set to true when modal is opened. Needs to do it itself with a small delay
    }
  }
}

const _photoTokenFromWrapper = (element) => {
  return element.closest('.photo__wrapper').getAttribute('data-token')
}

const _initialStateFor = (modalVariant, caller = null) => {
  const sectionsVisible = _defaultSectionsVisible(modalVariant)

  if(modalVariant === 'photo') {
    return {
      sectionsVisible,
      previouslyOrdered: caller && caller.props ? !caller.props.isInCurrentCart : true
    }
  }
  else if(modalVariant === 'prints-list') {
    return {
      sectionsVisible,
      isSubmittingCheckoutForm: false
    }
  }
}

// some of the state is propagated asyncronously between context and local state, so when highlighting elements sometimes need to wait for DOM elements to appear
const _waitForElement = (selector) => {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        observer.disconnect()
        resolve(document.querySelector(selector))
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  })
}

module.exports = {
  defaultSectionsVisible: _defaultSectionsVisible,
  initialStateFor: _initialStateFor,

  // avoid putting data token on every button
  photoTokenFromWrapper: (element) => {
    return _photoTokenFromWrapper(element)
  },

  initialiseModal: (modalVariant, component) => {
    const modalElements = document.querySelectorAll(`#${component.divID}`)

    // setup initial state if it's needed
    const onOpenEnd = component.onModalOpen

    // reset component state when modal is closed
    const onCloseStart = () => {
      if(modalVariant) {
        const initialState = _initialStateFor(modalVariant, component)

        if(initialState) {
          component.setState(initialState)
        }
      }
    }
  
    // initialise method is designed to be run on a collection, but we are always running on a single modal since each modal needs to initialise separately
    // so it can setup it's onClose state setting. Thus just return the first element of the init call, which is the single element we are initialising
    return Materialize.Modal.init(modalElements, { inDuration: 0, outDuration: 0, onOpenEnd, onCloseStart })[0]
  },

  // event and caller args are only needed for opening photo modals
  openModalClickHandler: (modalVariant, ev = null) => {
    const modalElement = (() => {

      if(modalVariant === 'photo') {
        const photoToken = _photoTokenFromWrapper(ev.target)
        
        return document.getElementById(`photo-modal-${photoToken}`)
      }
      else {
        return document.getElementById(modalVariant)
      }
    })()

    const photoModal = Materialize.Modal.getInstance(modalElement)

    photoModal.open()
  },

  highlightCartModalLink:  (photoToken, currentCartPhotoTokens) => {
    if(currentCartPhotoTokens.includes(photoToken)) {
      // don't highlight when it's being removed
      return
    }

    let [siteLinksWrapperSelector, printsListLinkSelector] = (() => {
      // footer sitelinks
      if(window.innerWidth < 992) {
        return ['.footer', '#bottom-prints-list-link']
      }
      else {
        // right sitelinks
        return ['.site-links--right', '#right-prints-list-link']
      }
    })()

    _waitForElement(siteLinksWrapperSelector).then((siteLinksWrapper) => {
      siteLinksWrapper.classList.add('highlight-activity')
    })

    _waitForElement(printsListLinkSelector).then((printsListLink) => {
      printsListLink.classList.add('highlight-activity')

      // printsListLink is always added after the siteLinksWrapper, so once the former is highlighted the timeout can start to remove highlight on both of them simultaneously
      setTimeout(() => {
        document.querySelector(siteLinksWrapperSelector).classList.remove('highlight-activity')
        printsListLink.classList.remove('highlight-activity')
      }, 1500)
    })
  }
}
