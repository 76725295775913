import React, { Fragment, useState } from 'react'

import ApiService from '../../services/ApiService'
import FlashHelper from '../../helpers/FlashHelper'

import Navbar from '../Navbar'
import Flash from '../Flash'
import SectionSeparator from '../SectionSeparator'

import PrintJobTable from './PrintJobTable'

import AdminFooter from '../AdminFooter'
import LoaderActionButton from '../LoaderActionButton'

function PrintJobs() {
  console.log('rendering PrintJobs component')

  const [currentPrintJob, setCurrentPrintJob] = useState(JSON.parse(document.getElementById('last_print_job').value))
  const [completedPrintJobs, setCompletedPrintJobs] = useState(JSON.parse(document.getElementById('completed_print_jobs').value))
  const [cancelledPrintJobs, setCancelledPrintJobs] = useState(JSON.parse(document.getElementById('cancelled_print_jobs').value))

  const [currentFlash, setCurrentFlash] = useState(FlashHelper.loadInitialFlash())

  console.log('currentPrintJob: ', currentPrintJob)
  console.log('completedPrintJobs: ', completedPrintJobs)
  console.log('cancelledPrintJobs: ', cancelledPrintJobs)

  const currentPrintJobPresent = Object.keys(currentPrintJob).length > 0

  return(
    <Fragment>
      <Navbar title='Print Jobs'/>
      <Flash flash={currentFlash} />
  
      <div className='print-Jobs'>
        <SectionSeparator text={currentPrintJobPresent ? `Current (${currentPrintJob.state})` : 'Current'} />

        { currentPrintJobPresent &&
          <PrintJobTable PrintJob={currentPrintJob} />
        }
      </div>

      <AdminFooter currentPage={'print_jobs'} />
    </Fragment>
  )
}

export default PrintJobs