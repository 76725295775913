import React, { useState } from 'react'

import Navbar from '../Navbar'
import SectionSeparator from '../SectionSeparator'
import HeaderVideo from '../HeaderVideo'
import SocialLinksModal from '../modals/SocialLinksModal'
import Footer from '../Footer'

import PageScrollHelper from '../../helpers/PageScrollHelper'
import ActionButton from '../ActionButton'

function Show() {
  const series = JSON.parse(document.getElementById('series_data').value)

  const cloudfrontBaseUrl = document.getElementById('photos_cloudfront_url').value

  const hasBRollPhotos = series.b_roll_photos && series.b_roll_photos.length > 0

  const [bRollPhotosShowing, setBRollPhotosShowing] = useState(false)

  const toggleBRollPhotosShowing = () => {
    setBRollPhotosShowing(!bRollPhotosShowing)
  }

  // TODO: move to helper
  const photoUrl = (filename) => {
    return `${cloudfrontBaseUrl}/${filename}`
  }

  const renderJumpToButtons = series.body_text.length >= 1000

  return(
    <React.Fragment>
      <Navbar title='keenedreams'/>
      
      <SectionSeparator text={series.title} />

      { series.video_embed_url &&
        <div className='about__section'>
          <HeaderVideo
            videoEmbedUrl={series.video_embed_url}
            title={series.title}
          />
        </div>
      }

      <div className='series container'>
        <h5 className='series__subtitle'>
          {series.subtitle}
        </h5>
        
        <p className='series__place'>
          {series.place}
        </p>

        { renderJumpToButtons &&
          <p className='series__scroll-to-photos'>
            <a onClick={(_e) => { PageScrollHelper.scrollToElement('series-photos')}}>
              Jump to Photos
            </a>
          </p>
        }

        <hr />

        {
          series.paragraphs.map((paragraph, index) => {
            return(
              <p key={`series-${series.slug}-paragraph-${index}`}>
                {paragraph}
              </p>
            )
          })
        }

        { series.photos.length > 0 && renderJumpToButtons &&
          <React.Fragment>
            <p>
              <a onClick={(_e) => { PageScrollHelper.scrollToTop({ behavior: 'instant', timeout: 0 }) }}>
                Back to Top
              </a>
            </p>

          </React.Fragment>
        }

        { series.photos.length > 0 &&
          <hr />
        }

        { series.photos.length > 0 &&
          <div className='photos-container' id='series-photos'>
            <div className='purple-small-text'>
              Prints in a series have fewer editions available than other photos on the site (just 5 for each size)
            </div>

            {
              series.photos.map((photo) => {
                return(
                  <div className='photo photo__wrapper' key={`series-${series.slug}-photo-${photo.token}`}>
                    <a href={`/photos/${photo.token}`}>
                      <img
                        className={'photo-box-shadow'}
                        src={photoUrl(photo.public_bucket_filename)}
                      />
                    </a>
                  </div>
                )
              })
            }
          </div>
        }

        { hasBRollPhotos &&
          <div className='series__toggle-b-roll'>
            <ActionButton
              text={`${bRollPhotosShowing ? 'Hide' : 'Show'} B-roll photos`}
              title={'Toggle series B-roll photos'}
              isShowing={true}
              clickHandler={toggleBRollPhotosShowing}
              faIconClass={'fa-film'}
            />
          </div>
        }

        { hasBRollPhotos && bRollPhotosShowing &&
          <div className='photos-container series__b-roll' id='series-b-roll-photos'>
            <div className='purple-small-text'>
              Photos that didn't quite make the cut, are unavailable as prints, or are just memories that I want to put somewhere
            </div>

            {
              series.b_roll_photos.map((photo) => {
                return(
                  <div className='photo photo__wrapper' key={`series-${series.slug}-photo-${photo.token}`}>
                    <a href={`/photos/${photo.token}`}>
                      <img
                        className={'photo-box-shadow'}
                        src={photoUrl(photo.public_bucket_filename)}
                      />
                    </a>
                  </div>
                )
              })
            }
          </div>
        }
      </div>

      <SocialLinksModal />

      <Footer currentPage={'series-show'} />
    </React.Fragment>
  )
}

export default Show
