import React, { Fragment, useState } from 'react'

import ApiService from '../../services/ApiService'
import FlashHelper from '../../helpers/FlashHelper'

import Navbar from '../Navbar'
import Flash from '../Flash'
import SectionSeparator from '../SectionSeparator'
import OrdersTable from './OrdersTable'
import AdminFooter from '../AdminFooter'
import LoaderActionButton from '../LoaderActionButton'

function Orders() {
  const [currentPrintJob, setCurrentPrintJob] = useState(JSON.parse(document.getElementById('last_print_job').value))
  const [ordersByState, setOrdersByState] = useState(JSON.parse(document.getElementById('orders_by_state').value))
  const [currentFlash, setCurrentFlash] = useState(FlashHelper.loadInitialFlash())

  console.log(`currentPrintJob: `, currentPrintJob)

  const [isPerformingAction, setIsPerformingAction] = useState(false)

  const setFlash = (flash) => {
    setCurrentFlash(flash)
    window.setTimeout(() => {
      setCurrentFlash(null)
    }, 3000)
  }

  const handleStateTransitionApiResponse = (response, actionText) => {
    setIsPerformingAction(false)

    console.log('handling response. response.success => ', response.success)
    console.log('response.data => ', response.data)

    if(response.success && Object.keys(response.data).length > 0) {

      // this is only returned when performing action on the print job
      if(response.data['last_print_job']) {
        const updatedCurrentPrintJob = response.data['last_print_job']
        console.log('setting currentPrintJob to: ', updatedCurrentPrintJob)
        setCurrentPrintJob(updatedCurrentPrintJob)
      }
      
      // always returned (whether performing action on print job or bullk-action on group of orders)
      const updatedOrdersByState = response.data['orders_by_state']

      setOrdersByState(updatedOrdersByState)

      let flashMessage = (() => {
        if(Object.keys(response.data) && response.data['flash_message']) {
          return response.data['flash_message']
        }
        else {
          return `Success ${actionText}`
        }
      })()
      
      setFlash({ type: 'notice', message: flashMessage })
    }
    else {
      setFlash({ type: 'error', message: `Error ${actionText}` })
    }
  }

  const createPrintJob = () => {
    setIsPerformingAction(true)
    const orderTokens = ordersByState['PAID'].map((order) => order.token)

    ApiService.createPrintJob(orderTokens)
      .then((response) => {
        handleStateTransitionApiResponse(response, 'creating Print Job')
      })
  }
  
  return(
    <Fragment>
      <Navbar title='Admin Orders'/>
      <Flash flash={currentFlash} />

      <div className='admin-orders'>
        { Object.keys(ordersByState['NEW']).length > 0 &&
          <Fragment>
            <SectionSeparator text={'New'} />

            <div className='container'>
              <OrdersTable
                ordersState={'NEW'}
                orders={ordersByState['NEW']}
              />
            </div>
          </Fragment>
        }
        { Object.keys(ordersByState['CONFIRMED']).length > 0 &&
          <Fragment>
            <SectionSeparator text={'Confirmed'} />

            <div className='container'>
              <OrdersTable
                ordersState={'CONFIRMED'}
                orders={ordersByState['CONFIRMED']}
              />
            </div>
          </Fragment>
        }
        
        { Object.keys(ordersByState['PAID']).length > 0 &&
          <Fragment>
            <SectionSeparator text={'Paid'} />

            <div className='container'>
              { ordersByState['PAID'].length > 0 &&
                <LoaderActionButton
                  text={'Create Print Job'}
                  secondaryClassName={'padding-left padding-right'}
                  faIconClass={'fa-truck-ramp-box'}
                  isLoading={isPerformingAction}
                  onClick={createPrintJob}
                  confirmationText={'Create a new Print Job with these Orders?'}
                />
              }
              <OrdersTable
                ordersState={'PAID'}
                orders={ordersByState['PAID']}
              />
            </div>
          </Fragment>
        }


        { Object.keys(ordersByState['AWAITING_PRINT']).length > 0 &&
          <Fragment>
            <SectionSeparator text={'Awaiting Print'} />

            <div className='container'>
              <OrdersTable
                ordersState={'AWAITING_PRINT'}
                orders={ordersByState['AWAITING_PRINT']}
              />
            </div>            
          </Fragment>
        }

        { Object.keys(ordersByState['COMPLETED']).length > 0 &&
          <Fragment>
            <SectionSeparator text={'Completed'} />

            <div className='container'>
              <OrdersTable
                ordersState={'COMPLETED'}
                orders={ordersByState['COMPLETED']}
              />
            </div>
          </Fragment>
        }

        { Object.keys(ordersByState['SHIPPED']).length > 0 &&
          <Fragment>
            <SectionSeparator text={'Shipped'} />

            <div className='container'>
              <OrdersTable
                ordersState={'SHIPPED'}
                orders={ordersByState['SHIPPED']}
              />
            </div>
          </Fragment>
        }

        { Object.keys(ordersByState['CANCELLED']).length > 0 &&
          <Fragment>            
            <SectionSeparator text={'Cancelled'} />

            <div className='container'>
              <OrdersTable
                ordersState={'CANCELLED'}
                orders={ordersByState['CANCELLED']}
              />
            </div>
          
          </Fragment>
        }
     
      </div>

      <AdminFooter currentPage={'orders'} />
    </Fragment>
  )
}

export default Orders
