import React from 'react';

class PhotoCountDifference extends React.Component {
  render() {
    // calculate how many photos needed to hide/show to have balance
    let calculateTarget = (increment) => {
      let target = this.props.showingCount;
      
      while(target % 3 !== 0 || target % 2 !== 0) {
        target = increment ? target + 1 : target - 1;
      }

      return target;
    }

    let targetCount = calculateTarget(this.props.increment);

    let difference = this.props.increment ?
      targetCount - this.props.showingCount : this.props.showingCount - targetCount;

    // there will always be some photos for a group
    if(targetCount === 0 || targetCount % 3 !== 0 || targetCount % 2 !== 0) {
      return null;
    }
    else {
      return(
        <div className='admin__aspect-group-metrics__difference-icon'>
          {difference} <i className={`fa-solid ${this.props.increment ? 'fa-arrow-up' : 'fa-arrow-down'}`}></i> to have {targetCount}
        </div>
      );
    }
    }
}

export default PhotoCountDifference;
