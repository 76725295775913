import React, { Fragment, useState } from 'react'

import ApiService from '../../services/ApiService'
import FlashHelper from '../../helpers/FlashHelper'

import Navbar from '../Navbar'
import Flash from '../Flash'

import PrintJobTable from './PrintJobTable'

import AdminFooter from '../AdminFooter'

function PrintJob() {
  const lastPrintJobToken = document.getElementById('last_print_job').value
  const [printJob, setPrintJob] = useState(JSON.parse(document.getElementById('print_job').value))

  const isLastPrintJob = printJob['token'] == lastPrintJobToken

  const [currentFlash, setCurrentFlash] = useState(FlashHelper.loadInitialFlash())

  const setFlash = (flash) => {
    setCurrentFlash(flash)
    window.setTimeout(() => {
      setCurrentFlash(null)
    }, 3000)
  }

  if(currentFlash) {
    window.setTimeout(() => {
      setCurrentFlash(null)
    }, 3000)
  }

  const PrintJobMissing = !printJob['token']

  const resourceName = document.getElementById('resource_name').value
  const isAdmin = resourceName === 'admin'

  return(
    <Fragment>
      <Navbar title={`${isLastPrintJob ? 'Last ' : ''}Print Job`}/>
      <Flash flash={currentFlash} />


      <div className='print-job'>

        {!PrintJobMissing &&
          <PrintJobTable
            printJob={printJob}
            resourceName={resourceName}
            setPrintJob={setPrintJob}
            setFlash={setFlash}
          />
        }
        {PrintJobMissing &&
          <div className='print-job__no-content'>
            <div className='container'>
              { isAdmin &&
                <p>No current Print Job</p>
              }
              { !isAdmin &&
                <p>No Print Job just at the moment. You'll get an email when the next one is ready.</p>
              }
            </div>
          </div>
        }
      </div>

      <AdminFooter
        currentPage={isLastPrintJob ? 'last_print_job' : 'print_job'}
        resourcename={resourceName}
      />
    </Fragment>
  )
}

export default PrintJob