import React from 'react'
import ApiService from '../../services/ApiService'

function Footer(props) {
  const affiliateEmail = JSON.parse(document.getElementById('affiliate').value)['email']

  const signOut = () => {
    const headers = ApiService.loadRequestHeaders()

    fetch('/affiliates/sign_out', { method: 'DELETE', headers })
      .then((response) => {
        if(response.ok) {
          console.log("you've signed out, redirecting to affiliates login")
          window.location = '/affiliates/sign_in'
        }
        else {
          console.error("dang! something went wrong while trying to sign you out")
        }
      })
  }

  return(
    <footer className={`footer footer--affiliate`}>
      <div className='container'>
        <div className='footer__site-links footer__site-links--affiliate'>
          <div className='site-links--bottom__element'>
            <p className='resource-email'>
              {affiliateEmail}
            </p>
          </div>
          { props.currentPage && props.currentPage === 'order' &&
            <div className='site-links--bottom__element site-links--bottom__element--centered'>
              <a href='/affiliate/home'>Home</a>
            </div>
          }
          <div className='site-links--bottom__element'>
            <a onClick={signOut}>
              Sign out
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
