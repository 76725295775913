import React from 'react'

class Crypto extends React.Component {
  render() {
    return(
      <div>
        Give me Crypto
      </div> 
    )
  }
}

export default Crypto;