const mappings = {
  'name': 'Name',
  'address_line_1': 'Address line 1',
  'address_line_2': 'Address line 2',
  'city': 'City',
  'state': 'State / Region',
  'zip_or_postal_code': 'Zip / Postcode',
  'country': 'Country',
  'phone_number': 'Phone number',
  'extra_delivery_instructions': 'Extra delivery instructions'
}

const nonRequiredFields = [
  'address_line_2',
  'extra_delivery_instructions'
]

const _requiredFields = () => {
  return Object.keys(mappings).filter((addressKey) => {
    return !nonRequiredFields.includes(addressKey)
  })
}

export default {
  fieldKeyToName: (fieldKey) => {
    return mappings[fieldKey]
  },

  fields: () => {
    return Object.keys(mappings)
  },

  requiredFields: _requiredFields,

  addressIsValid: (address) => {
    const requiredFields = _requiredFields()

    const presentRequiredFields = Object.keys(address)
      .filter((addressField) => requiredFields.includes(addressField))
      .filter((addressField) => address[addressField] && address[addressField].length)

    return presentRequiredFields.length === requiredFields.length
  }
}
