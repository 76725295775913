import React from 'react'
import CryptoHelper from '../../helpers/CryptoHelper'
import ModalHelper from '../../helpers/ModalHelper'
import CryptoAddressModal from '../modals/CryptoAddressModal'

function PaymentStepCryptoOption(props) {
  const copyAddress = () => {
    const address = document.getElementById(`${props.crypto}-address`).value
    const copiedNotifier = document.getElementById(`${props.crypto}-address-copied-notice`)

    navigator.clipboard.writeText(address)

    copiedNotifier.style.display = 'block'

    window.setTimeout(() => {
      copiedNotifier.style.display = 'none'
    }, 3000)
  }

  const metadata = CryptoHelper.metadata(props.crypto)
  const address = metadata['address']

  return(
    <div className='payment-step-crypto-option'>
      <h5>{`${metadata['name']} (${metadata['symbol']})`}</h5>
      <div className='payment-step-crypto-option__current-price'>
        <p>
          <span>{props.isConfirmed ? 'LOCKED PRICE:' : 'CURRENT PRICE:'}</span>
          <span>{(props.fetchingCryptoPriceData || !props.cryptoPriceData) ? '...' : `€${props.cryptoPriceData['crypto_token_price_usd']}`}</span>
        </p>
        { !props.isConfirmed && !props.isPaid &&
          <div>
            <i className={`fa fa-solid fa-arrow-rotate-right fa-lg ${props.fetchingCryptoPriceData ? 'fa-spin' : 'orange-hover-transition'}`} title='refresh' onClick={() => { props.fetchCryptoPriceData(props.crypto) }}></i>
          </div>
        }
      </div>
      <p>
        {`Deposit the equivalent ${metadata['symbol']} to this wallet address`}
        <br />
        <a className='orange-hover-transition' onClick={(_e) => { ModalHelper.openModalClickHandler(`${props.crypto}-crypto-address-modal`) }}>
          QR code
        </a>
      </p>
      { props.isConfirmed &&
        <p>
          {`This ${metadata['symbol']} price was locked in when you confirmed your Order. You'll need to transfer exactly this amount for the Order to be marked as PAID`}
        </p>
      }
      { !props.isConfirmed && !props.isPaid &&
        <p>
          <b>Important:</b>&nbsp;
          {`Once you confirm your Order, the price in ${metadata['symbol']} will be locked in - so you'll need to transfer exactly this amount for the Order to be marked as PAID`}
        </p>
      }
      { props.isPaid &&
        <p>
          {`Thank you! ${metadata['symbol']} payment received.`}
        </p>
      }

      <div className='crypto-address-input'>
        <div className='input-field'>
          <input disabled type='text' id={`${props.crypto}-address`} value={address}></input>
          <label className='active' htmlFor={`${props.crypto}-address`}>
            { `Address (${metadata['network']})` }
          </label>
        </div>

        <i onClick={copyAddress} className="fa fa-solid fa-clipboard"></i>
      </div>

      <p className='copied-to-clipboard' id={`${props.crypto}-address-copied-notice`}>
        Copied
      </p>
        
      <CryptoAddressModal
        crypto={props.crypto}
        metadata={metadata}
      />
    </div>
  )
}

export default PaymentStepCryptoOption
