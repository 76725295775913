import React from 'react'
import LoadingSpinner from './LoadingSpinner'
import PhotosHelper from '../helpers/PhotosHelper'
import PrintSizeSelector from './PrintSizeSelector'
import PrintsListContext from './contexts/PrintsListContext'

class CartPhoto extends React.Component {

  static contextType = PrintsListContext;

  constructor(props) {
    super(props);

    this.photoToken = this.props.photo.token

    this.state = {
      isLoaded: false
    }
  }

  setImageLoaded = () => {
    this.setState({ isLoaded: true }, () => {
      if(this.props.onImageLoaded) {
        this.props.onImageLoaded(this.photoToken)
      }

      // const wrapper = document.getElementById(`cart-photo-${this.photoToken}-wrapper`)
      // const img = document.getElementById(`cart-photo-${this.photoToken}`)
      // if(wrapper && img) {
      //   // wrapper.setAttribute('style', `height:${img.height}px;`)
      // }
    })
  }

  selectedSize = () => {
    if(!this.state.isLoaded) {
      return
    }

    if(this.context && this.context.printSizings) {
      // cart context before Order is created (printsList)
      return this.context.printSizings[this.photoToken]
    }
    else {
      // completing context when Order has already been created (rendering in ReviewOrderPhotos)
      const photo = this.props.photo

      if(photo.print_sizing && photo.print_sizing.size) {
        return photo.print_sizing.size
      }
    }
  }

  selectPrintSize = (size) => {
    if(this.props.selectPrintSize) {
      this.props.selectPrintSize(this.photoToken, size)
    }
    else {
      this.context.selectPrintSize(this.photoToken, size)
    }
  }

  render() {
    const photo = this.props.photo
    const isLoaded = this.state.isLoaded
    const url = photo.url || PhotosHelper.photoCloudfrontUrl(photo.basename, photo.aspect_group)
    const loadingPaddingPercentage = PhotosHelper.loadingPhotoSpinnerWrapperPaddingPercentage(photo.aspect_group)

    return(
      <div key={`cart-photo-${photo.token}`} className={`cart-photos__photo ${isLoaded ? 'cart-photos__photo--with-border' : ''}`}>
        <div id={`cart-photo-${photo.token}-wrapper`}>
          { !isLoaded &&
            <div className='photo__loading-spinner' style={{ paddingTop: `${loadingPaddingPercentage}%`, paddingBottom: `${loadingPaddingPercentage}%` }}>
              <div className='photo__loading-spinner__icon-container'>
                <LoadingSpinner
                  isShowing={true}
                  size={'big'}
                  colour={'blue'}
                />
              </div>
            </div>
          }

          { isLoaded &&
            <p className='cart-photos__photo__name italic-text'>
              {photo.name}
            </p>
          }

          <img
            className={'photo-box-shadow'}
            id={`cart-photo-${photo.token}`}
            onLoad={this.setImageLoaded}
            src={url}
            draggable='false'
            style={{ display: isLoaded ? 'block' : 'none' }}
          />

          { isLoaded && this.props.removeFromCart && !this.props.optionsDisabled &&
            <div title='Remove from list' className='button button--round' onClick={() => { this.props.removeFromCart(photo.token) }}>
              <i className="fa fa-solid fa-trash"></i>
            </div>
          }
        </div>
        { isLoaded &&
          <PrintSizeSelector
            photo={photo}
            optionsDisabled={this.props.optionsDisabled}
            selectedSize={this.selectedSize()}
            selectSize={this.selectPrintSize}
            renderLabel={true}
          />
        }
      </div>
    )
  }
}

export default CartPhoto