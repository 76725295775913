import React from 'react';
import ModalCloseIcon from './ModalCloseIcon';
import ModalHelper from '../../helpers/ModalHelper'
import AddressPreview from '../forms/AddressPreview';

class PrintJobOrderAddressModal extends React.Component {
  constructor(props) {
    super(props)

    this.divID = `print-job-order-${props.order.token}-address-modal`
  }

  componentDidMount() {
    this.modalInstance = ModalHelper.initialiseModal(this.divID, this)
  }

  closeModal = () => {
    this.modalInstance.close()
  }

  copyAddressFields = () => {
    const elements = document.getElementById(this.divID).querySelectorAll('.address-field-value')
    const copiedNotifier = document.getElementById(this.divID).querySelector('#copied-to-clipboard-notice')

    let fields = []

    elements.forEach((el) => fields.push(`${el.innerText}`))

    const fieldsString = fields.join('\t')

    navigator.clipboard.writeText(fieldsString)

    copiedNotifier.style.display = 'block'

    window.setTimeout(() => {
      copiedNotifier.style.display = 'none'
    }, 3000)
  }

  render() {
    return(
      <div id={this.divID} className='modal modal--print-job-order-address'>
        <div className='modal-content'>
          <ModalCloseIcon
            closeModal={this.closeModal}
            modalID={this.divID}
          />

          <h5>Order Address</h5>

          <div className='modal--print-job-order-address__copy-address-fields'>
            <a onClick={this.copyAddressFields} className='button button--action'>
              <span>
                Copy Address Fields
              </span>
              <i className='fa-regular fa-copy'></i>
            </a>
            <p className='copied-to-clipboard' id='copied-to-clipboard-notice'>
              Copied
            </p>
          </div>

          <AddressPreview
            hideHeader={true}
            address={this.props.order.address}
            rawCountryCode={true}
          />
        </div>
      </div>
    )
  }
}

export default PrintJobOrderAddressModal
