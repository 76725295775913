import React from 'react';

class PhotoTechnicalDetails extends React.Component {
  render() {
    const metadata = this.props.metadata;

    if(this.props.isShowing) {
      const exifApertureComponents = metadata['exif:ApertureValue'].split('/').map((el) => Number(el));
      const exifApertureValue = (exifApertureComponents[0] / exifApertureComponents[1]);
      const trueAperture = Math.pow(2, exifApertureValue / 2).toFixed(2)

      return(
        <table className='photo-technical-details-table'>
          <tbody>
            <tr>
              <td>Camera:</td>
              <td>{`${metadata['exif:Make']} ${metadata['exif:Model']}`}</td>
            </tr>
            <tr>
              <td>Lens:</td>
              <td>{`${metadata['exif:LensMake']} ${metadata['exif:LensModel']}`}</td>
            </tr>
            <tr>
              <td>35mm equivalent focal length:</td>
              <td>{metadata['exif:FocalLengthIn35mmFilm']}mm</td>
            </tr>
            <tr>
              <td>Aperture:</td>
              <td>{trueAperture}</td>
            </tr>
            <tr>
              <td>Shutter speed:</td>
              <td>{metadata['exif:ExposureTime']}</td>
            </tr>
          </tbody>
        </table>
      )
    }
    else {
      return null;
    }
  }
}

export default PhotoTechnicalDetails;
