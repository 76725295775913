import React from 'react';
import ModalCloseIcon from '../../modals/ModalCloseIcon'
import ModalHelper from '../../../helpers/ModalHelper'
import LoaderActionButton from '../../LoaderActionButton'
import RequiredFieldLabel from '../../forms/RequiredFieldLabel'
import ApiService from '../../../services/ApiService'

// create or update
class SeriesFormModal extends React.Component {
  constructor(props) {
    super(props)

    this.divID = props.modalID

    this.series = props.series 

    this.isPersisted = !['', null, undefined].includes(this.series['token'])

    const enabled = (() => {
      if(this.isPersisted) {
        return [true, 'true'].includes(this.series['enabled']) ? true : false
      }
      else {
        return false
      }
    })()
    
    this.state = {
      isRunningApiRequest: false,
      apiRequestError: false,
      inputVals: {
        slug: this.series['slug'] || '',
        title: this.series['title'] || '',
        subtitle: this.series['subtitle'] || '',
        place: this.series['place'] || '',
        body_text: this.series['body_text'] || '',
        video_embed_url: this.series['video_embed_url'] || '',
        enabled
      }
    }
  }

  componentDidMount() {
    this.modalInstance = ModalHelper.initialiseModal(this.divID, this)
  }

  closeModal = () => {
    this.modalInstance.close()
  }

  handleApiResponse = (response) => {
    if(response.success) {
      const updateStateArgs = {
        apiRequestSuccess: true,
        isRunningApiRequest: false
      }

      this.setState(
        updateStateArgs,
        () => {
          this.props.createOrUpdateCallback(response.data['series'], response.data['flash'])
          this.closeModal()
        }
      )
    }
    else {
      this.setState({
        isRunningApiRequest: false,
        apiRequestError: true
      })
    }
  }

  handleInputValueChange = (e) => {
    const target = e.target.name

    const value = (() => {
      if(target === 'enabled') {
        // toggling boolean
        return !this.state.inputVals['enabled']
      }
      else {
        return e.target.value
      }
    })()

    const updatedInputVals = Object.assign(this.state.inputVals, { [target]: value })

    this.setState({ inputVals: updatedInputVals })
  }

  submitForm = (event) => {
    const series = this.state.inputVals
    const requestParams = { series }

    // don't try to submit, and allow the HTML form validations to run
    if(['', null].includes(series.slug) || ['', null].includes(series.title) || ['', null].includes(series.subtitle) || ['', null].includes(series.body_text)) {
      return
    }

    event.stopPropagation()
    event.preventDefault()

    const runApiRequestCallback = (() => {
      // update
      if(this.isPersisted) {
        return(
          () => {
            ApiService.updateSeries(this.series['token'], requestParams)
              .then((response) => {
                this.handleApiResponse(response)
              })
          }
        )
        
      }
      // create
      else {
        return(
          () => {
            ApiService.createSeries(requestParams)
              .then((response) => {
                this.handleApiResponse(response)
              })
          }
        )
      }
    })()

    this.setState(
      {
        isRunningApiRequest: true,
        apiRequestError: false
      },
      runApiRequestCallback
    )
  }

  render() {
    return(
      <div id={this.divID} className='modal modal--series-form'>
        <div className='modal-content'>
          <ModalCloseIcon
            closeModal={this.closeModal}
            modalID={this.divID}
          />

          <h5>{`${this.isPersisted ? 'Update' : 'Create' } Series`}</h5>

          <div className='modal-form'>
            <form onSubmit={(e) => { e.preventDefault() }} className="col s12">
              <div className="input-field col s12">
                <RequiredFieldLabel
                  name={'Slug'}
                  htmlFor={`${this.divID}-form-slug-input`}
                  forceActive={this.state.inputVals['slug'] !== ''}
                />
                <input required name='slug' type='text' autoComplete='off' value={this.state.inputVals['slug']} onChange={this.handleInputValueChange} id={`${this.divID}-form-slug-input`} className='modal-form__input'></input>
              </div>

              <div className="input-field col s12">
                <RequiredFieldLabel
                  name={'Title'}
                  htmlFor={`${this.divID}-form-title-input`}
                  forceActive={this.state.inputVals['title'] !== ''}
                />
                <input required name='title' type='text' autoComplete='off' value={this.state.inputVals['title']} onChange={this.handleInputValueChange} id={`${this.divID}-form-title-input`} className='modal-form__input'></input>
              </div>

              <div className="input-field col s12">
                <RequiredFieldLabel
                  name={'Subtitle'}
                  htmlFor={`${this.divID}-form-subtitle-input`}
                  forceActive={this.state.inputVals['subtitle'] !== ''}
                />
                <input required name='subtitle' type='text' autoComplete='off' value={this.state.inputVals['subtitle']} onChange={this.handleInputValueChange} id={`${this.divID}-form-subtitle-input`} className='modal-form__input'></input>
              </div>

              <div className="input-field col s12">
                <RequiredFieldLabel
                  name={'Place'}
                  htmlFor={`${this.divID}-form-place-input`}
                  forceActive={this.state.inputVals['place'] !== ''}
                />
                <input required name='place' type='text' autoComplete='off' value={this.state.inputVals['place']} onChange={this.handleInputValueChange} id={`${this.divID}-form-place-input`} className='modal-form__input'></input>
              </div>

              <div className="input-field col s12">
                <RequiredFieldLabel
                  name={'Body Text'}
                  htmlFor={`${this.divID}-form-body-text-input`}
                  forceActive={this.state.inputVals['body_text'] !== ''}
                />
                <textarea required name='body_text' value={this.state.inputVals['body_text']} onChange={this.handleInputValueChange} id={`${this.divID}-form-body-text-input`} className="modal-form__input materialize-textarea"></textarea>
              </div>

              <div className="input-field col s12">
                <label className={this.state.inputVals['video_embed_url'] == '' ? '' : 'active'} htmlFor={`${this.divID}-form-video-url-input`}>
                  Video embed URL
                </label>
                <input name='video_embed_url' type='text' autoComplete='off' value={this.state.inputVals['video_embed_url']} onChange={this.handleInputValueChange} id={`${this.divID}-form-video-url-input`} className='modal-form__input'></input>
              </div>

              <div className="input-field col s12">
                <p>
                  <label className='checkbox-label'>
                    <input type="checkbox" name='enabled' className="modal-form__input filled-in" checked={this.state.inputVals['enabled']} onChange={this.handleInputValueChange} id={`${this.divID}-form-enabled-input`} />
                    <span>Enabled</span>
                  </label>
                </p>
              </div>

              <div className='modal-form__submit'>
                { !this.state.apiRequestError &&
                  <LoaderActionButton
                    text={`${this.isPersisted ? 'Update' : 'Add' } Series`}
                    buttonType={'submit'}
                    faIconClass={this.isPersisted ? 'fa-check' : 'fa-plus'}
                    secondaryClassName={'padding-left padding-right'}
                    isLoading={this.state.isRunningApiRequest}
                    onClick={this.submitForm}
                  />
                }

                { this.state.apiRequestError &&
                  <p>
                    Sorry! something went wrong &nbsp;
                    <a onClick={this.submitForm}>
                      try again
                    </a>
                  </p>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default SeriesFormModal
