import React, { useState } from 'react'

import Navbar from '../../Navbar'
import Flash from '../../Flash'
import SeriesFormModal from '../modals/SeriesFormModal'
import ActionButton from '../../ActionButton'
import SeriesCard from './Card'
import AdminFooter from '../../AdminFooter'

import ApiService from '../../../services/ApiService'
import ModalHelper from '../../../helpers/ModalHelper'
import FlashHelper from '../../../helpers/FlashHelper'

function Index() {
  const rawSeriesData = JSON.parse(document.getElementById('series_data').value)

  const [seriesData, setSeriesData] = useState(rawSeriesData)
  const [currentFlash, setCurrentFlash] = useState(FlashHelper.loadInitialFlash())

  const setFlash = (flash) => {
    setCurrentFlash(flash)
    window.setTimeout(() => {
      setCurrentFlash(null)
    }, 3000)
  }

  const handleSeriesModalFormSubmission = (series, flashMessage) => {
    // only success flash is serialised
    setFlash({ type: 'notice', message: flashMessage })

    const toUpdate = seriesData.find((s) => s.token == series.token)

    const updatedSeriesData = (() => {
      let dataToUpdate = seriesData

      if(toUpdate) {
        const seriesIndex = seriesData.indexOf(toUpdate)

        dataToUpdate.splice(seriesIndex, 1, series)
      }
      else {
        // creating
        dataToUpdate.push(series)
      }

      return dataToUpdate
    })()

    // just re-serialise all series whether creating or updating
    setSeriesData(updatedSeriesData)
  }

  const deleteButtonClickHandler = (seriesToken) => {
    ApiService.destroySeries(seriesToken)
      .then((response) => {
        const responseData = response.data

        if(responseData.success) {
          const toRemove = seriesData.find((s) => s.token == seriesToken)

          let dataToUpdate = seriesData
      
          const seriesIndex = seriesData.indexOf(toRemove)
      
          dataToUpdate.splice(seriesIndex, 1)
      
          setSeriesData(dataToUpdate)
          setFlash({ type: 'notice', message: responseData.flash })
        }
        else {
          setFlash({ type: 'error', message: responseData.flash })
        }
      })
  }

  return(
    <React.Fragment>
      <Navbar title='keenedreams'/>
      <Flash flash={currentFlash} />

      <div className='series-index'>
        <div className='series-index__new-series-link'>
          <SeriesFormModal
            series={{}}
            modalID={'new-series-form-modal'}
            createOrUpdateCallback={handleSeriesModalFormSubmission}
          />

          <ActionButton
            isShowing={true}
            text={'New Series'}
            faIconClass={'fa-plus'}
            secondaryClassName={'button--fullsize-round'}
            clickHandler={() => { ModalHelper.openModalClickHandler('new-series-form-modal') }}
          />
        </div>

        {
          seriesData.map((series) => {
            return(
              <div className='series-index__admin-card' key={series.slug}>
                <SeriesFormModal
                  series={series}
                  modalID={`edit-series-${series.token}-form-modal`}
                  createOrUpdateCallback={handleSeriesModalFormSubmission}
                />

                <SeriesCard
                  seriesData={series}
                  deleteButtonClickHandler={deleteButtonClickHandler}
                />
              </div>
            )
          })
        }
      </div>

      <AdminFooter currentPage={'series-index'} />
    </React.Fragment>
  )
}

export default Index
