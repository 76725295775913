
export default {
  // remove the param from URL
  clearUrlParam: (urlParams, key) => {
    urlParams.delete(key)
    const newURL = window.location.pathname + '?' + urlParams.toString()
    history.replaceState({}, document.title, newURL)
  },

  loadParams: () => {
    return new URLSearchParams(window.location.search)
  }
}
