import React, { useState } from 'react'

import StringHelper from '../../helpers/StringHelper'
import ModalHelper from '../../helpers/ModalHelper'
import FlashHelper from '../../helpers/FlashHelper'

import Navbar from '../Navbar'
import Flash from '../Flash'
import SectionSeparator from '../SectionSeparator'
import OrderStatusTable from './OrderStatusTable'
import CostsReview from './CostsReview'
import AddressPreview from '../forms/AddressPreview'
import ReviewOrderPhotos from './ReviewOrderPhotos'
import LoaderActionButton from '../LoaderActionButton'
import SocialLinksModal from '../modals/SocialLinksModal'
import Footer from '../Footer'
import CancelOrderModal from '../modals/CancelOrderModal'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'

function Show() {
  const order = JSON.parse(document.getElementById('order_data').value)
  const unparsedPhotoDataElements = order['photos_json']
  const photos = unparsedPhotoDataElements.map((photoDataElement) => JSON.parse(photoDataElement))

  const [currentFlash, setCurrentFlash] = useState(FlashHelper.loadInitialFlash())

  const pluraliseCollectionName = (collectionName) => {
    return StringHelper.pluraliseCollectionName(photos, collectionName)
  }

  // may have been coming straight from processing card payment, in which case the cart would not have been cleared
  if(LocalStorageHelper.currentOrderToken() === order.token) {
    LocalStorageHelper.clearCurrentCart()
  }

  if(currentFlash) {
    window.setTimeout(() => {
      setCurrentFlash(null)
    }, 3000)
  }

  return(
    <React.Fragment>
      <Navbar title='keenedreams'/>
      <Flash flash={currentFlash} />
      
      <div className='order-show'>
        <SectionSeparator text={`Order ${order.token}`}/>

        <div className='order-show__section'>
          <OrderStatusTable order={order} />
        </div>

        <div className='padding-bottom'>
          { order.is_updatable &&
            <div className='order-show__section'>
              <LoaderActionButton
                text={`${order.is_paid && order.delivery_method === 'delivery' ? 'Update delivery Address' : (order.is_paid ? 'Make changes to my Order' : 'Go to Order Payment')}`}
                href={`/orders/${order.token}/checkout`}
                faIconClass={'fa-arrow-right'}
                secondaryClassName={'order-show__link-or-action-button'}
              />
            </div>
          }

          { order.is_cancellable &&
            <div className='order-show__section'>  
              <React.Fragment>
                <CancelOrderModal
                  orderToken={order.token}
                  isPaid={order.is_paid}
                  paymentMethod={order.payment_method}
                  deliveryMethod={order.delivery_method}
                  stripePaymentDetails={order['stripe_payment_details']}
                />

                <a onClick={(_e) => { ModalHelper.openModalClickHandler(`cancel-order-${order.token}-modal`) }} className={`button button--action margin-bottom-mobile margin-top`}>
                  <div className='contents-wrapper'>
                    <span>Cancel my Order</span>
                    <i className='fa fa-solid fa-trash-can'></i>
                  </div>
                </a>
              </React.Fragment>
            </div>
          }

          { order.is_cancelled && false &&
            <div className='order-show__section'>  
              <a onClick={(e) => { console.log('TODO reorder') }} className='order-show__link-or-action-button button button--action'>
                <div className='contents-wrapper'>
                  <span>Order again</span>
                  <i className='fa fa-solid fa-cart-shopping'></i>
                </div>
              </a>
            </div>
          }
        </div>

        <React.Fragment>
          <SectionSeparator text={'Order Costs'}/>
          <div className='order-show__section'>

            <div className='review-order-photos'>
              <CostsReview
                isPaid={order.is_paid}
                discountCodeMetadata={order.discount_code_metadata}
                deliveryMethod={order.delivery_method}
                pluraliseCollectionName={pluraliseCollectionName}
                printsCost={order.prints_cost}
                totalCost={order.total_cost}
                shippingCost={order.shipping_cost}
                // cryptoPriceData={order.crypto_payment_details}
              />
            </div>
          </div>
        </React.Fragment>

        { !order.is_cancelled && order.delivery_method === 'delivery' && Object.keys(order.address).length > 0 &&
          <React.Fragment>
            <SectionSeparator text={'Delivery Address'}/>
            
            <div className='order-show__section'>
              <AddressPreview
                hideHeader={true}
                address={order.address}
              />
            </div>
          </React.Fragment>
        }

        { !order.is_cancelled &&
          <React.Fragment>
            <SectionSeparator text={`Order ${pluraliseCollectionName('Photo')}`}/>

            <div className='order-show__section'>
              <ReviewOrderPhotos
                photos={photos}
                optionsDisabled={true}
              />
            </div>

          </React.Fragment>
        }
      </div>

      <SocialLinksModal />

      <Footer currentPage={'order-show'} />
    </React.Fragment>
 ) 
}

export default Show
