import React from 'react'
import ActionButton from '../ActionButton'
import LoaderActionButton from '../LoaderActionButton'
import ModalHelper from '../../helpers/ModalHelper'

function CardContents(props) {
  const seriesData = props.seriesData

  const cloudfrontBaseUrl = document.getElementById('photos_cloudfront_url').value

  // ensure only 2 are ever shown here (admin side serialises everything so limit this instead in the front-end)
  const photos = (() => {
    // for convenience all photos are serialised from the backend, in this context just select the first 3 portraits for the card preview
    let portraitPhotos = seriesData.photos.filter((photo) => photo.aspect_group === 'portrait')
    
    if(portraitPhotos.length > 3) {
      return portraitPhotos.slice(0, 3)
    }
    else {
      return seriesData.photos
    }
  })()

  const photoUrl = (filename) => {
    return `${cloudfrontBaseUrl}/${filename}`
  }

  return(
    <div className='series-card__contents'>
      <div className={`series-card__header ${props.renderLinks ? 'series-card__header--inline-links' : ''}`}>
        <h4>{seriesData.title}</h4>
        { props.renderLinks &&
          <div className='inline-links'>
             <ActionButton
              isShowing={true}
              text={'Edit'}
              faIconClass={'fa-pen-to-square'}
              secondaryClassName={'margin-bottom'}
              clickHandler={() => { ModalHelper.openModalClickHandler(`edit-series-${seriesData.token}-form-modal`) }}
            />

            <LoaderActionButton
              text={seriesData.enabled ? 'Show' : 'Preview'}
              href={`/series/${seriesData.slug}`}
              faIconClass={'fa-arrow-right'}
            />

            { props.deleteButtonClickHandler &&
              <LoaderActionButton
                text={'Delete'}
                onClick={() => { props.deleteButtonClickHandler(seriesData.token) }}
                faIconClass={'fa-trash'}
                secondaryClassName={'margin-top'}
                confirmationText={'Delete this series?'}
              />
            }
          </div>
        }
      </div>
      
      <p className='series-card__place'>
        {seriesData.place}
      </p>

      <div className='series-card__photos'>
        {
          photos.map((photo) => {
            return(
              <div className='photo__wrapper' key={`series-${seriesData.slug}-${photo.public_bucket_filename}`}>
                <img
                  className={'photo-box-shadow'}
                  src={photoUrl(photo.public_bucket_filename)}
                />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default CardContents
