import React from 'react';

function VisibilityToggle(props) {
  return(
    <div className={`switch admin__visibility-toggle admin__visibility-toggle--${props.variant}`}>
      <label>
        {props.text}
        <input type="checkbox" checked={props.isChecked} onChange={props.toggleHandler} />
        <span className="lever"></span>
      </label>
    </div>
  )
}

export default VisibilityToggle
