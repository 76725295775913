
import PageScrollHelper from './PageScrollHelper'

const _scrollToTop = () => {
  PageScrollHelper.scrollToTop({ behavior: 'instant', timeout: 0 })
}

export default {
  newPage: (page, currentPage, allPages) => {
    // the component itself makes sure we're not doing out of bounds calls with this
    if(page === 'previous') {
      _scrollToTop()

      return String(parseInt(currentPage) - 1)
    }
    else if(page === 'next') {
      _scrollToTop()

      return String(parseInt(currentPage) + 1)
    }
    else {
      if(allPages.includes(page)) {
        _scrollToTop()

        return page
      }
      else {
        return null
      }
    }
  }
}
