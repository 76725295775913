import React, { useState } from 'react'
import Navbar from '../Navbar'
import Flash from '../Flash'
import RequiredFieldLabel from '../forms/RequiredFieldLabel'
import FlashHelper from '../../helpers/FlashHelper'
import ApiService from '../../services/ApiService'
import Footer from './Footer'

function NewAffiliateCode() {
  const [affiliateCode, setAffiliateCode] = useState(null)
  const [currentFlash, setCurrentFlash] = useState(FlashHelper.loadInitialFlash())
  const [isCreating, setIsCreating] = useState(false)

  console.log(`currentFlash: `, currentFlash)

  const resetFlashWithTimeout = () => {
    window.setTimeout(() => {
      setCurrentFlash(null)
    }, 3000)
  }

  if(currentFlash) {
    resetFlashWithTimeout()
  }

  const setFlash = (flash) => {
    setCurrentFlash(flash)
    resetFlashWithTimeout()
  }

  const createCode = (_event) => {
    if(isCreating) {
      return
    }

    setIsCreating(true)

    const code = document.getElementById('discount-code-code').value
    console.log(`loaded code: ${code}`)

    if(code === '') {
      console.error('code field empty')
      setIsCreating(false)
      return
    }

    // event.stopPropagation()
    // event.preventDefault()

    ApiService.createAffiliateCode(code)
      .then((response) => {
        setIsCreating(false)

        if(response.success && Object.keys(response.data).length > 0) {
          console.log(`response: `, response)
          console.log(`repsonse.data: `, response.data)

          const responseData = response.data

          const flash = responseData['flash']
          const affiliate_code = responseData['affiliate_code']
        
          console.log(`affiliate_code: `, affiliate_code)

          if(code) {
            console.log(`setting code`)
            setAffiliateCode(affiliate_code)
          }
          // success or failure (success if code was returned, indicating successful affiliate code creation)
          setFlash(flash)
        }
        else {
          setFlash({ type: 'error', message: "Sorry! something went wrong just then while trying to create your affiliate code." })
        }
      })
  }
  
  if(affiliateCode) {
    return(
      <React.Fragment>
        <Navbar title='AFFILIATE CODE'/>
        <Flash flash={currentFlash} />

        <div className='affiliate-code'>
          <h4 className='border-bottom-header'>
            Your affiliate code
          </h4>
          <div className='affiliate-code__section'>
            <div className='affiliate-code__created'>
              <h3>{affiliateCode.code}</h3>
              
              <a href='/affiliate/home' className='button button--action button--full-width'>
                <span className='affiliate-home-after-create-code-link'>
                </span>
                <i className='fa fa-solid fa-arrow-right'></i>
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
  else {
    return(
      <React.Fragment>
        <Navbar title='AFFILIATE CODE'/>
        <Flash flash={currentFlash} />

        <div className='affiliate-code'>
          <h4 className='border-bottom-header'>
            Create your affiliate code
          </h4>
          <div className='affiliate-code__section'>
            <p>Create your affiliate code to get started. Try to make it simple as users will need to enter it exactly for it to work. For example: "BOBS BURGERS"</p>
          </div>

          <div className='affiliate-code__section'>
            <div className='affiliate-code__form'>
              <form onSubmit={(e) => { e.preventDefault() }}>
                <div className='input-field'>
                  <input required name='code' type='text' autoComplete='off' id='discount-code-code'></input>
                  <RequiredFieldLabel
                    name={'Code'}
                    htmlFor={'discount-code-code'}
                  />

                  <button type='submit' className='button button--action button--full-width' onClick={createCode}>
                    <span className='create-affiliate-code-link'>
                    </span>
                    { isCreating &&
                      <i className='fa-solid fa-spinner fa-spin'></i>
                    }
                    { !isCreating &&
                      <i className='fa fa-solid fa-arrow-right'></i>
                    }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

export default NewAffiliateCode
