import React from 'react'

class Money extends React.Component {
  render() {
    return(
      <div>
        Give me money
      </div>
    )
  }
}

export default Money;