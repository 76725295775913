import React from 'react'

class StepNav extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  classesFor = (step) => {
    let classes = ''
    const isCurrentStep = this.props.currentStep === step
    const isNavegable = this.props.navegableSteps.includes(step)
    
    // current step has higher priority than navegable, and will always override it (since current step is always navegable)
    if(isCurrentStep) {
      classes += 'step-nav__step--current '
    }
    else {
      if(isNavegable ) {
        classes += 'step-nav__step--navegable '
      }
      else {
        classes += 'step-nav__step--disabled '
      }
    }

    if(this.props.completedSteps.includes(step)) {
      classes += 'step-nav__step--completed '
    }

    return classes
  }

  render() {
    return(
      <React.Fragment>
        <div className='step-nav-backing-line'></div>
        <div className='step-nav'>
          {
            this.props.allSteps.map((step, index) => {
              return(
                <a onClick={(e) => { this.props.onStepClick(step) }} key={`step-nav-${step}-step`} className={`step-nav__step ${this.classesFor(step)}`}>
                  <div className='step-index-wrapper'>
                    <i className="step-completed-check fa-solid fa-check"></i>
                    <div className='step-index'>
                      { index + 1 }
                    </div>
                  </div>

                  <div className='step-name'>
                    { step }
                  </div>
                </a>
              )
            })
          }
        </div>
      </React.Fragment>
    )
  }
}

export default StepNav
