import React, { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import LoaderActionButton from '../LoaderActionButton'
import PageScrollHelper from '../../helpers/PageScrollHelper'


function StripePaymentForm(props) {
  const stripe = useStripe()
  const elements = useElements()

  const [formIsInitialised, setFormIsInitialised] = useState(null)
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const stripePaymentElement = elements.getElement('payment')
    
    stripePaymentElement.on('ready', () => {
      setFormIsInitialised(true)

      PageScrollHelper.scrollToBottom()
    })

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!")
          break
        case "processing":
          setMessage("Your payment is processing.")
          break
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.")
          break
        default:
          setMessage("Something went wrong.")
          break
      }
    })
  }, [stripe])

  const loadOrigin = () => {
    const url = window.location.href

    const domain = (new URL(url))

    return domain['origin']
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${loadOrigin()}/orders/${props.orderToken}/stripe_payment_callback`,
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setError(true)
      setMessage(error.message)
    }
    else {
      setMessage("An unexpected error occurred.")
    }

    window.setTimeout(() => {
      setError(false)
      setMessage(null)
    }, 4000)

    setIsLoading(false)
  }

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form className='stripe-payment-form' id='payment-form' onSubmit={handleSubmit}>
      <PaymentElement id='payment-element' options={paymentElementOptions} />

      <div className='stripe-payment-form__controls'>
        { formIsInitialised &&
          <LoaderActionButton
            text={'Pay and complete Order'}
            buttonType='submit'
            faIconClass={'fa-arrow-right'}
            isLoading={isLoading}
          />
        }
        { (!formIsInitialised) &&
          <div className="request-loading">
            <div className="indeterminate"></div>
          </div>
        }

        { message &&
          <p className={`${error ? 'api-request-error' : ''}`}>
            {message}
          </p>
        }
      </div>
    </form>
  )
}

export default StripePaymentForm
