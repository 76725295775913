import React from 'react'
import LoadingSpinner from './LoadingSpinner'
import PhotoSelector from './PhotoSelector'
import PhotosHelper from '../helpers/PhotosHelper'

class PhotoImage extends React.Component {
  constructor(props) {
    super(props)

    let initialState = {
      loaded: false
    }

    const photo = props.photo
    this.renderPhotoSelector = !props.href && photo.wall_preview_image_filenames.length > 0

    if(this.renderPhotoSelector) {

      initialState.selectedPhoto = props.src // initial selection
  
      initialState.selectablePhotos = (() => {
        if(this.renderPhotoSelector && photo.wall_preview_image_filenames && photo.wall_preview_image_filenames.length > 0) {
          const selectableUrls = this.selectablePhotoFullUrls()
          // console.log('selectableUrls: ', selectableUrls)
  
          return selectableUrls
        }
        else {
          return [props.src]
        }
      })()
    }

    this.state = initialState
    this._onLoadHandler = this._onLoadHandler.bind(this)
  }

  
  _onLoadHandler(e) {
    this.setState({ loaded: true })
    this.props.setLoaded()
  }

  selectablePhotoFullUrls = () => {
    const assetsCloudfrontUrl = document.getElementById('assets_cloudfront_url').value
    const wallPreviewImageUrls = this.props.photo.wall_preview_image_filenames.map((filename) => `${assetsCloudfrontUrl}/${filename}`)

    return [this.props.src].concat(wallPreviewImageUrls)
  }

  setSelectedPhoto = (selectedPhoto) => {
    this.setState({ selectedPhoto })
  }

  render() {
    // display: none until it's loaded. Show spinner until it's done (pad spinner to same size as photo will be)
    const classString = (() => {
      let baseString = ''

      if(this.props.selected) {
        baseString = baseString.concat('photo--selected ')
      }
      if(this.state.loaded && (!this.renderPhotoSelector || this.state.selectedPhoto === this.props.src)) {
        baseString = baseString.concat('photo-box-shadow photo-box-shadow--hoverable ')
      }
      if(!this.state.loaded) {
        baseString = baseString.concat('photo--loading ')
      }

      return baseString
    }).call()

    const photo = this.props.photo
    const loadingPaddding = PhotosHelper.loadingPhotoSpinnerWrapperPaddingPercentage(photo.aspect_group)


    return(
      <React.Fragment>
        <div className='photo__loading-spinner' style={this.state.loaded ? {} : { paddingTop: `${loadingPaddding}%`, paddingBottom: `${loadingPaddding}%` } }>
          <div className='photo__loading-spinner__icon-container'>
            <LoadingSpinner
              isShowing={!this.state.loaded}
              size={'big'}
              colour={'blue'}
            />
          </div>
        </div>

        {this.renderPhotoSelector && this.state.selectablePhotos.length > 1 &&
          <React.Fragment>
            <div className={`selectable-image-wrapper selectable-image-wrapper--${photo.aspect_group}`}>
              <img
                className={classString}
                src={this.state.selectedPhoto}
                draggable='false'
                data-token={photo.token}
                data-aspect-group={photo.aspect_group}
                onClick={this.props.clickHandler}
                onLoadStart={this._onLoadStartHandler}
                onLoad={this._onLoadHandler}
              />
            </div>

            <PhotoSelector
              selectable={this.state.selectablePhotos}
              selected={this.state.selectedPhoto}
              setSelected={this.setSelectedPhoto}
            />
          </React.Fragment>
        }

        { !this.renderPhotoSelector &&
          <a href={this.props.href}>
            <img
              className={classString}
              src={this.props.src}
              draggable='false'
              data-token={photo.token}
              data-aspect-group={photo.aspect_group}
              onClick={this.props.clickHandler}
              onLoadStart={this._onLoadStartHandler}
              onLoad={this._onLoadHandler}
            />
          </a>
        }
      </React.Fragment>
    )
  }
}

export default PhotoImage
