import React from 'react';

class LoadingSpinner extends React.Component {
  render() {
    if(this.props.isShowing) {
      return(
        <div className={`preloader-wrapper ${this.props.size} active`}>
          <div className={`spinner-layer spinner-${this.props.colour}-only`}>
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>
            <div className="gap-patch">
              <div className="circle"></div>
            </div>
            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return null;
    }
  }
}

export default LoadingSpinner;

