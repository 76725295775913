import React from 'react'

import Navbar from '../Navbar'
import SeriesCard from './Card'
import SocialLinksModal from '../modals/SocialLinksModal'
import Footer from '../Footer'

function Index() {
  const seriesCards = JSON.parse(document.getElementById('series_cards_data').value)

  return(
    <React.Fragment>
      <Navbar title='keenedreams'/>

      <div className='series-index'>
        {
          seriesCards.map((seriesData) => {
            return(
              <SeriesCard
                key={seriesData.slug}
                seriesData={seriesData}
              />
            )
          })
        }
      </div>

      <SocialLinksModal />

      <Footer currentPage={'series-index'} />
    </React.Fragment>
  )
}

export default Index
