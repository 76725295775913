import React, { Fragment } from 'react'
import ModalHelper from '../../helpers/ModalHelper'

import ActionButton from '../ActionButton'
import PrintJobOrderAddressModal from '../modals/PrintJobOrderAddressModal'
import LoaderActionButton from '../LoaderActionButton'

function PrintJobOrderTable(props) {
  const order = props.order
  return(
    <div className='print-job-order-table'>
      <div className='print-job-order-table__header'>
        { order.address &&
          <Fragment>
            <PrintJobOrderAddressModal order={order} />

            <div className='print-job-order-table__open-order-address-modal'>
              <ActionButton
                isShowing={true}
                text={'Order Address'}
                faIconClass={'fa-regular fa-address-book'}
                secondaryClassName={'button--fullsize-round'}
                clickHandler={() => { ModalHelper.openModalClickHandler(`print-job-order-${order.token}-address-modal`) }}
              />
            </div>
          </Fragment>
        }

        <div className='print-job-order-table__link'>
          <LoaderActionButton
            href={`/dreams_admin/orders/${order.token}`}
            text={'View Order'}
            faIconClass={'fa-arrow-right'}
            secondaryClassName={'button--fullsize-round'}
          />
        </div>

      </div>

      <div className='print-job-order-table__token'>
        <div>Order ID:</div>
        <div>{order['token']}</div>
      </div>

      { order['internal_notes'] &&
        <div className='print-job-order-table__internal-notes'>
          <div>Internal Notes:</div>
          <div>{order['internal_notes']}</div>
        </div>
      }

      { order['address'] &&
        <div className='print-job-order-table__customer-delivery-name'>
          <div>Customer Name: </div>
          <div>{ order['address'].name }</div>
        </div>
      }

      <table>
        <thead>
          <tr>
            <td>Filename</td>
            <td>Size</td>
            <td>Link</td>
          </tr>
        </thead>

        <tbody>
          {
            order.photos.map((photo) => {
              return(
                <tr key={`photo-${photo.token}`}>
                  <td className='photo-preview-table-cell'>
                    <img src={photo.public_thumbnail_url} />
                    <div>{photo.filename}</div>
                  </td>
                  <td>{`${photo.size} (${photo.dimensions})`}</td>
                  <td>
                    <a href={`/photos/${photo.token}`} target='_blank'>
                      {photo.token}
                    </a>
                  </td>
                </tr>
              )
            })
          }
         
        </tbody>
      </table>
    </div>
  )
}

export default PrintJobOrderTable
