import React from 'react'
import PrintsizingHelper from '../helpers/PrintsizingHelper'

class PrintSizeSelector extends React.Component {
  constructor(props) {
    super(props)

    const firstSelectableSize = props.photo.print_edition_sizes_available[0] // there should always be one here - modal isn't rendered if not

    const selectedSize = this.props.selectedSize || firstSelectableSize

    // if selectSize function is passed in via props then we're dealing with an existing order, and want to update in backend
    // otherwise it's a cart item and we render and populate a form hidden field
    this.isOrderCheckout = !this.props.setSize

    this.photoToken = this.props.photo.token
    this.aspectGroup = this.props.photo.aspect_group

    this.state = { selectedSize }
  }

  sizeIsSoldOut = (size) => {
    return this.props.photo.print_editions_sold_out.includes(size)
  }

  selectSize = (size) => {
    if(this.props.optionsDisabled || this.state.selectedSize === size || !['small', 'medium', 'large', 'extra_large'].includes(size) || this.sizeIsSoldOut(size)) {
      return
    }

    const callback = (() => {
      if(this.props.selectSize) {
        return () => {
          // function passed that will pass up to context to set state upstream
          this.props.selectSize(size)
        }
      }
      else {
        // otherwise do nothing, (the hidden input value will be set by the setState below)
        return () => {}
      }
    })()

    this.setState({ selectedSize: size }, callback)
  }

  selectedSizeSubtext = () => {
    const photo = this.props.photo
    const selectedSize = this.state.selectedSize
    const printEdition = photo.print_edition || {}

    const editionNumber = (() => {
      if(printEdition['size'] === selectedSize && printEdition['number']) {
        // completed order (viewing edition of photo that was sold with order)
        return printEdition['number']
      }
      else {
        // not ordered yet - viewing print edition that it will be once it is ordered
        return photo.number_sold_data[selectedSize] + 1
      }
    })()

    return `Limited edition: #${editionNumber} of ${photo.default_print_edition_numbers_by_size[selectedSize]}`
  }

  render() {
    return(
      <div className='input-field col s12 inline-options-selector'>
        <input type='hidden' name='' data-inline-options-selector id={`photo-${this.photoToken}-size`} value={this.state.selectedSize} />

        { this.isOrderCheckout && this.props.renderLabel &&
          <div className='inline-options-selector__label'>
            {`${this.props.optionsDisabled ? 'Selected Size' : 'Select Size'}`}
          </div>
        }
        <div className={`inline-options-selector__options ${this.props.optionsDisabled ? 'disabled' : ''}`}>
          <div onClick={(_e) => { this.selectSize('small') }} className={`inline-options-selector__option ${this.state.selectedSize === 'small' ? 'inline-options-selector__option--selected' : ''} ${this.sizeIsSoldOut('small') ? 'inline-options-selector__option--sold-out' : ''} ${this.props.optionsDisabled ? 'inline-options-selector__option--disabled' : ''}`}>
            <span>Small</span>
          </div>
          <div onClick={(_e) => { this.selectSize('medium') }} className={`inline-options-selector__option ${this.state.selectedSize === 'medium' ? 'inline-options-selector__option--selected' : ''} ${this.sizeIsSoldOut('medium') ? 'inline-options-selector__option--sold-out' : ''} ${this.props.optionsDisabled ? 'inline-options-selector__option--disabled' : ''}`}>
            <span>Medium</span>
          </div>
          <div onClick={(_e) => { this.selectSize('large') }} className={`inline-options-selector__option ${this.state.selectedSize === 'large' ? 'inline-options-selector__option--selected' : ''} ${this.sizeIsSoldOut('large') ? 'inline-options-selector__option--sold-out' : ''} ${this.props.optionsDisabled ? 'inline-options-selector__option--disabled' : ''}`}>
            <span>Large</span>
          </div>
         
          { this.props.photo.print_sizings_available_by_dpi.includes('extra_large') &&
            <div onClick={(_e) => { this.selectSize('extra_large') }} className={`inline-options-selector__option ${this.state.selectedSize === 'extra_large' ? 'inline-options-selector__option--selected' : ''} ${this.sizeIsSoldOut('extra_large') ? 'inline-options-selector__option--sold-out' : ''} ${this.props.optionsDisabled ? 'inline-options-selector__option--disabled' : ''}`}>
              <span>XL</span>
            </div>
          }
        </div>

        <div className='inline-options-selector__dimensions'>
          {
            `${PrintsizingHelper.dimensions(this.state.selectedSize, this.aspectGroup)} ~ €${PrintsizingHelper.cost(this.state.selectedSize, this.aspectGroup)}`
          }

          <React.Fragment>
            <br />
            <div className='version-subtext'>
              {this.selectedSizeSubtext()}
            </div>
          </React.Fragment>
        </div>
      </div>
    )
  }
}

export default PrintSizeSelector
