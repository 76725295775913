import React, { Fragment, useState } from 'react'
import PrintJobOrderTable from './PrintJobOrderTable'
import PrintJobQuoteModal from '../modals/PrintJobQuoteModal'
import PrintJobPayModal from '../modals/PrintJobPayModal'
import ModalHelper from '../../helpers/ModalHelper'
import LoaderActionButton from '../LoaderActionButton'
import ActionButton from '../ActionButton'
import ApiService from '../../services/ApiService'

function PrintJobTable(props) {
  const [isDownloadingPhotos, setIsDownloadingPhotos] = useState(false)
  const printJob = props.printJob
  const printJobQuote = printJob.print_job_quote
  const isAdmin = props.resourceName === 'admin'

  const [stateTransitionBeingPerformed, setStateTransitionBeingPerformed] = useState(null)

  const setPrintJob = (printJob, flashMessage) => {
    props.setPrintJob(printJob)
    props.setFlash(({ type: 'notice', message: flashMessage }))
  }

  const printJobStateTransition = (stateTransition) => {
    setStateTransitionBeingPerformed(stateTransition)
    console.log(`Going to ${stateTransition} print job`)

    ApiService.printJobStateTransition(printJob.token, stateTransition)
    .then((response) => {
      if(response.success) {
        console.log('response: ', response)
        setPrintJob(response.data['print_job'], response.data['flash'])
        setStateTransitionBeingPerformed(null)
      }
      else {
        console.log('failed response')
        props.setFlash(({ type: 'error', message: "Sorry! something went wrong." }))
        setStateTransitionBeingPerformed(null)
      }
    })
  }

  const handlePhotosDownload = async () => {
    setIsDownloadingPhotos(true)

    try {
      const response = await fetch(`/dreams_admin/print_jobs/${printJob.token}/download_photos`, { method: 'GET', responseType: 'blob' })

      const blob = await response.blob()

      // Create a temporary link element
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `print-job-${printJob.token}-photos.zip`)
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noreferrer noopener')

      document.body.appendChild(link)

      setTimeout(() => {
        // Trigger the click event to start the download
        link.click()
  
        // Remove the temporary link element
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      }, 100)

    } catch (error) {
      console.error('Download failed:', error)
    } finally {
      setIsDownloadingPhotos(false)
    }
  }

  return(
    <div className='container'>
      <div className='print-job-table'>

        <h4>Info</h4>

        <div className='print-job-table__info'>
          <table>
            <tbody>
              <tr>
                <td>Status:</td>
                <td className='purple-text'>
                  {printJob['status']}
                </td>
              </tr>
              { printJob['assigned_to'] &&
                <tr>
                  <td>Assigned to:</td>
                  <td>{printJob['assigned_to']}</td>
                </tr>
              }
              { printJob.payment_note &&
                <tr>
                  <td>Payment Reference:</td>
                  <td>{printJob.payment_note}</td>
                </tr>
              }
              { printJob.notes &&
                <tr>
                  <td>Notes:</td>
                  <td>{printJob.notes}</td>
                </tr>
              }
              { isAdmin && 
                <tr>
                  <td>Created:&nbsp;</td>
                  <td>{printJob['created_at']}</td>
                </tr>
              }

              { printJob['sent_at'] &&
                <tr>
                  <td>{isAdmin ? 'Sent:' : 'Created:'}&nbsp;</td>
                  <td>{printJob['sent_at']}</td>
                </tr>
              }

              { printJobQuote && printJobQuote['paid_at'] &&
                <tr>
                  <td>Paid:&nbsp;</td>
                  <td>{printJobQuote['paid_at']}</td>
                </tr>
              }

              { printJob['started_at'] &&
                <tr>
                  <td>Started:&nbsp;</td>
                  <td>{printJob['started_at']}</td>
                </tr>
              }

              { printJob['completed_at'] &&
                <tr>
                  <td>Completed:&nbsp;</td>
                  <td>{printJob['completed_at']}</td>
                </tr>
              }

              { printJob['shipped_at'] && 
                <tr>
                  <td>Shipped:&nbsp;</td>
                  <td>{printJob['shipped_at']}</td>
                </tr>
              }

              { printJobQuote &&
                <Fragment>
                  <tr>
                    <td>Print Cost:&nbsp;</td>
                    <td>{printJobQuote['printing_cost_as_money']}</td>
                  </tr>
                  <tr>
                    <td>Shipping Cost:&nbsp;</td>
                    <td>{printJobQuote['shipping_cost_as_money']}</td>
                  </tr>
                  { printJobQuote['misc_cost'] && printJobQuote['misc_cost'] > 0 &&
                    <tr>
                      <td>Other Costs:&nbsp;</td>
                      <td>{printJobQuote['misc_cost_as_money']}</td>
                    </tr>
                  }
                  <tr>
                    <td>Total Cost:&nbsp;</td>
                    <td>{`${printJobQuote['total_cost_as_money']}`}</td>
                  </tr>
                </Fragment>
              }

            </tbody>
          </table>
        </div>

        <h4>Actions</h4>

        <div className='print-job-table__actions'>
          <table>
            <tbody>
              <tr>
                { isDownloadingPhotos &&
                  <td>
                    <i>Fetching full-size files and creating a ZIP archive, please wait...</i>
                  </td>
                }

                { !isDownloadingPhotos &&
                  <td>Download all photo files for this Order as a ZIP</td>
                }
                <td>
                  <LoaderActionButton
                    text={'Get files'}
                    onClick={handlePhotosDownload}
                    isLoading={isDownloadingPhotos}
                    faIconClass={'fa-download'}
                    secondaryClassName={'print-job-table__actions__button'}
                  />
                </td>
              </tr>

              { printJob['is_quote_updatable'] &&
                <tr>
                  <td className={printJobQuote ? '' : 'purple-text'}>
                    {`${printJobQuote ? `Print Job Quote added` : 'Before starting the job, add a quote for printing and shipping:'}`}
                  </td>
                  <td>
                    <Fragment>
                      <PrintJobQuoteModal
                        printJob={printJob}
                        setPrintJob={setPrintJob}
                      />

                      <ActionButton
                        isShowing={true}
                        text={`${printJobQuote ? 'Update' : 'Add'} Quote`}
                        faIconClass={'fa-sack-dollar'}
                        secondaryClassName={'print-job-table__actions__button'}
                        clickHandler={() => { ModalHelper.openModalClickHandler(`print-job-${printJob.token}-production-quote-modal`) }}
                      />
                    </Fragment>
                  </td>
                </tr>
              }

              { printJob['is_payable'] &&
                <tr>
                  <td className={'purple-text'}>
                    Mark Print Job as PAID
                  </td>
                  <td>
                    <Fragment>
                      <PrintJobPayModal
                        printJob={printJob}
                        setPrintJob={setPrintJob}
                      />

                      <ActionButton
                        isShowing={true}
                        text={`Mark PAID`}
                        faIconClass={'fa-hand-holding-dollar'}
                        secondaryClassName={'print-job-table__actions__button'}
                        clickHandler={() => { ModalHelper.openModalClickHandler(`print-job-${printJob.token}-pay-modal`) }}
                      />
                    </Fragment>
                  </td>
                </tr>
              }

              { printJob['is_confirmable'] &&
                <tr>
                  <td>{`Confirm and notify Printers: (${printJob['printer_emails']})`}</td>
                  <td>
                    <LoaderActionButton
                      isShowing={true}
                      text={'Confirm now'}
                      confirmationText={'Confirm Print Job?'}
                      faIconClass={'fa-regular fa-check'}
                      secondaryClassName={'print-job-table__actions__button'}
                      onClick={(e) => { printJobStateTransition('confirm') }}
                      isLoading={stateTransitionBeingPerformed === 'confirm'}
                    />
                  </td>
                </tr>
              }

              { printJob['is_startable'] &&
                <tr>
                  <td className='purple-text'>
                    Start Print Job
                  </td>
                  <td>
                    <LoaderActionButton
                      isShowing={true}
                      text={'Start now'}
                      confirmationText={'Start Print Job?'}
                      faIconClass={'fa-flag-checkered'}
                      secondaryClassName={'print-job-table__actions__button'}
                      onClick={(e) => { printJobStateTransition('start') }}
                      isLoading={stateTransitionBeingPerformed === 'start'}
                    />
                  </td>
                </tr>
              }

              { printJob['is_completable'] &&
                <tr>
                  <td>{`Mark Job as complete`}</td>
                  <td>
                    <LoaderActionButton
                      isShowing={true}
                      text={'Complete'}
                      confirmationText={'Mark Print Job as complete?'}
                      faIconClass={'fa-check'}
                      secondaryClassName={'print-job-table__actions__button'}
                      onClick={(e) => { printJobStateTransition('complete') }}
                      isLoading={stateTransitionBeingPerformed === 'complete'}
                    />
                  </td>
                </tr>
              }

              { printJob['is_shippable'] &&
                <tr>
                  <td>{`Mark Job as shipped (out for delivery)`}</td>
                  <td>
                    <LoaderActionButton
                      isShowing={true}
                      text={'Shipped'}
                      confirmationText={'Mark Print Job as shipped?'}
                      faIconClass={'fa-truck-fast'}
                      secondaryClassName={'print-job-table__actions__button'}
                      onClick={(e) => { printJobStateTransition('ship') }}
                      isLoading={stateTransitionBeingPerformed === 'ship'}
                    />
                  </td>
                </tr>
              }

              { printJob['is_cancellable'] &&
                <tr>
                  <td>Cancel Print Job</td>
                  <td>
                    <LoaderActionButton
                      isShowing={true}
                      text={'Cancel now'}
                      confirmationText={'Cancel this Print Job?'}
                      faIconClass={'fa-xmark'}
                      secondaryClassName={'print-job-table__actions__button'}
                      onClick={(e) => { printJobStateTransition('cancel') }}
                      isLoading={stateTransitionBeingPerformed === 'cancel'}
                    />
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <h4 className='margin-bottom'>
          All Photos to print
        </h4>

        <div className='print-job-table__total-quantities-by-size'>
          {
            Object.keys(printJob.print_sizing_counts).map((sizing) => {
              return(
                <div key={sizing} className='print-job-table__total-quantities-by-size__row'>
                  <div>{`${sizing}:`}</div>
                  <div>{printJob.print_sizing_counts[sizing]}</div>
                </div>
              )
            })
          }
        </div>

        <div className='print-job-table__all-photos'>
          <table>
            <thead>
              <tr>
                <td>Filename</td>
                <td>Size</td>
                <td>Quantity</td>
              </tr>
            </thead>

            <tbody>
              { printJob.photos.map((photo) => {
                return(
                  <tr key={`print-job-photo-${photo.token}`}>
                    <td className='photo-preview-table-cell'>
                      <img src={photo.public_thumbnail_url} />
                      <div>{photo.filename}</div>
                    </td>
                    <td>{`${photo.size} (${photo.dimensions})`}</td>
                    <td>{photo.quantity}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <h4>Orders</h4>

        <div className='print-job-table__orders'>
          { printJob.orders.map((order) => {
            return(
              <PrintJobOrderTable
                order={order}
                key={`${order.token}-print-job-order-table`}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default PrintJobTable
