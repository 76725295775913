import React from 'react';

class BaseMetrics extends React.Component {
  render() {
    return(
      <React.Fragment>
        <p>{this.props.aspectGroup}</p>
        <hr />
        <p>Total:  {this.props.totalCount}</p>
        <p>Showing: {this.props.showingCount}</p>
        <p>Hidden: {this.props.hiddenCount}</p>
      </React.Fragment>
    );
  }
}

export default BaseMetrics;
