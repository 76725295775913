import React from 'react'
import AddressPreview from '../../forms/AddressPreview'
import AddressForm from '../../forms/AddressForm'
import AddressHelper from '../../../helpers/AddressHelper'

class DeliveryStep extends React.Component {
  constructor(props) {
    super(props)

    const address = this.props.address

    const addressIsValid = AddressHelper.addressIsValid(address)

    // if address present and valid, show AddressPreview
    // if it's present and invalid, show address form, otherwise neither
    this.state = { addressFormShowing: this.props.deliveryMethod === 'delivery' && !addressIsValid }
  }

  selectDelivery = () => {
    if(this.props.isPaid) {
      this.setState({ paidTextShowing: true })

      return
    }

    if(this.props.deliveryMethod === 'delivery') { return }

    const addressIsValid = AddressHelper.addressIsValid(this.props.address)

    this.setState({ addressFormShowing: !addressIsValid }, () => {
      this.props.selectDeliveryMethod('delivery')
    })
  }

  selectPickup = () => {
    if(this.props.isPaid) {
      this.setState({ paidTextShowing: true })

      return
    }

    if(this.props.deliveryMethod === 'pickup') { return }

    this.setState({ addressFormShowing: false }, () => {
      this.props.selectDeliveryMethod('pickup')
    })
  }

  showAddressForm = () => {
    this.setState({ addressFormShowing: true })
  }

  removeAddress = () => {
    if(this.props.isPaid || this.deliveryMethod == 'delivery') {
      return
    }

    const confirmRemoveMessage = (() => {
      if(this.props.isConfirmed) {
        return 'Are you sure you want to remove your address? - if you do this you will need to confirm your Order again, after selected a delivery method.'
      }
      else {
        return 'Are you sure you want to remove your address?'
      }
    })()

    if(window.confirm(confirmRemoveMessage)) {
      this.setState({ addressFormShowing: false }, () => {
        this.props.removeAddress()
      })
    }
  }

  hideAddressForm = () => {
    const addressIsValid = AddressHelper.addressIsValid(this.props.address)
    // unset 'pickup' if address is closed without a valid address set
    const deliveryMethod = !addressIsValid ? null : this.props.deliveryMethod

    this.setState({ addressFormShowing: false, deliveryMethod })
  }

  methodSelectionText = () => {
    const deliveryMethod = this.props.deliveryMethod
    
    if(deliveryMethod === null) {
      return `How would you like to get your ${this.props.pluraliseCollectionName('Print')}?`
    }

    if(deliveryMethod === 'delivery') {
      if(this.state.addressFormShowing) {
        return Object.keys(this.props.address).length ? 'Updating Address' : 'Please enter your address to continue'
      }
      else {
        return 'Delivery address selected'
      }
    }
    else if(deliveryMethod === 'pickup') {
      return `Pickup your ${this.props.pluraliseCollectionName('Print')} in Puerto Escondido`
    }
  }

  updateAddressSuccess = (responseData) => {
    this.setState({ addressFormShowing: false }, () => {
      // update state in parent
      this.props.updateAddressSuccess(responseData)
    })
  }

  render() {
    const deliveryMethod = this.props.deliveryMethod
    const addressIsValid = AddressHelper.addressIsValid(this.props.address)

    return(
      <div className='delivery-step'>
        <div className='order-checkout__section'>
          <p>{ this.methodSelectionText() }</p>
        </div>

        <div className='order-checkout__section'>
          <div className='delivery-step__options-panel'>
            <a className={`button button--selectable button--full-width ${deliveryMethod === 'delivery' ? 'selected' : ''} ${this.props.isPaid && deliveryMethod !== 'delivery' ? 'button--disabled' : ''}`} onClick={this.selectDelivery}>
              <div className='contents-wrapper'>
                <span>Delivery</span>
                <i className="fa fa-solid fa-truck"></i>
              </div>
            </a>

            <a className={`button button--selectable button--full-width ${deliveryMethod === 'pickup' ? 'selected' : ''} ${this.props.isPaid && deliveryMethod !== 'pickup' ? 'button--disabled' : ''}`} onClick={this.selectPickup}>
              <div className='contents-wrapper'>
                <span>Pickup</span>
                <i className="fa fa-solid fa-handshake-simple"></i>
              </div>
            </a>

            { !this.props.isPaid && ((deliveryMethod === 'pickup') || (deliveryMethod === 'delivery' && addressIsValid)) &&
              <a className='button button--action button--full-width' onClick={this.props.completeStep}>
                <div className='contents-wrapper'>
                  <span>Continue</span>
                  <i className='fa fa-solid fa-arrow-right'></i>
                </div>
              </a>
            } 
          </div>
        </div>

        { this.props.isPaid && this.state.paidTextShowing &&
          <div className='order-checkout__section'>
            <div className='delivery-step__paid-text'>
              <p>Your Order is paid so your selected delivery method is now locked in.</p>
              <p>
                If you need to change it you can &nbsp;
                <a href={`mailto:caleb@keenedreams.io?subject=Order%20${this.props.orderToken}`}>send me an email</a>
              </p>
            </div>
          </div>
        }

        { addressIsValid && !this.state.addressFormShowing && deliveryMethod && deliveryMethod !== 'pickup' &&
          <div className='order-checkout__section'>
            <AddressPreview
              renderUpdateLink={true}
              onUpdateLinkClick={this.showAddressForm}
              renderRemoveLink={!this.props.isPaid}
              onRemoveLinkClick={this.removeAddress}
              address={this.props.address}
            />
          </div>
        }

        { this.state.addressFormShowing && 
          <AddressForm
            address={this.props.address}
            submitButtonText={'Set Address'}
            populateOnMount={true}
            orderToken={this.props.orderToken}
            successCallback={this.updateAddressSuccess}
            closeForm={this.hideAddressForm}
            customer={this.props.customer}
          />
        }
      </div>
    )
  }
}

export default DeliveryStep
