import React from 'react'
import ReviewOrderPhotos from '../ReviewOrderPhotos'
import CustomerForm from '../../forms/CustomerForm'

class ReviewStep extends React.Component {
  render() {
    return(
      <React.Fragment>
        <div className='order-checkout__section'>
          <CustomerForm
            orderToken={this.props.orderToken}
            note={this.props.note}
            customer={this.props.customer}
            successCallback={this.props.updateCustomerSuccess}
          />
        </div>
        <div className='order-checkout__section'>
          <ReviewOrderPhotos
            { ...this.props }
          />
        </div>
      </React.Fragment>
    )
  }
}

export default ReviewStep
