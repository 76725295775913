import React from 'react'
import PhotoTechnicalDetails from './PhotoTechnicalDetails'
import ModalHelper from '../helpers/ModalHelper'
import PhotosHelper from '../helpers/PhotosHelper'
import LoaderActionButton from './LoaderActionButton'

import LocalStorageHelper from '../helpers/LocalStorageHelper'
import LocalStorageContext from './contexts/LocalStorageContext'
import ApiService from '../services/ApiService'

class ShowPhotoOptions extends React.Component {

  static contextType = LocalStorageContext

  constructor(props) {
    super(props)

    this.photoToken = this.props.photo['token']


    // TODO: probably get rid of this functionality
    const previouslyOrdered = (() => {
      if(this.LocalStorageIsAvailable) {
        return LocalStorageHelper.previousOrderPhotoTokens().includes(this.photoToken)
      }
      else {
        return false
      }
    })()

    // sectionsVisible state managed by parent component
    this.state = { previouslyOrdered, isAddingToCart: false }
  }

  componentDidMount() {
    this.LocalStorageIsAvailable = this.props.LocalStorageIsAvailable || LocalStorageHelper.LocalStorageIsAvailable()
  }

  isInCurrentCart = () => {
    return this.props.currentCartPhotoTokens.includes(this.photoToken)
  }

  // update state in top-level component via context, just for maintaining count logic
  updateCurrentPhotoTokensContext = () => {
    const currentPhotoTokensFromSession = LocalStorageHelper.currentCartPhotoTokens()

    this.context.setCurrentCartPhotosFromTokens(
      currentPhotoTokensFromSession,
      ModalHelper.highlightCartModalLink(this.photoToken, this.props.currentCartPhotoTokens)
    )
  }

  addPhotoTokenToLocalStorage = () => {
    if(LocalStorageHelper.addToCart(this.photoToken)) {
      this.updateCurrentPhotoTokensContext()
    }
    else {
      console.log("Dang something didn't work - adding to cart failed :(")
    }
  }

  addToCart = () => {
    const currentOrderToken = LocalStorageHelper.currentOrderToken()

    if(currentOrderToken) {
      this.setState({ isAddingToCart: true })
      // add to existing order in backend
      ApiService.addPrintToOrder(currentOrderToken, this.photoToken)
        .then((response) => {
          if(response.success) {
            this.addPhotoTokenToLocalStorage()
          }
          this.setState({ isAddingToCart: false })
        })
    }
    else {
     this.addPhotoTokenToLocalStorage()
    }
    
  }

  removeFromCart = () => {
    if(LocalStorageHelper.removeFromCart(this.photoToken)) {
      this.updateCurrentPhotoTokensContext()
    }
    else {
      console.log("Dang something didn't work - removing from cart failed :(")
    }
  }

  openPrintsListModal = () => {
    ModalHelper.openModalClickHandler('prints-list-modal')
  }
  
  render() {
    const photo = this.props.photo
    const watermarkedUrl = PhotosHelper.watermarkedPhotoCloudfrontUrl(photo.basename)
    const isInCurrentCart = this.isInCurrentCart()
    const sectionsVisible = this.props.sectionsVisible

    const displayableTechnicalDetailsSection = false // this.displayableTechnicalDetailsSection(photo)

    const currentOrderToken = LocalStorageHelper.currentOrderToken()

    const currentSeries = photo.current_series

    // are there editions of any size available
    const hasEditionsAvailable = !photo.is_b_roll && photo.print_edition_sizes_available.length > 0

    return(
      <div className={`show-photo-options${isInCurrentCart ? ' show-photo-options--in-current-cart' : ''}`}>
        { photo.name &&
          <p className='show-photo-options_name'>
            {photo.name}
          </p>
        }
  
        { photo.place && 
          <p className={`show-photo-options__place${photo.description ? '' : ' show-photo-options__place--border-bottom'}`}>
            {photo.place}
          </p>
        }

        { photo.description && 
          <p className='show-photo-options__description'>
            {photo.description}
          </p>
        }

        { currentSeries && currentSeries.slug && currentSeries.title &&
          <p>
            Part of the series
            &nbsp;
            <a href={`/series/${currentSeries.slug}`}>{currentSeries.title}</a>
          </p>

        }

        { watermarkedUrl && photo.has_watermarked_version &&
          <p>
            <a href={watermarkedUrl}>
              Full-resolution image
            </a>
          </p>
        }

        { photo.example_print_image_filenames && photo.example_print_image_filenames.length > 0 &&
          <p>
            <a href={`/example_prints?photo_token=${photo.token}`}>
              Photos of finished print
            </a>
          </p>
        }
        
        { !isInCurrentCart && hasEditionsAvailable &&
          <div className='modal__stacked-buttons'>
            <LoaderActionButton
              text={'Add to Cart'}
              onClick={this.addToCart}
              faIconClass={'fa-cart-plus'}
              isLoading={this.isAddingToCart}
            />
          </div>
        }

        { photo.is_b_roll &&
          <p className='purple-small-text'>
            This photo is 'B-roll' within it's series, and is not available as a print. If you're interested in a print, let me know by email (caleb@keenedreams.io)
          </p>
        }

        { !hasEditionsAvailable &&
          <div className='modal__stacked-buttons'>
            <a href='mailto:caleb@keenedreams.io' className='button button--action'>
              <span>{photo.is_b_roll ? "I'm interested in a print" : 'Request a custom print'}</span>
              <i className="fa-regular fa-envelope"></i>
            </a>
          </div>
        }
    
        { isInCurrentCart && currentOrderToken &&
          <p>
            Photo is in cart:
            &nbsp;
            <a href={`/orders/${currentOrderToken}/checkout`}>
              Go to Cart
            </a>
          </p>
        }

        { isInCurrentCart && !currentOrderToken &&
          <p>
            Photo is in cart:
            &nbsp;
            <a onClick={this.openPrintsListModal}>
              See Cart
            </a>
            &nbsp; | &nbsp;
            <a onClick={this.removeFromCart}>
              Remove
            </a>
          </p>
        }

        { displayableTechnicalDetailsSection &&
          <hr />
        }

        { displayableTechnicalDetailsSection &&
          <p>
            <a onClick={() => { this.props.setCollapsibleSection({ technicalDetails: !sectionsVisible['technicalDetails'] }) }}>
              {`${sectionsVisible['technicalDetails'] ? 'Hide' : 'Show'} photo technical details`}
            </a>
          </p>
        }

        { displayableTechnicalDetailsSection &&
          <PhotoTechnicalDetails
            isShowing={sectionsVisible['technicalDetails']}
            metadata={metadata.verbose_metadata}
          />
        }
      </div>
    )
  }
}

export default ShowPhotoOptions
