import React from 'react';

class PhotoStatusIcon extends React.Component {
  render() {
    if(this.props.isShowing) {
      return(
        <div className='admin-photo-status-icon' title={this.props.title}>
          <i className={`${this.props.faIconClass} fa-lg`}></i>
        </div>
      );
    }
    else {
      return null;
    }
  }
}

export default PhotoStatusIcon;
