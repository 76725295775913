import React from 'react'
import EditPhotoModal from '../admin/modals/EditPhotoModal'
import ModalWrapperContext from '../contexts/ModalWrapperContext'
import LocalStorageContext from '../contexts/LocalStorageContext'

class PhotoModalWrapper extends React.Component {
  static contextType = LocalStorageContext

  photoToken = () => {
    return this.props.photo['token']
  }

  showModalProps = () => {
    return Object.assign(
      {},
      this.props,
      {
        currentCartPhotoTokens: this.context.currentCartPhotoTokens
      }
    )
  }

  render() {
    return(
      <ModalWrapperContext.Provider value={{ token: this.photoToken() }}>
        {this.props.type === 'admin-dreams-edit' &&
          <EditPhotoModal
            { ...this.props }
          />
        }
      </ModalWrapperContext.Provider>
    )
  }
}

export default PhotoModalWrapper
