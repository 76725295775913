import React from 'react'
import ModalCloseIcon from '../../modals/ModalCloseIcon'
import LoaderActionButton from '../../LoaderActionButton'
import ResponseStatusIcon from '../ResponseStatusIcon'
import ModalTextField from '../../modals/ModalTextField'

import ApiService from '../../../services/ApiService'
import ModalHelper from '../../../helpers/ModalHelper'

class EditPhotoModal extends React.Component {
  constructor(props) {
    super(props)

    this.divID = `photo-modal-${this.props.photo['token']}`

    this.state = {
      updating: false,
      responseIconShowing: false,
      isBRoll: this.props.photo.is_b_roll
    }
  }

  metadataFields = ['name', 'instagram_url', 'place', 'description']

  componentDidMount() { 
    this.modalInstance = ModalHelper.initialiseModal('photo', this)

    this.seriesIdSlugMappings = JSON.parse(document.getElementById('series_id_slug_mappings').value)

    const selectedSeriesId = this.props.photo.series_id ? this.props.photo.series_id : null
    
    this.setState({ selectedSeriesId })
  }

  toggleIsBRoll = (e) => {
    // if hasAttribute, unchecking
    const isBRoll = !(e.target.hasAttribute('checked'))

    console.log('isBRoll: ', isBRoll)

    this.setState({ isBRoll })

    if(isBRoll) {
      e.target.setAttribute('checked', true)
    }
    else {
      e.target.removeAttribute('checked')
    }
  }

  setOrToggleSeriesSelection = (e) => {
    const seriesId = e.target.getAttribute('data-series-id')
    const isChecked = e.target.hasAttribute('checked')

    const selectedSeriesId = (() => {
      if(isChecked) {
        // unchecking

        e.target.removeAttribute('checked')

        return null
      }
      else {
        // checking
        e.target.setAttribute('checked', true)

        return seriesId
      }
    })()


    this.setState({ selectedSeriesId })
  }

  closeModal = () => {
    this.modalInstance.close()
  }

  _fadeoutResponseStatusIcon = () => {
    setTimeout(() => this.setState({ responseIconShowing: false }), 3000)
  }

  _metadataFromElements = () => {
    return(
      // build object like { name: name, description: description } etc
      Object.fromEntries(
        new Map(
          this.metadataFields.map((attributeName) => {
            return [
              attributeName,
              document.getElementById(`photo-${this.props.photo.token}-${attributeName}`).value
            ]
          })
        )
      )
    )
  }

  _updatePhotoMetadata = (_e) => {
    this.setState({
      updating: true,
      responseIconShowing: false
    })

    let requestParams = this._metadataFromElements()

    requestParams['series_id'] = this.state.selectedSeriesId
    requestParams['is_b_roll'] = this.state.isBRoll

    ApiService.updatePhotoMetadata(this.props.photo.token, requestParams)
      .then((response) => {
        if(response['success']) {
          this.setState({
            updating: false,
            responseIconShowing: true,
            lastResponseStatus: 'success'
          })
        }
        else {
          this.setState({
            updating: false,
            responseIconShowing: true,
            lastResponseStatus: 'failure'
          })
        }
        this._fadeoutResponseStatusIcon()
      })
    this._fadeoutResponseStatusIcon()
  }

  render() {
    const photo = this.props.photo

    return(
      <div id={this.divID} className='modal modal--photo'>
        <div className='modal-content'>
          <ModalCloseIcon
            modalID={this.divID}
            closeModal={this.closeModal}
          />

          <ModalTextField
            inputType={'input'}
            name={'token'}
            defaultValue={photo.token}
            disabled={true}
          />

          <ModalTextField
            inputType={'input'}
            name={'basename'}
            defaultValue={photo.basename}
            disabled={true}
          />

          <ModalTextField
            inputType={'input'}
            name={'name'}
            defaultValue={photo.name}
          />
          
          <ModalTextField
            inputType={'input'}
            name={'instagram_url'}
            defaultValue={photo.instagram_url}
          />

          <ModalTextField
            inputType={'input'}
            name={'google_maps_url'}
            defaultValue={photo.google_maps_url}
          />

          <ModalTextField
            inputType={'input'}
            name={'place'}
            defaultValue={photo.place}
          />

          <ModalTextField
            inputType={'textarea'}
            name={'description'}
            defaultValue={photo.description}
          />

          <div className='row border-top-light-gray'>
            <div className='input-field col s12'>
              <p>
                <label>
                  <input type='checkbox' className='filled-in' checked={this.state.isBRoll} onChange={this.toggleIsBRoll}></input>
                  <span>Is B-roll photo</span>
                </label>
              </p>
            </div>
          </div>

          { this.seriesIdSlugMappings &&
            <fieldset>
              <legend>Associated Series</legend>
              {
                Object.keys(this.seriesIdSlugMappings).map((seriesId) => {
                  return(
                    <p key={seriesId}>
                      <label>
                        <input data-series-id={seriesId} type="checkbox" className="filled-in" checked={this.state.selectedSeriesId == seriesId} onChange={this.setOrToggleSeriesSelection}/>
                        <span>{this.seriesIdSlugMappings[seriesId]}</span>
                      </label>
                    </p>
                  )
                })
              }
            </fieldset>
          }

          <LoaderActionButton
            text={'Update'}
            secondaryClassName={'padding-right padding-left'}
            faIconClass={'fa-check'}
            onClick={this._updatePhotoMetadata}
            isLoading={this.state.updating}
          />

          <ResponseStatusIcon
            isShowing={this.state.responseIconShowing}
            success={this.state.lastResponseStatus === 'success'}
          />
        </div>
      </div>
    )
  }
}

export default EditPhotoModal
