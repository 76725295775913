export default {
  pluraliseCollectionName: (collection, collectionName) => {
    let outputString = collection.length > 1 ? `${collectionName}s` : collectionName

    return outputString
  },

  capitalise: (string) => {
    return `${string[0].toUpperCase()}${string.slice(1, string.length)}`
  }
}
