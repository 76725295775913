import React from 'react'
import StringHelper from '../../helpers/StringHelper'

function OrdersTable(props) {

  if(props.orders.length > 0) {
    const [sortByDateColumn, sortByDateColumnHeader] = (() => {
      if(props.ordersState === 'NEW') {
        return ['created_at', 'Created']
      }
      else {
        const lowercaseState = props.ordersState.toLowerCase()
  
        return [
          `${lowercaseState}_at`,
          StringHelper.capitalise(lowercaseState)
        ]
      }
    })()
  
    return(
      <div className='responsive-table'>
        <table className='admin-orders-table'>
          <thead>
            <tr>
              <td>Token</td>
              <td>Customer</td>
              { props.ordersState !== 'AWAITING_PRINT' &&
                <td>{sortByDateColumnHeader}</td>
              }
              { props.ordersState === 'CANCELLED' &&
                <td className='hide-for-extra-small'>Cancelled by</td>
              }
          
              <td>Cost</td>
          
              <td></td>
            </tr>
          </thead>
          <tbody>
            {
              props.orders.map((order) => {
                return(
                  <tr key={`order-${order.token}-table-row`}>
                    <td>
                      <a href={`/dreams_admin/orders/${order.token}`}>
                        {order.token}
                      </a>
                    </td>
                    <td>
                      {order.customer.email}
                    </td>
                    { props.ordersState !== 'AWAITING_PRINT' &&
                      <td>{order[sortByDateColumn]}</td>
                    }
                    { props.ordersState === 'CANCELLED' &&
                      <td className='hide-for-extra-small'>{order.cancelled_by}</td>
                    }
                    <td>{`${order.total_cost}`}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
  else {
    return(
      <div className='centered-flex-container-row'>
        <div className='centered-flex-container-column'>
          <p className='orders-table-description-text'>{`No ${props.ordersState} Orders yet.`}</p>
        </div>
      </div>
    )
  }
}

export default OrdersTable
