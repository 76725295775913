import React from 'react'
import RequiredFieldLabel from './RequiredFieldLabel'
import ApiService from '../../services/ApiService'
import LoaderActionButton from '../LoaderActionButton'
import AddressHelper from '../../helpers/AddressHelper'
import CountryPicker from './CountryPicker'

class AddressForm extends React.Component {
  constructor(props) {
    super(props)

    this.countriesData = JSON.parse(document.getElementById('countries_data').value)

    this.state = {
      isUpdating: false,
      updateError: false
    }
  }

  componentDidMount() {
    this.fields = AddressHelper.fields()

    const address = this.props.address

    const setInputValue = (inputId, inputValue) => {
      const input = document.getElementById(inputId)
      const label = document.querySelector(`label[for='${inputId}']`)

      input.value = inputValue
      label.classList.add('active')
    }

    // country will get passed as prop to countrypicker component
    Object.keys(address).filter((addressFieldKey) => !['country', '_id', 'order_id'].includes(addressFieldKey)).forEach((addressFieldKey) => {
      const addressFieldValue = address[addressFieldKey]

      if(addressFieldValue && addressFieldValue.length) {
        const inputId = `address_form_${addressFieldKey}_input`

        setInputValue(inputId, addressFieldValue)
      }
    })

    // preset name from customer
    if(!address['name'] && this.props.customer && this.props.customer['name']) {
      setInputValue('address_form_name_input', this.props.customer['name'])
    }
  }

  dismissError = () => {
    this.setState({ updateError: false })
  }

  loadFieldValue = (field) => {
    return document.getElementById(`address_form_${field}_input`).value
  }

  createOrUpdateAddress = (event) => {
    let addressParams = {}
    const requiredFields = AddressHelper.requiredFields()
    let missingRequiredField = false

    // populate orderData with address fields
    this.fields.forEach((field) => {
      const fieldValue = this.loadFieldValue(field)

      // only need 1 to be missing to cancel and let the HTML validations do their thing
      if(!missingRequiredField && requiredFields.includes(field) && (!fieldValue || fieldValue.length === 0)) {
        missingRequiredField = true
      }

      addressParams[field] = fieldValue
    });

    if(missingRequiredField) { return }

    event.stopPropagation()
    event.preventDefault()

    const updateAddressCallback = () => {
      ApiService.updateOrder(this.props.orderToken, { address: addressParams })
        .then((response) => {
          const success = response.success

          this.setState({ isUpdating: false, updateError: !success }, () => {
            if(success) {
              const responseData = response.data

              this.props.successCallback(responseData)
            }
          })
        })
    }

    this.setState({ isUpdating: true, updateError: false }, updateAddressCallback)
  }

  labelClassFor = (addressFieldValue) => {
    return addressFieldValue && addressFieldValue.length ? 'active' : null
  }

  render() {
    return(
      <div className="address-form row">
        <form onSubmit={(e) => { e.preventDefault() }} className="col s12">
          { Object.keys(this.props.address).length > 0 &&
            <i onClick={this.props.closeForm} className="fa fa-solid fa-xmark fa-lg"></i>
          }
          <div className="input-field col s12">
            <input required name='name' type='text' id='address_form_name_input'></input>

            <RequiredFieldLabel
              name={'Name'}
              htmlFor={'address_form_name_input'}
            />
          </div>

          <div className="input-field col s12">
            <input required name='address_line_1' type='text' id='address_form_address_line_1_input'></input>

            <RequiredFieldLabel
              name={'Address line 1'}
              htmlFor={'address_form_address_line_1_input'}
            />
          </div>

          <div className="input-field col s12">
            <input name='address_line_2' type='text' id='address_form_address_line_2_input'>
            </input>

            <label htmlFor='address_form_address_line_2_input'>Address line 2</label>
          </div>

          <div className="input-field col s12">
            <input required name='city' type='text' id='address_form_city_input'>
            </input>

            <RequiredFieldLabel
              name={'City'}
              htmlFor={'address_form_city_input'}
            />
          </div>

          <div className="input-field col s12">
            <input required name='state' type='text' id='address_form_state_input'>
            </input>

            <RequiredFieldLabel
              name={'State / Region'}
              htmlFor={'address_form_state_input'}
            />
          </div>

          <div className="input-field col s12">
            <input required name='zip_or_postal_code' type='text' id='address_form_zip_or_postal_code_input'>
            </input>

            <RequiredFieldLabel
              name={'Zip / Postcode'}
              htmlFor={'address_form_zip_or_postal_code_input'}
            />
          </div>

          <div className="input-field col s12">
            <CountryPicker
              formId={'address_form_country_input'}
              selected={this.props.address.country}
              countriesData={this.countriesData}
            />
            
            <RequiredFieldLabel
              name={'Country'}
              htmlFor={'address_form_country_input'}
            />
          </div>

          <div className="input-field col s12">
            <RequiredFieldLabel
              name={'Phone number'}
              htmlFor={'address_form_phone_number_input'}
            />
            <input required name='phone_number' type='text' id='address_form_phone_number_input'></input>
          </div>

          <div className="input-field col s12">
            <textarea name='extra_delivery_instructions' id='address_form_extra_delivery_instructions_input' className="materialize-textarea">
            </textarea>
            <label htmlFor='address_form_extra_delivery_instructions_input'>Extra delivery instructions</label>
          </div>

          { this.state.updateError &&
            <div className='address-form__update-error col s12'>
              
              <i title='Dismiss' onClick={this.dismissError} className="fa fa-solid fa-xmark fa-lg"></i>
              <p className='api-request-error'>
                Dang! something didn't work quite right just then. Please try again, or you can &nbsp;
                <a href={`mailto:caleb@keenedreams.io?subject=Order%20${this.props.orderToken}`}>send me an email</a>
              </p>
            </div>
          }

          <div className='address-form__submit'>
            <LoaderActionButton
              secondaryClassName={'padding-left padding-right'}
              isLoading={this.state.isUpdating}
              text={this.props.submitButtonText}
              buttonType={'submit'}
              onClick={this.createOrUpdateAddress}
              faIconClass={'fa-check'}
            />
          </div>

        </form>
      </div>
    )
  }
}

export default AddressForm
