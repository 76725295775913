import React from 'react'

function PhotoSelector(props) {
  return(
    <div className='photo-selector'>
      { props.selectable.map((src) => {
        return(
          <div key={src} className={'photo-selector__selectable'} onClick={(_e) => { props.setSelected(src) }}>
            <div className={`internal-wrapper ${props.selected === src ? 'selected' : ''}`}>
              <img src={src} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default PhotoSelector
