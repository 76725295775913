import React, { useState, useEffect } from 'react'
import Navbar from '../Navbar'
import Flash from '../Flash'
import RequiredFieldLabel from '../forms/RequiredFieldLabel'
import FlashHelper from '../../helpers/FlashHelper'
import ApiService from '../../services/ApiService'
import Footer from './Footer'

function EditAffiliateCode() {
  const affiliateCode = JSON.parse(document.getElementById('affiliate_code').value)
  const [currentFlash, setCurrentFlash] = useState(FlashHelper.loadInitialFlash())
  const [isUpdating, setIsUpdating] = useState(false)

  const resetFlashWithTimeout = () => {
    window.setTimeout(() => {
      setCurrentFlash(null)
    }, 3000)
  }

  const setFlash = (flash) => {
    setCurrentFlash(flash)
    resetFlashWithTimeout()
  }

  if(currentFlash) {
    window.setTimeout(() => {
      setCurrentFlash(null)
    }, 3000)
  }

  useEffect(() => {
    // load previous value
    const initialValue = document.getElementById('discount-code-code').value

    if(initialValue === '') {
      document.getElementById('discount-code-code').value = affiliateCode.code
    }
  })

  const updateCode = (_event) => {
    if(isUpdating) {
      return
    }

    setIsUpdating(true)

    const code = document.getElementById('discount-code-code').value
    console.log(`loaded code: ${code}`)

    if(code === '') {
      console.error('code field empty')
      setIsUpdating(false)
      return
    }

    // event.stopPropagation()
    // event.preventDefault()

    ApiService.updateAffiliateCode(affiliateCode.id, code)
      .then((response) => {
        if(response.success) {
          // redirect straight back to affiliate home, backend will load success flash from session
          window.location = '/affiliate/home'
        }
        else {
          setFlash({ type: 'error', message: "Sorry! something went wrong just then while trying to update your affiliate code." })
          setIsUpdating(false)
        }
      })
  }
  
  return(
    <React.Fragment>
      <Navbar title='AFFILIATE CODE'/>
      <Flash flash={currentFlash} />

      <div className='affiliate-code'>
        <h4 className='border-bottom-header'>
          Update your affiliate code
        </h4>

        <div className='affiliate-code__section'>
          <div className='affiliate-code__form'>
            <form onSubmit={(e) => { e.preventDefault() }}>
              <input name='authenticity_token' type='hidden' value={ApiService.authenticityToken()} />

              <div className='input-field'>
                <input required name='code' type='text' autoComplete='off' id='discount-code-code'></input>
                <RequiredFieldLabel
                  forceActive={true}
                  name={'Code'}
                  htmlFor={'discount-code-code'}
                />

                <div className='actions'>
                  <a href='/affiliate/home' className={`button button--action ${isUpdating ? 'disabled' : ''}`}>
                    <i className='fa-solid fa-arrow-left'></i>
                    <span>
                      Back
                    </span>
                  </a>

                  <button type='submit' className='button button--action' onClick={updateCode}>
                    <span>
                      Update Code
                    </span>
                    { isUpdating &&
                      <i className='fa-solid fa-spinner fa-spin'></i>
                    }
                    { !isUpdating &&
                      <i className='fa fa-solid fa-check'></i>
                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default EditAffiliateCode
