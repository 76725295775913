// see also: print_sizing.rb

const _longSideDimensions = (size) => {
  return {
    small:  {
      in: 7.0,
      mm: 178
    },
    medium: {
      in: 10.0,
      mm: 254
    },
    large: {
      in: 13.8,
      mm: 348
    },
    extra_large: {
      in: 20.0,
      mm: 508
    }
    
  }[size]
}

const _shortSideDimensions = (aspectGroup, size) => {
  if(aspectGroup === 'square') {
    return _longSideDimensions(size)
  }
  else {
    return {
      small: {
        in: 5.7,
        mm: 142
      },
      medium: {
        in: 8.0,
        mm: 203,
      },
      large: {
        in: 11.0,
        mm: 279
      },
      extra_large: {
        in: 16.0,
        mm: 406
      }
    }[size]
  }
}

export default {
  dimensions: (size, aspectGroup) => {
    const longSideDimensions =  _longSideDimensions(size)
    const shortSideDimensions = _shortSideDimensions(aspectGroup, size)

    if(aspectGroup === 'landscape') {
      return `${longSideDimensions['in']}″ x ${shortSideDimensions['in']}″ (${longSideDimensions['mm']}mm x ${shortSideDimensions['mm']}mm)`
    }
    else if(aspectGroup === 'portrait') {
      return `${shortSideDimensions['in']}″ x ${longSideDimensions['in']}″ (${shortSideDimensions['mm']}mm x ${longSideDimensions['mm']}mm)`
    }
    else { // square
      return `${shortSideDimensions['in']}″ x ${longSideDimensions['in']}″ (${shortSideDimensions['in']}″ = ${shortSideDimensions['mm']}mm)`
    }
  },

  cost: (size) => {
    const costSizeMappings = {
      small:  100.0,
      medium: 140.0,
      large:  170.0,
      extra_large: 190
    }[size]

    return costSizeMappings || 'TBC'
  }
}
