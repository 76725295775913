import React from 'react'
import Navbar from '../Navbar';
import SinglePageNav from '../SinglePageNav';
import Footer from '../Footer'

import Money from './Money';
import Crypto from './Crypto';
import KindWords from './KindWords';

class Donate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSection: 'Money'
    }
  }

  _switchAboutSection = (e) => {
    this.setState({ selectedSection: e.target.getAttribute('data-section') });
  }

  render() {
    const sectionMappings = {
      'Money': Money,
      'Crypto': Crypto,
      'KindWords': KindWords
    }

    const CurrentSectionComponent = sectionMappings[this.state.selectedSection];

    return(
      <React.Fragment>
        <Navbar title='keenedreams'/>
        <div className='container'>
          <div className='donate__photo-container'>
            <img src={`/images/oaxaca-church-wall-${this.state.selectedSection.toLowerCase()}.jpg`}></img>
          </div>

          <div className='donate__header'>
            <h3>Donate</h3>
            <p>Every little bit helps :)</p>
            <p></p>
          </div>

          <div className='single-page-nav__wrapper'>
            <SinglePageNav
              text={'Crypto'}
              selectionKey='Crypto'
              onClickHandler={this._switchAboutSection}
              selected={this.state.selectedSection === 'Crypto'}
            />

            <SinglePageNav
              text={'Money'}
              selectionKey='Money'
              onClickHandler={this._switchAboutSection}
              selected={this.state.selectedSection === 'Money'}
            />

            <SinglePageNav
              text={'Kind words'}
              selectionKey='KindWords'
              onClickHandler={this._switchAboutSection}
              selected={this.state.selectedSection === 'KindWords'}
            />
          </div>
          <div className='about__text'>
            <CurrentSectionComponent />
          </div>
        </div>
        <Footer currentPage={'donate'} />
      </React.Fragment>
    );
  }
}

export default Donate;
